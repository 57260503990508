import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css';
import BootstrapVue from "bootstrap-vue";
import $ from 'jquery'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
// 富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import footCom from "@/components/footCom.vue"
Vue.component('footCom', footCom)
Vue.use(VueQuillEditor)
Vue.prototype.$axios=axios;
Vue.prototype.imgUrl='http://47.250.39.153:8600';
Vue.config.productionTip = false

Vue.use(ElementUI)
new Vue({
  router,
  store,
  BootstrapVue,
  render: h => h(App)
}).$mount('#app')
