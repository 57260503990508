<template>
  <div class="campus-universally">
    <div class="banner-img">
      <img :src="image" />
    </div>
    <div class="universally-content universally-f">
      <div class="flex-ac" style="width: 90%; margin: 20px auto; align-items: flex-start;  justify-self: center;">

        <div class="list-call">
          <div class="content">
            <div class="nei" @click="gopush(item.articleId)" :class="index % 2 == 0 ? 'rever' : ''"
              v-for="(item, index) in newList" :key="item">
             <!-- <img class="p-imgs" v-if="item.images" :src="item.images" />-->
              <div class="p-right">
                <p class="p-title" style="">{{ item.title }}</p>
                <p style="font-size: 16px; color: #003572;">{{ item.createTime }}</p>
                <div style="text-align: left;">{{ item.summary }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-pagination class="pageone" style="margin: 10px 0" background layout="prev, pager, next" :total="total"
      :page-size="10" :current-page="page" @current-change="getchangePage">
    </el-pagination>
    <!--底部导航栏-->
    <footCom></footCom>
  </div>
</template>
<script>
import { getArticleList, getArticleDetail } from "@/api/home";
export default {
  components: {
    // bottomleft,
  },
  created() {
    this.initData();
  },
  data() {
    return {
      total: 0,
      page: 1,
      newList: [],
      image:null,
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.getNewList();
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    initData() {
      this.getNewList();
    },
    getNewList() {
      getArticleList({ pageNum: this.page, pageSize: 20, categoryId: this.$route.query.categoryId }).then((response) => {
        response.rows.forEach(item => {
          item.images = this.imgUrl + item.images
          this.image=this.imgUrl + item.images
        })
        this.newList = response.rows;
        this.total = response.total
      });
    },
    getchangePage(page) {
      console.log(page);
      this.page = page;
      this.getNewList()
    },
    gopush(id) {
      this.$router.push({ name: 'Detail', params: { id } })
    }
  }
};
</script>
<style lang="scss" scoped>
.current-campus {
  margin-top: 100px;
}

.university-wrap {
  min-height: calc(100vh - 100px - 72px - 52px);
}

.list-call {
  width: 100%;
  height: auto;
  margin-top: 20px;

  .content {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    width: 100%;

    img:after,
    img:before {
      max-width: 100%;
    }
  }

  .nei {
    display: flex;
    width: 100%;
    // margin: 20px 0;
    margin-bottom: 15px;
    background-color: #fff;
    border: 3px solid rgba($color: #000000, $alpha: 0.3);
    border-radius: 10px;
    cursor: pointer;

    .p-imgs {
      width: 50%;
      height: auto;
    }

    .p-right {
      width: 50%;
      height: auto;
      padding: 20px;
      box-sizing: border-box;

      .p-title {
        font-size: 40px;
        color: #003572;
      }

      .p-title:hover {
        color: #eda712;
      }
    }
  }

  .rever {
    flex-direction: row-reverse;
  }
}
</style>
<style lang="scss" type="text/scss" src="../../assets/style/OurUniversity.scss" />
