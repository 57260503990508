import { render, staticRenderFns } from "./PaymentInstructions.vue?vue&type=template&id=8937c7f4&scoped=true"
import script from "./PaymentInstructions.vue?vue&type=script&lang=js"
export * from "./PaymentInstructions.vue?vue&type=script&lang=js"
import style0 from "./PaymentInstructions.vue?vue&type=style&index=0&id=8937c7f4&prod&lang=scss&scoped=true"
import style1 from "../assets/style/PaymentInstructions.scss?vue&type=style&index=1&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8937c7f4",
  null
  
)

export default component.exports