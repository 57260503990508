<template>
  <div id="demo" class="carousel slide" data-ride="carousel">
    <!-- 指示符 -->
    <!-- <ul class="carousel-indicators">
      <li data-target="#demo" data-slide-to="0" class="active"></li>
      <li data-target="#demo" data-slide-to="1"></li>
      <li data-target="#demo" data-slide-to="2"></li>
    </ul> -->

    <!-- 轮播图片 -->
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="inner-text">
          FIS has provided me with the strong fundamental knowledge of science,
          communication skills and opportunities to improve myself through
          various activities. For example, in the Cross Boundary Studies module,
          it is a group-based work which encourages students to work in a team.
          Through this module, I was able to strengthen my critical thinking
          skills and leadership skills which makes me well-prepared for
          undergraduate study. Also, I like the fact that FlS in IMU is designed
          to prepare students into the healthcare field with various healthcare
          related modules like English for Healthcare Professionals,Psychology
          in Healthcare, Bioscience, etc. Besides, there is also a PBL session
          in FIS where we discuss a case study and share our thoughts and
          findings after we did some research on the internet. It really helped
          me a lot as I gained my confidence and ability to socialise, I am
          truly glad that I have pursued this programme.123
        </div>
      </div>
      <div class="carousel-item">
        <div class="inner-text">
          FIS has provided me with the strong fundamental knowledge of science,
          communication skills and opportunities to improve myself through
          various activities. For example, in the Cross Boundary Studies module,
          it is a group-based work which encourages students to work in a team.
          Through this module, I was able to strengthen my critical thinking
          skills and leadership skills which makes me well-prepared for
          undergraduate study. Also, I like the fact that FlS in IMU is designed
          to prepare students into the healthcare field with various healthcare
          related modules like English for Healthcare Professionals,Psychology
          in Healthcare, Bioscience, etc. Besides, there is also a PBL session
          in FIS where we discuss a case study and share our thoughts and
          findings after we did some research on the internet. It really helped
          me a lot as I gained my confidence and ability to socialise, I am
          truly glad that I have pursued this programme.123333
        </div>
      </div>
    </div>

    <!-- 左右切换按钮 -->
    <a class="carousel-control-prev" href="#demo" data-slide="prev">
      <span class="carousel-control-prev-icon"></span>
    </a>
    <a class="carousel-control-next" href="#demo" data-slide="next">
      <span class="carousel-control-next-icon"></span>
    </a>
  </div>
</template>
<script>
export default {
  name: "carousel",
  mounted() {},
};
</script>
<style lang="scss" scoped>
.inner-text {
  text-align: left;
  color: #a4a0a0;
  width: 70%;
  height: 173px;
  margin: 0 auto;
  border-left: 5px solid #716b6b;
  padding-left: 30px;
}
.tiplight {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(203, 203, 203, 0.8);
}
</style>