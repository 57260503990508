<template>
  <div>
    <collegeHeader />
    <div class="header-part flex-ac">
      <div style="width: 800px; margin: 0 auto">
        <div class="title">CENTER FOR PRE-UNIVERSITY</div>
        <div class="second-title">HOME LIMU CENTRE FORBGTANYERSIT</div>
      </div>
    </div>
    <div class="arti flex-cc">
      <div class="pos-rel">
        <div>
          The Centre for Pre University at IMU offers you learning in a
          university
        </div>
        <div>environment that promotes teamwork; mutual respect and</div>
        <div class="p-b-20">independent lifelong learning.</div>
        <div>
          For this Foundation in Science programme, the Centre has incorporated
        </div>
        <div>the concept of quality education, preparing students well for</div>
        <div>
          the challenges of university studies. The lecturers in the Centre have
          the
        </div>
        <div>
          requisite pre-university core competencies in their related
          disciplines. In
        </div>
        <div>
          addition, they have knowledge of related undergraduate programme
        </div>
        <div>
          requirements but with specific experience that is focused on the
        </div>
        <div>teaching and mentoring needs of pre-university students.</div>
        <div class="cover-text-yellow pos-yellow"></div>
      </div>
    </div>
    <div class="flex-cc second-part">
      <div>
        <div>
          Your lectures, tutorials, laboratories and academic mentoring are all
          based in a university environment, sharing the same facilities as the
        </div>
        <div>
          undergraduate and postgraduate students and contributing equally to
          the university community.
        </div>
      </div>
    </div>
    <div class="flex-cc pos-rel">
      <div class="big-title">Programmes Offered</div>
      <!-- <div class="cover-text-blue pos-blue"></div> -->
    </div>
    <div class="sci-p">
      <div class="item flex-ac">
        <img src="" />
        <div>FOUNDATION IN SCIENCE</div>
      </div>
    </div>
    <div class="flex-cc pos-rel">
      <div class="big-title">Message from the Head of Centre</div>
      <!-- <div
        class="cover-text-yellow pos-blue"
        style="background-color: rgb(255, 255, 0, 0.8)"
      ></div> -->
    </div>
    <img
      class="header-img"
      src="//11775317.s61i.faiusr.com/2/AD0I1drOBRACGAAg8rCH2gUo-riJcjBGOEY.jpg.webp"
    />
    <div class="img-name">DR JAMES EDWARD WALSH</div>
    <div class="intro">Director. Centre for Pre-University</div>
    <div class="intro">
      BSc,MSc.PhD (Physics-NUI), M.Inst.Phys. C.Phys.(Inst of Physics Londoo)
    </div>
    <div class="p-div" style="margin-top: 20px">
      "The IMU Foundation in Science programme is designed to equip both
      Malaysian and international students with the knowledge and skills
    </div>
    <div class="p-div">
      required for undergraduate studies at the IMU and our international
      partner universities. This programme incorporates a holistic approach to
    </div>
    <div class="p-div">
      learning and in addition to providing core university science foundation
      knowledge, it incorporates signtficant English language and professional
    </div>
    <div class="p-div">
      development elements. It also provides students with an early integration
      into the university community that includes opportunities to interact
    </div>
    <div class="p-div">
      with faculty academics and students and which incorporates full access to
      the IMU learning resources. Students will also gain exposure to the
    </div>
    <div class="p-div">
      IMU's learning methodologies that promote independent lifelong learning,
      to better prepare them for degree progression after successful
    </div>
    <div class="p-div">
      completion of Foundation in Science.The IMU Foundation in Science
      programme is the ideal pathway for entry into IMU's local and
      international
    </div>
    <div class="p-div">
      partner degree programmes and is the key to a promising career pathway."
    </div>
    <div class="flex-cc pos-rel">
      <div class="big-title">Achievement & Engagement Activities</div>
      <!-- <div class="cover-text-blue pos-blue"></div> -->
    </div>
    <div class="part-three flex-ac" style="align-items: flex-start">
      <div style="width: 10%">
        <!-- <div class="dropdown">
          <a
            class="btn btn-secondary dropdown-toggle"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            all
          </a>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item" href="">Action</a>
            <a class="dropdown-item" href="">Another action</a>
            <a class="dropdown-item" href="">Something else here</a>
          </div>
        </div> -->
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="part-right">
        <div class="title flex-ac">
          <img src="../assets/1.jpg" />
          <div class="title-t">
            Mathematics-themed Escape Room Challenge ; IMU's Novel Way to Teach
            Pre-UniversityMathematics
          </div>
        </div>
        <p>
          Gamification based teaching me thods can be used to change behayior,
          to educate and to motivate, which is important for many students who
          find mathematics diffcult iMid Foundation in Science (F1S) Mathematics
          lecturers lung Li Qian and Jessica LeongMay Fong, have developed a
          great new and fun way to learn mathematics based on the popular Escape
          Rooms concept that has become one leading leisure activities among
          young people. Beating an escape room is challenging yet fun and
          requires teamwork, speed, creativity, and patience. These are the same
          qualities which are essential for students to excerefore perfect to be
          incorporated intoMathematics teaching and learning to enhance
          students'interest, engagement and participation in learning
          activities. Read on to find out how the activities were conducted,
          here.
        </p>
        <div class="title flex-ac">
          <img src="../assets/1.jpg" />
          <div class="title-t">
            Mathematics-themed Escape Room Challenge ; IMU's Novel Way to Teach
            Pre-UniversityMathematics
          </div>
        </div>
        <p>
          Gamification based teaching me thods can be used to change behayior,
          to educate and to motivate, which is important for many students who
          find mathematics diffcult iMid Foundation in Science (F1S) Mathematics
          lecturers lung Li Qian and Jessica LeongMay Fong, have developed a
          great new and fun way to learn mathematics based on the popular Escape
          Rooms concept that has become one leading leisure activities among
          young people. Beating an escape room is challenging yet fun and
          requires teamwork, speed, creativity, and patience. These are the same
          qualities which are essential for students to excerefore perfect to be
          incorporated intoMathematics teaching and learning to enhance
          students'interest, engagement and participation in learning
          activities. Read on to find out how the activities were conducted,
          here.
        </p>
        <div class="title flex-ac">
          <img src="../assets/1.jpg" />
          <div class="title-t">
            Mathematics-themed Escape Room Challenge ; IMU's Novel Way to Teach
            Pre-UniversityMathematics
          </div>
        </div>
        <p>
          Gamification based teaching me thods can be used to change behayior,
          to educate and to motivate, which is important for many students who
          find mathematics diffcult iMid Foundation in Science (F1S) Mathematics
          lecturers lung Li Qian and Jessica LeongMay Fong, have developed a
          great new and fun way to learn mathematics based on the popular Escape
          Rooms concept that has become one leading leisure activities among
          young people. Beating an escape room is challenging yet fun and
          requires teamwork, speed, creativity, and patience. These are the same
          qualities which are essential for students to excerefore perfect to be
          incorporated intoMathematics teaching and learning to enhance
          students'interest, engagement and participation in learning
          activities. Read on to find out how the activities were conducted,
          here.
        </p>
        <div class="title flex-ac">
          <img src="../assets/1.jpg" />
          <div class="title-t">
            Mathematics-themed Escape Room Challenge ; IMU's Novel Way to Teach
            Pre-UniversityMathematics
          </div>
        </div>
        <p>
          Gamification based teaching me thods can be used to change behayior,
          to educate and to motivate, which is important for many students who
          find mathematics diffcult iMid Foundation in Science (F1S) Mathematics
          lecturers lung Li Qian and Jessica LeongMay Fong, have developed a
          great new and fun way to learn mathematics based on the popular Escape
          Rooms concept that has become one leading leisure activities among
          young people. Beating an escape room is challenging yet fun and
          requires teamwork, speed, creativity, and patience. These are the same
          qualities which are essential for students to excerefore perfect to be
          incorporated intoMathematics teaching and learning to enhance
          students'interest, engagement and participation in learning
          activities. Read on to find out how the activities were conducted,
          here.
        </p>
        <div class="title-bottom pos-rel">
          View All
          <!-- <div
            class="cover-text-blue pos-blue"
            style="top: 0; left: 0; width: 22px; height: 22px"
          ></div> -->
        </div>
      </div>
    </div>
    <div class="flex-cc pos-rel">
      <div class="big-title">Views From Our Community</div>
      <!-- <div
        class="cover-text-blue pos-blue"
        style="background-color: rgb(85 210 241 / 80%); left: 38%"
      ></div> -->
    </div>
    <img
      class="header-img"
      src="//11775317.s61i.faiusr.com/2/AD0I1drOBRACGAAg8rCH2gUo-riJcjBGOEY.jpg.webp"
    />
    <div class="img-name">LEE CHIU YING</div>
    <div class="intro">Student,IMU Nutrition</div>
    <div style="margin-top: 40px; margin-bottom: 40px">
      <carousel3 />
    </div>
    <div class="flex-cc pos-rel">
      <div class="big-title">Latest News & Stories</div>
      <!-- <div
        class="cover-text-yellow pos-blue"
        style="background-color: rgb(255, 255, 0, 0.8)"
      ></div> -->
    </div>
    <div class="">
      <div
        class="flex-ac-js"
        style="padding-bottom: 100px; width: 70%; margin: 0 auto"
      >
        <div class="card-bodys">
          <img class="people" src="../assets/1.jpg" alt="" />
          <div class="p-div p-t-20" style="font-size: 15px">
            The IMU Science
          </div>
          <div class="p-div" style="font-size: 15px">Discovery Challenge:</div>
          <div class="p-div" style="font-size: 15px">
            Cultivating an Interest i...
          </div>
          <div class="w-line"></div>
          <div class="p-div hover-tip" style="font-size: 15px; cursor: pointer">
            Read more
          </div>
        </div>
        <div class="card-bodys">
          <img class="people" src="../assets/1.jpg" alt="" />
          <div class="p-div p-t-20" style="font-size: 15px">
            The IMU Science
          </div>
          <div class="p-div" style="font-size: 15px">Discovery Challenge:</div>
          <div class="p-div" style="font-size: 15px">
            Cultivating an Interest i...
          </div>
          <div class="w-line"></div>
          <div class="p-div hover-tip" style="font-size: 15px; cursor: pointer">
            Read more
          </div>
        </div>
        <div class="card-bodys">
          <img class="people" src="../assets/1.jpg" alt="" />
          <div class="p-div p-t-20" style="font-size: 15px">
            The IMU Science
          </div>
          <div class="p-div" style="font-size: 15px">Discovery Challenge:</div>
          <div class="p-div" style="font-size: 15px">
            Cultivating an Interest i...
          </div>
          <div class="w-line"></div>
          <div class="p-div hover-tip" style="font-size: 15px; cursor: pointer">
            Read more
          </div>
        </div>
        <div class="card-bodys">
          <img class="people" src="../assets/1.jpg" alt="" />
          <div class="p-div p-t-20" style="font-size: 15px">
            The IMU Science
          </div>
          <div class="p-div" style="font-size: 15px">Discovery Challenge:</div>
          <div class="p-div" style="font-size: 15px">
            Cultivating an Interest i...
          </div>
          <div class="w-line"></div>
          <div class="p-div" style="font-size: 15px; cursor: pointer">
            Read more
          </div>
        </div>
      </div>
    </div>
    <webBottom />
    <!-- <bottomleft /> -->
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import webBottom from "@/components/webBottom.vue";
import carousel3 from "@/components/carousel3.vue";
import bottomleft from "@/components/bottomLeft.vue";
export default {
  components: {
    collegeHeader,
    webBottom,
    carousel3,
    // bottomleft
  },
  data() {
    return {
      options: [{
          value: '0',
          label: 'All'
        }, {
          value: '1',
          label: 'First'
        }, {
          value: '2',
          label: 'Second'
        }],
        value: '0'
    }
  }
};
</script>
<style lang="scss" type="text/scss" src="../assets/style/PreUniversity.scss">