<template>
  <div>

    <bannerImage :image="image"></bannerImage>

    <div class="container">

      <div :style="{
      textAlign: 'left',
      width: '900px',
      margin: '0 auto 50px auto',
    }">
        <div :style="{
      margin: '20px 0 20px 0',
    }">
          <span :style="{
      fontSize: '20px',
      marginRight: '10px',
    }">About Us</span><span :style="{
      marginRight: '10px'
    }">></span>
          <span :style="{
      color: '#465584'
    }">Governance and Leadership</span>
        </div>
        <div>
          <h1 :style="{
      fontSize: '40px',
      color: '#000',
      margin: '0 0 40px 0',
      padding: '30px 0 30px 0',
      borderBottom: '4px solid #465584',
    }">Governance and Leadership</h1>
        </div>

        <!-- 列表div -->
        <div :style="{
      borderRadius: '20px',
      overflow: 'hidden',
      border: '1px solid #EBF0FF',
      cursor: 'pointer',
    }">
          <div :style="{
      display: 'flex',
      justifyContent: 'flex-start',
      borderBottom: '1px solid #EBF0FF',
      padding: '20px 0 20px 0',
      margin: '0',
    }" v-for="(i, k) in profiles" :key="k" @click="goDetail(i.index)">
            <svg :style="{
      margin: '0 20px 0 35px',
      backgroundColor: '#465584',
      color: '#fff',
      padding: '3px',
      borderRadius: '50%',
    }" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 2048 2048">
              <path fill="currentColor"
                d="M1168 946q38 15 74 33t70 41q-22 24-40 50t-33 56q-77-50-164-76t-179-26q-88 0-170 23t-153 64t-129 100t-100 130t-65 153t-23 170H128q0-120 35-231t101-205t156-167t204-115q-113-74-176-186t-64-248q0-106 40-199t109-163T696 40T896 0q106 0 199 40t163 109t110 163t40 200q0 66-16 129t-48 119t-75 103t-101 83M512 512q0 80 30 149t82 122t122 83t150 30q79 0 149-30t122-82t83-122t30-150q0-79-30-149t-82-122t-123-83t-149-30q-80 0-149 30t-122 82t-83 123t-30 149m1472 832q0 52-16 101t-48 91v512l-256-128l-256 128v-512q-31-42-47-91t-17-101q0-66 25-124t68-101t102-69t125-26q66 0 124 25t101 69t69 102t26 124m-320-192q-40 0-75 15t-61 41t-41 61t-15 75q0 40 15 75t41 61t61 41t75 15q40 0 75-15t61-41t41-61t15-75q0-40-15-75t-41-61t-61-41t-75-15m128 689v-204q-60 27-128 27t-128-27v204q32-16 64-31t64-33q32 17 64 32t64 32" />
            </svg>
            <div>
              <p :style="{
      margin: '0',
    }">{{ i.name }}</p>
              <p v-html="i.title" :style="{
      margin: '0',
    }"></p>
            </div>
          </div>

        </div>
      </div>


      <!--底部导航栏-->
      <footCom></footCom>
    </div>
  </div>
</template>
<script>
import { getArticleDetail1 } from "@/api/home";
import bannerImage from "@/views/component/bannerImage.vue";

export default {
  components: {
    bannerImage
  },
  created() {
    this.initData()
  },
  data() {
    return {
      profiles: [
        {
          index: 1,
          name: 'Prof. Dr. Jason Fitzsimmons',
          title: 'Vice Chancellor',
          introduction: 'Prof. Dr. Jason Fitzsimmons comes with over 20 years of experience in higher education and during that period he has held senior academic roles with extensive experience in international recruitment, programme development, academic collaborations, executive education programmes and the integration of technology in learning environments. He has lived and taught in various countries including Australia, India, Malaysia, Singapore and the United Arab Emirates. ',
          image: require('@/assets/leader01.jpg'),
        },
        {
          index: 2,
          name: 'Prof. Dr. Graham Kendall',
          title: 'Deputy Vice Chancellor - Quality Assurance & Research',
          introduction: 'Prof. Dr. Graham Kendall is an academic but has spent a significant amount of his career in industry, holding senior positions in the IT industry (in the UK) and C-suite positions in Malaysia, in both industry and academia. He was the Provost/CEO of the University of Nottingham Malaysia (5,000 students, with 650 staff on a 125-acre estate). He also served as a Pro-Vice Chancellor for the global University. He is still affiliated with Nottingham, as an Emeritus Professor. He holds distinguished and honorary positions in Malaysia, India and Hong Kong.',
          image: require('@/assets/leader02.jpg'),
        },
        {
          index: 3,
          name: 'Mr. Zhao Baoli',
          title: 'Vice President - Facilities, Foundation & Development',
          introduction: 'Zhao Baoli is the Vice President of Mila University. He was formerly the Vice President of Hainan SaiboLe Education Group， and the Assistant to the President and Director of Infrastructure at Hainan University of Economics, as well as having rich experience in education management and campus construction. Earlier in his career, he served as the CEO of Changying Group\'s Hainan Cultural Industry Company, bringing with him extensive experience in business management & project planning and construction.',
          image: require('@/assets/leader03.jpg'),
        },
        {
          index: 4,
          name: 'Assoc. Prof. Sargunam Thirugnanam',
          title: 'Registrar',
          introduction: 'Assoc. Prof. Sargunam Thirugnanam has more than three decades of experience with dynamic expertise in Academics, Registry, Governance, Quality, Compliance, Student Life Cycle, and Project Management in Higher Education Ecosystem. He has held various senior academic and management roles responsible for academics, programme development, quality, examination and international collaborations in private higher educational institutions. He played a key role in establishing twinning and franchise programmes with some of the universities in the United Kingdom, Australia, and the United States. He holds Chartered Engineer (CEng) status from the Engineering Council (UK) and is recognized as a Professional Fellow of the British Computer Society, The Chartered Institute for IT (UK).',
          image: require('@/assets/leader04.jpg'),
        },
        {
          index: 5,
          name: 'Prof. Dr. Lee Chew Ging',
          title: 'Assistant Deputy Vice Chancellor <br />Dean of the School of Management & Business',
          introduction: 'Prof. Dr. Lee Chew Ging is a Professor in Economics. He graduated from the University of London with a Bachelor of Science (Economics). He received his Master of Social Sciences (Economics) from the National University of Singapore. He completed his PhD by Publication at the University of Portsmouth. He was promoted to Full Professor in 2011 by the University of Nottingham Malaysia. Prior to joining MILA University, he had held different senior positions, such as Deputy Chief Academic Officer of INTI International University & Colleges, Head of Southampton Malaysia Business School, Dean of Nottingham Business School Malaysia and Associate Vice Chancellor of Wawasan Open University. He also served as an expert to assist the Malaysian Qualifications Agency (MQA) to develop the first edition and second edition of Programme Standards: Business Studies. Currently, he is a member of the Board of Examiners of Asian Institute of Chartered Banker. ',
          image: require('@/assets/leader05.jpg'),
        },
        {
          index: 6,
          name: 'Prof. Dr. Edwin Chung Chin Yau',
          title: 'Dean of the School of Engineering & Computing <br/>Dean of the School of Biotechnology',
          introduction: 'Prof. Dr. Edwin Chung is an Engineering Professor and the Dean for the School of Engineering & Computing as well as the School of Biotechnology at MILA University. Edwin graduated from Monash University (Clayton) with a Bachelor of Science majoring in Computer Science, a Bachelor of Engineering with first class honours majoring in Electrical & Computer Systems Engineering and a PhD in the area of asynchronous circuit design.He was the Director for Knowledge Transfer & Commercialisation at Taylor’s University and the General Manager at Taylor’s Consultancy Sdn Bhd (a subsidiary of Taylor’s University).He also holds 1 Malaysian and 2 international patents.',
          image: require('@/assets/leader06.jpg'),
        },

      ],
      image: null
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.getNewList();
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    initData() {
      this.getNewList();
    },
    getNewList() {
      getArticleDetail1({ value: 43 }).then((response) => {
        if (response.data && response.data.images) {
          response.data.images = this.imgUrl + response.data.images
          this.image = response.data.images;
        }
      });
    },
    goDetail(detailName) {
      this.$router.push({
        path: '/GovernanceAndLeadershipDetail',
        query: {
          name: 'GovernanceAndLeadership',
          params: detailName
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 10px 0px 10px 0px;
  min-height: 75%;
  max-width: 2220px;



  .list {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    padding: 20px;

    .item {
      cursor: pointer;
      height: 400px;

      img {
        height: 300px;
        width: 50%;
      }
    }
  }
}
</style>
