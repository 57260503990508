<template>
  <div class="pay-content">
    <collegeHeader />
    <div class="pay-main">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="">Home</a></li>
        <li class="breadcrumb-item active">Payment Instructions</li>
      </ol>
      <div class="second-titles">Payment Instructions</div>
      <p style="margin: 30px 0">
        Payments and fees can be made through the following payment channels:
      </p>
      <!-- <div class="pay-box-title flex-ac-js">
        <div></div>
        <div>—</div>
      </div> -->
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item title="JomPAY Local Online Payment" name="1">
          <div class="payment-info">
            1 <span>Payment for Programme</span>
            <p style="padding-left: 13px">Biller Code: 2220</p>
          </div>
          <div class="payment-info" style="margin: 0">
            2 <span>Payment for Accommodation</span>
            <p style="padding-left: 13px">Biller Code: 3228</p>
          </div>
          <div class="pay-box-car">
            <div class="flex-cc">
              <img class="bank-icon" src="../assets/1.jpg" alt="" />
              <div class="bank-info">
                <b> Biller Code: </b>Refer to the above (a) or (b) <br />
                <b> Ref-1: </b>Student ID ( for SU, SCKL and Sis Students only)
                <br />
                <b> Ref-2: </b> Student NRIC/Passport Number/Sunway Pals No.
              </div>
            </div>
            <div style="padding: 5px 20px">
              <b
                >JomPAY online at internet & Mobile Bankingwith your Current,
                Savings or Credit Card Account.</b
              >
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="Online Banking" name="2">
        </el-collapse-item>
        <el-collapse-item title="Automated Teller Machine (ATM) / Cash Deposit Machine / Cheque Deposit Machine] Interbank GIRO" name="3">
        </el-collapse-item>
        <el-collapse-item title="Telegraphic Transfer" name="4">
        </el-collapse-item>
        <el-collapse-item title="Cheque Deposit Box" name="5">
        </el-collapse-item>
        <el-collapse-item title="Over the Counter" name="6">
        </el-collapse-item>
        <el-collapse-item title="Important Notes" name="7">
          <div class="second-titles p-b-20">Important Notes</div>
          <p style="font-size: 18px p-b-20">
            For payment channels
            <b>
              Cheque Deposit Box, Automated Teller Machine (ATM) / Cash Deposit
              Machine/ Cheque Deposit Machine / Interbank GIRO, Online
              Banking,Telegraphic Transfer</b
            >
            and <b>JomPAY</b>, please provide us with the following information:
          </p>
          <p style="padding-left: 10px">1.Student Name</p>
          <p style="padding-left: 10px">2.Student NRIC/Passport No.</p>
          <p style="padding-left: 10px">3. Contact number.</p>
          <p style="padding-left: 10px">4.Programme study.</p>
          <p style="padding-left: 10px">5. Sunway PALS Card No.</p>
          <p style="font-size: 18px p-b-20">
            Please note: Sunway points will be awarded on payment made for tuition
            fees only. Sunway points will not be awarded once payment has been
            processed and official receipt issued.
          </p>
          <p>
            <b style="font-size: 18px; text-align: left" class="p-t-20 p-b-20"
              >AND</b
            >
          </p>
          <p>
            Send us the payment transaction receipts/slip through one of the
            following channels:
          </p>
          <p style="padding-left: 10px">1.Over the Finance Counter</p>
          <p style="padding-left: 10px">2.Email:<u> finance@sunway.edu.my</u></p>
          <p>
            <b style="font-size: 18px; text-align: left" class="p-t-20 p-b-20"
              >AND</b
            >
          </p>
          <p>
            · e-Receipt will be sent to student iMail or registered e-mail with
            the Registry
          </p>
          <p>· it will take;</p>
          <p style="padding-left: 20px">
            。2 working days (for local remote bank-in or bank transfer); and .
          </p>
          <p style="padding-left: 20px">
            。5 to 7 working days (for overseas transfer) for remittance,
            clearance after we have received your payment transaction receipt.
          </p>
        </el-collapse-item>
      </el-collapse>
      <div class="p-t-20 p-b-20">
        <!-- <div class="payment-box flex-ac-js">
          <b>Online Banking</b>
          <i class="el-icon-plus" style="color: rgba(204, 151, 37, 0.955)"></i>
        </div> -->
        <!-- <div class="payment-box flex-ac-js">
          <b>Online Payment</b>
          <i class="el-icon-plus" style="color: rgba(204, 151, 37, 0.955)"></i>
        </div>
        <div class="payment-box flex-ac-js">
          <b
            >Automated Teller Machine (ATM) / Cash Deposit Machine / Cheque
            Deposit Machine] Interbank GIRO
          </b>
          <i class="el-icon-plus" style="color: rgba(204, 151, 37, 0.955)"></i>
        </div>
        <div class="payment-box flex-ac-js">
          <b>Telegraphic Transfer </b>
          <i class="el-icon-plus" style="color: rgba(204, 151, 37, 0.955)"></i>
        </div>
        <div class="payment-box flex-ac-js">
          <b>Cheque Deposit Box </b>
          <i class="el-icon-plus" style="color: rgba(204, 151, 37, 0.955)"></i>
        </div>
        <div class="payment-box flex-ac-js">
          <b>Over the Counter</b>
          <i class="el-icon-plus" style="color: rgba(204, 151, 37, 0.955)"></i>
        </div> -->

        <!-- <div class="payment-box flex-ac-js">
          <b>Important Notes</b>
          <i class="el-icon-plus" style="color: rgba(204, 151, 37, 0.955)"></i>
        </div> -->
        <div class="payment-line"></div>
        <p class="p-t-20 p-b-20">
          For further enquiry, please contact Financial Services Department:
        </p>
        <p><b>Tel</b>:(6) 03-7491 8622</p>
        <p><b>Email:</b>2 finance@sunway.edu.my</p>
        <p><b>Operation Hours:</b> Monday -Friday (8,30AM to 5.30PM)</p>
        <p><b>Address:</b></p>
        <p>
          Financial Services Department<br />
          Level1, North Building, MILA University<br />
          No.5, Jalan Universiti<br />
          Bandar Sunway<br />
          47500 Selangor Darul Ehsan<br />
          Malaysia
        </p>
      </div>
    </div>
    <webBottom />
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import webBottom from "@/components/webBottom.vue";
export default {
  components: {
    collegeHeader,
    webBottom,
  },
};
</script>
<style lang="scss" scoped>
.header-part {
  width: 100%;
  height: 400px;
  background-image: url("../assets/2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 0;
}
::v-deep {
  .el-collapse-item__header {
    padding: 0 20px;
    background-color: #1c376a;
    color: #fff;
    font-size: 20px;
  }
}
</style>
<style lang="scss" type="text/scss" src="../assets/style/PaymentInstructions.scss">
