<template>
  <div>
    <collegeHeader />
    <div class="header-part flex-ac">
      <div style="width: 800px; margin: 0 auto">
        <div class="title" style="text-align: center">
          SCHOOLLIfe
        </div>
        <div class="second-title">HOME LIMU CENTRE FORBGTANYERSIT</div>
      </div>
    </div>
    <div
      class="dipoma-main"
      style="
        padding-top: 20px;
        padding-left: 50px;
        padding-right: 50px;
        background-color: rgb(251, 251, 251);
        overflow: hidden;
      "
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="">Home</a></li>
        <li class="breadcrumb-item">Courses</li>
        <li class="breadcrumb-item active">CampusLife</li>
      </ol>
      <div class="apply-now-title" style="">
        Unlock your full potential for a prosperous future in the business world
      </div>
      <p>
        Embarkon atenstomatine souation iourey WiTh SUCws Bus nes Schoel and fur
        wly Un-etss Dio nehesanos of seceuianwcroeames designed to pro oel your
        career in verious discipines. Whether you have ambitions to becomre an
        acooma lished acoountant. a sstaregic manager, a dyeemie markener, or an
        expet in finance, entreprenewrshin, glsba sumply chain, interational
        bisiness or business aralytice, we haee the perfect edusatio-al path to
        fuifi you apioatione
      </p>
      <img
        src="../assets/2.jpg"
        style="width: 400px; height: 200px; margin: 15px 0"
      />
      <p>
        Our pricle iirs in the presigious aoorssitation bislowed uson Sunmvay
        Busd"ess school by the soaciation to Advance Dalkeghate Schoos of
        Budnwss |AncSe.wich this accolade, camed by ess than be of laerindss
        schools worldwide, you can be conident r at you will receive a top notch
        edscation of unpan leled ousity and
      </p>
      <img
        src="../assets/3.jpg"
        style="width: 400px; height: 250px; margin: 15px 0"
      />
      <p>
        Our pricle iirs in the presigious aoorssitation bislowed uson Sunmvay
        Busd"ess school by the soaciation to Advance Dalkeghate Schoos of
        Budnwss |AncSe.wich this accolade, camed by ess than be of laerindss
        schools worldwide, you can be conident r at you will receive a top notch
        edscation of unpan leled ousity and
      </p>
      <img
        src="../assets/4.jpg"
        style="width: 400px; height: 300px; margin: 15px 0"
      />
      <p>
        Embarkon atenstomatine souation iourey WiTh SUCws Bus nes Schoel and fur
        wly Un-etss Dio nehesanos of seceuianwcroeames designed to pro oel your
        career in verious discipines. Whether you have ambitions to becomre an
        acooma lished acoountant. a sstaregic manager, a dyeemie markener, or an
        expet in finance, entreprenewrshin, glsba sumply chain, interational
        bisiness or business aralytice, we haee the perfect edusatio-al path to
        fuifi you apioatione
      </p>
    </div>
    <!-- <bottomleft /> -->
    <webBottom />
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import webBottom from "@/components/webBottom.vue";
import bottomleft from "@/components/bottomLeft.vue";
export default {
  components: {
    collegeHeader,
    webBottom,
    // bottomleft
  },
};
</script>
<style lang="scss" scoped>
.header-part {
  width: 100%;
  height: 400px;
  background-image: url("../assets/2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 0;
}
</style>
<style lang="scss" type="text/scss" src="../assets/style/SchoolLifte.scss">
