<template>
  <div class="current-campus">
    <collegeHeader />
    <img
      src="//31814893.s21i.faiusr.com/2/ABUIABACGAAgvp6yqgYonsPNowIwgA849AM.jpg.webp&quot"
    />
    <div class="university-wrap">
      <div
        class="flex-ac"
        style="width: 90%; margin: 50px auto; align-items: flex-start; justify-content: center;"
      >
        <div class="art-main">
          <div class="title">{{ article.categoryData.name }}</div>
           <img :src="article.images"  style="margin-bottom:10px;"/>
          <p v-html="article.content">
          </p>
          <!-- <p>
            学校位于雪兰莪州的双威市，占地22亩。学校拥有非常先进的教学设施，最新建成的图书楼和综合教学楼都按照国际最先进的设施完善而成。此外，学校还通过树冠走道与周边社区设施相连，为师生提供了独特的可持续发展环境。学校所在的双威市也是一个可持续发展的城市，步行15分钟内即可使用所有便利设施。
          </p>
          <img src="../assets/3.jpg" />
          <p>
            　除了拥有良好的教学和研究设施，双威大学还与许多国际大学建立了紧密的合作关系，包括哈佛大学、牛津大学和剑桥大学等。这些国际大学的教授们也不定期为双威大学和双威市社区提供公开演讲。
          </p> -->
          <!-- <img src="../assets/5.jpg" /> -->
          <!-- <quill-editor
            ref="myQuillEditor"
            v-model="content"
            :options="editorOption"
            class="my-quill-editor"
          ></quill-editor> -->
        </div>
        <div>
          <div class="art-left">
            <div class="title-second">
              <div class="flex-cc">
                <div class="art-box"></div>
                Article List
              </div>
            </div>
            <div
              class="item"
              v-for="(item, index) in linkList"
              :key="index"
              @click="getArticleDetail(item.articleId)"
            >
              {{ item.title }}
            </div>
            <div class="line"></div>
            <el-pagination
              style="margin: 10px 0"
              layout="prev, pager, next"
              :page-size="10"
              :total="total"
              :current-page="page"
              @current-change="getchangePage">
            </el-pagination>
            <!-- <div class="item">
                前沿国家政策提升助学金标准
            </div>
            <div class="line"></div>
            <div class="item">
                也谈“几何直观”的认识及运用
            </div>
            <div class="line"></div>
            <div class="item">
                物流管理专业就业前景
            </div>
            <div class="line"></div>
            <div class="item">
            计算机软件技术就业前景
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <webBottom />
    <!-- <bottomleft /> -->
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import webBottom from "@/components/webBottom.vue";
import bottomleft from "@/components/bottomLeft.vue";
import { getArticleList, getArticleDetail } from "@/api/home";
export default {
  components: {
    collegeHeader,
    webBottom,
    // bottomleft,
  },
  created() {
    this.initData();
  },
  data() {
    return {
      article: {},
      linkList: [],
      total: 0,
      page: 1
    };
  },
  methods: {
    initData() {
      this.getArticleList();
    },
    getArticleList() {
      getArticleList({module:'about',pageNum:this.page,pageSize:10,orderByColumn:'updateTime',isAsc:'desc'}).then((res) => {
        // console.log('数据库',res)
        this.linkList = res.rows;
        this.total = res.total;
        this.getArticleDetail(res.rows[0].articleId);
      });
    },
    getArticleDetail(value) {
      getArticleDetail({ value: value }).then((res) => {
        // console.log('查看文章详情',res)
        this.article = res.data;
        this.article.images = this.imgUrl + this.article.images
      });
    },
    getchangePage(page) {
      this.page = page;
      this.getArticleList()
    },
  },
};
</script>
<style lang="scss" type="text/scss" src="../assets/style/OurUniversity.scss" />