<template>
  <div class="campus-universally">

    <bannerImage v-if="$route.query.name=='News'" :image="'http://47.250.39.153:8600/upload/2024/02/22/Student Canteen No.2_20240222114945A067.jpg'"></bannerImage>
    <bannerImage v-if="$route.query.name=='Events'" :image="'http://47.250.39.153:8600/upload/2024/02/22/West-Gate_20240222114954A068.jpg'"></bannerImage>
    <bannerImage v-else-if="$route.query.name!='Events'&$route.query.name!='News'" :image="image"></bannerImage>

    <div class="universally-content universally-f">

      <div class="flex-ac" style="margin: 20px auto; align-items: flex-start; justify-self: center;">

        <div class="list-call">
          <div class="content">
            <div class="nei">
              <h1 style="text-align: left;margin: auto 0; font-size: 28px;margin-bottom: 20px">{{ form.title}}
              </h1>
<!--
              <span style="font-size: 13px;font-weight: 500;color: #646464;float: left;margin: 0;margin-right: 10px;">{{ form.createTime }}</span>
              <span style="font-size: 13px;font-weight: 500;color: #646464;">{{ form.summary }}</span>
              <span style="font-size: 13px;font-weight: 500;color: #646464;margin-bottom: 20px">{{ form.remark }}</span>
-->

              <div class="html-con" v-html="form.content"></div>
              <!-- 九宫格显示图片 -->
              <div v-if="showImages">
                <el-row :gutter="20">
                  <el-col :span="8" v-for="(item, index) in images" :key="index">
                    <el-image style="width: 100%;aspect-ratio: 4/3" :src="item" fit="cover"></el-image>
                  </el-col>
                </el-row>
              </div> 

            </div>
          </div>
        </div>
      </div>
    </div>

    <!--底部导航栏-->
    <footCom></footCom>
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import { getArticleDetail1 } from "@/api/home";
import bannerImage from "@/views/component/bannerImage.vue";

export default {
  components: {
    bannerImage
  },
  created() {
    if(this.$route.path === '/campus' && this.$route.query.name === 'Hostel'){
      this.showImages = true;
    }
    this.initData();
  },
  data() {
    return {
      form: {},
      image:null,
      showImages: false,
      images:[
        require('@/assets/palacegrid/1.jpg'),
        require('@/assets/palacegrid/2.jpg'),
        require('@/assets/palacegrid/3.jpg'),
        require('@/assets/palacegrid/4.jpg'),
        require('@/assets/palacegrid/5.jpg'),
        require('@/assets/palacegrid/6.jpg'),
        require('@/assets/palacegrid/7.jpg'),
        require('@/assets/palacegrid/8.jpg'),
        require('@/assets/palacegrid/9.jpg'),
      ],
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.getNewList();
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    initData() {
      this.getNewList();
    },
    getNewList() {
      getArticleDetail1({ value: this.$route.query.categoryId }).then((response) => {
        if (response.data && response.data.images) {
          response.data.images = this.imgUrl + response.data.images
          this.image = response.data.images
        }
        this.form = response.data
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.current-campus {
  margin-top: 100px;
  // height: 100vh;
}

.university-wrap {
  min-height: calc(100vh - 100px - 72px);
}

.list-call {
  width: 100%;
  height: auto;
  margin-top: 20px;

  .content {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    width: 100%;

    img:after,
    img:before {
      max-width: 100%;
    }
  }

  .nei {
    width: 100%;
    margin: 0 24px;
    margin-bottom: 15px;
    background-color: #fff;
    .p-imgs {
      width: 100%;
      height: auto;
    }

    p {
      margin: 8px 0;
      font-size: 18px;
      font-family: 'SourceSansPro', Helvetica, Arial, sans-serif;
      color: #003572;
    }

    div {
      margin-bottom: 16px;
      text-align: left;
    }

    span {
      display: block;
      text-align: left;
      margin: 10px 0;
      font-size: 15px;
    }
  }
}
</style>
<style lang="scss" type="text/scss" src="../../assets/style/OurUniversity.scss" />
