<template>
  <div id="demo" class="carousel-mian">
    <div class="swiper-container swiper-container-horizontal swiper-carousel">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in showList" :key="index">
          <div class="slide-img"
           :style="{
            backgroundImage: `url('${item}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
          
          }">
          </div>
        </div>

      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-carousel">
        <span v-for="(item, index) in showList" :key="item"
          :class="['swiper-pagination-bullet', index == swiperIndex ? 'swiper-pagination-bullet-active' : '']"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "carousel",
  components: {
  },
  data() {
    return {
      timer: "",
      long: 60,
      input: "",
      swiperIndex: 0,
      mySwiper: undefined,
    };
  },
  props: {
    imgList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    showList() {
      this.$nextTick(() => {
        this.initSwiper()
      })
      return this.imgList
    }
  },
  // watch: {
  //   imgList:function(value) {
  //     console.log('变化',value)
  //   }
  // },
  methods: {
    initSwiper() {
      setTimeout(() => {
        if (this.mySwiper != undefined && this.mySwiper != 'undefined') {
          // this.mySwiper.update();
        } else {
          this.mySwiper = new Swiper('.swiper-carousel', {
            pagination: '.swiper-pagination-carousel',
            loop: true,
            preventClicks: false,//默认true
            autoplayDisableOnInteraction: false,
            paginationClickable: true,
            autoplay: 5000,//可选选项，自动滑动
            observer: true,
            observeParents: true,
            onSlideChangeEnd: (swiper) => {
              this.swiperIndex = swiper.realIndex
            }
          });
        }
      }, 3000)
    }
  },

  mounted() {

  }
};
</script> 
<style lang="scss">
.swiper-pagination-bullet {
  width: 25px !important;
  border-radius: 4px !important;
}

.swiper-pagination-bullet-active {
  background: #fff !important;
}
</style>
<style lang="scss" scoped>
.carousel-mian {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;


  .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-wrapper {
      width: 100%;
      height: 100%;

      .swiper-slide {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .slide-img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }


}
</style>