var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('bannerImage',{attrs:{"image":_vm.image}}),_c('div',{staticClass:"container"},[_c('div',{style:({
    textAlign: 'left',
    width: '900px',
    margin: '0 auto 50px auto',
  })},[_c('div',{style:({
    margin: '20px 0 20px 0',
  })},[_c('span',{style:({
    fontSize: '20px',
    marginRight: '10px',
  })},[_vm._v("About Us")]),_c('span',{style:({
    marginRight: '10px'
  })},[_vm._v(">")]),_c('span',{style:({
    color: '#465584'
  })},[_vm._v("Governance and Leadership")])]),_c('div',[_c('h1',{style:({
    fontSize: '40px',
    color: '#000',
    margin: '0 0 40px 0',
    padding: '30px 0 30px 0',
    borderBottom: '4px solid #465584',
  })},[_vm._v("Governance and Leadership")])]),_c('div',{style:({
    borderRadius: '20px',
    overflow: 'hidden',
    border: '1px solid #EBF0FF',
    cursor: 'pointer',
  })},_vm._l((_vm.profiles),function(i,k){return _c('div',{key:k,style:({
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottom: '1px solid #EBF0FF',
    padding: '20px 0 20px 0',
    margin: '0',
  }),on:{"click":function($event){return _vm.goDetail(i.index)}}},[_c('svg',{style:({
    margin: '0 20px 0 35px',
    backgroundColor: '#465584',
    color: '#fff',
    padding: '3px',
    borderRadius: '50%',
  }),attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"1.2em","height":"1.2em","viewBox":"0 0 2048 2048"}},[_c('path',{attrs:{"fill":"currentColor","d":"M1168 946q38 15 74 33t70 41q-22 24-40 50t-33 56q-77-50-164-76t-179-26q-88 0-170 23t-153 64t-129 100t-100 130t-65 153t-23 170H128q0-120 35-231t101-205t156-167t204-115q-113-74-176-186t-64-248q0-106 40-199t109-163T696 40T896 0q106 0 199 40t163 109t110 163t40 200q0 66-16 129t-48 119t-75 103t-101 83M512 512q0 80 30 149t82 122t122 83t150 30q79 0 149-30t122-82t83-122t30-150q0-79-30-149t-82-122t-123-83t-149-30q-80 0-149 30t-122 82t-83 123t-30 149m1472 832q0 52-16 101t-48 91v512l-256-128l-256 128v-512q-31-42-47-91t-17-101q0-66 25-124t68-101t102-69t125-26q66 0 124 25t101 69t69 102t26 124m-320-192q-40 0-75 15t-61 41t-41 61t-15 75q0 40 15 75t41 61t61 41t75 15q40 0 75-15t61-41t41-61t15-75q0-40-15-75t-41-61t-61-41t-75-15m128 689v-204q-60 27-128 27t-128-27v204q32-16 64-31t64-33q32 17 64 32t64 32"}})]),_c('div',[_c('p',{style:({
    margin: '0',
  })},[_vm._v(_vm._s(i.name))]),_c('p',{style:({
    margin: '0',
  }),domProps:{"innerHTML":_vm._s(i.title)}})])])}),0)]),_c('footCom')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }