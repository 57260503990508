<template>
  <span>
    <div :class="['foot-custom-mian', index == 1 ? 'foot-float' : '']" v-for="(item, index) in arr" :key="item">
      <div class="middle-content middle-max">
        <div class="foot-enumerate">
          <div class="enumerate-item" v-for="(itemA, indexA) in footArr" :key="indexA">
            <div class="enumerate-title">{{ itemA.title }}</div>
            <div class="enumerate-value" v-for="(item, index) in itemA.list" :key="index"
              @click="handlerToTarget(`/${item.module}`, item.name, item.categoryId)">{{ item.name }}</div>
          </div>
        </div>
        <img class="foot-log" src="@/assets/logo2.png" />
        <di class="foot-title">MILA UNIVERSITY</di>
        <div class="foot-basic">
<!--          <div class="name">@2024 The President and Feows of Mis unveraity</div>-->
          <span class="value" v-for="(item, index) in linkList" :key="index" @click="handlerToLink(item.linkUrl)">
            {{ item.linkName }}</span>

          <img class="ico" src="https://sunwayuniversity.edu.my/themes/custom/sunway/images/icons/waze-icon.png" />
          <img class="ico" src="https://sunwayuniversity.edu.my/themes/custom/sunway/images/icons/google-maps-icon.png" />
        </div>
      </div>
    </div>
  </span>
</template>
<script>
import { getPList, getArticleDetail1 } from '@/api/home.js'
export default {
  name: "footCom",
  components: {
  },
  data() {
    return {
      footArr: [],
    };
  },
  props: {
    type: {
      type: Number,
      default: 0,
    },
  },
  computed: {

    arr() {
      //return this.type === 1 ? ['1'] : ['1', '2']
      return  ['1']
    }

  },

  methods: {
    handlerToTarget(path, name, categoryId) {
      if (path == '/home') {
        this.$router.push({ path: '/', query: { id: 1 } })
        return
      }
      if (path == '/study' || path == '/school' || path == '/student') {
        getArticleDetail1({ value: categoryId }).then((response) => {
          if (response && response.data) {
            this.$router.push({ path: path, query: { name, categoryId } })
          } else {
            this.$message.error('There is currently no introduction to this menu')
          }
        });
        return
      }
      if (this.$route.name == path) {
        return
      }
      this.$router.push({ path: path, query: { name, categoryId } })
    },
    getrouteData() {
      getPList({ pageSize: 1000 }).then((res) => {
        this.footArr = [{
          name: 'school',
          title: 'Schools',
          list: []
        }, {
          name: 'course',
          title: 'Courses',
          list: [{ name: 'Pre-University', module: 'course' }, { name: 'Diploma', module: 'course' }, { name: 'Undergraduate', module: 'course' }, { name: 'Postgraduate', module: 'course' }]
        }, {
          name: 'campus',
          title: 'Campus Life',
          list: []
        }]
        const filterName = ['Schoolstest', 'Research Management Centre', 'Academic Partners']

        if (Array.isArray(res.rows)) {
          res.rows.forEach(item => {
            if (item.parentId != 0  || filterName.includes(item.name)) return
            for (let i = 0; i < this.footArr.length; i++) {
              if (this.footArr[i].name == item.module && item.module != 'course') {
                this.footArr[i].list.push(JSON.parse(JSON.stringify(item)))
              }
            }
          })
        }
      })
    },
  },

  mounted() {
    this.getrouteData()
  }
};
</script>
<style lang="scss" scoped> .foot-custom-mian {
   position: relative;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #003572;


   .middle-content {
     width: 100%;
     padding: 20px 50px;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-flow: column;

     .foot-enumerate {
       width: 60%;
       display: flex;
       justify-content: space-between;
       align-items: flex-start;

       .enumerate-item {

         .enumerate-title {
           width: 100%;
           display: block;
           color: #fff;
           text-align: left;
           font-size: 18px;
           line-height: 2.3;
         }

         .enumerate-value {
           width: 100%;
           display: block;
           color: #f2f2f2;
           font-size: 14px;
           text-align: left;
           line-height: 2;
           cursor: pointer;
         }
       }
     }

     .foot-log {
       margin-top: 20px;
       margin-bottom: 20px;
       width: 80px;
       height: 85px;
     }

     .foot-title {
       width: 100%;
       display: block;
       color: #f2f2f2;
       font-size: 15px;
       //margin-top: 30px;
     }

     .foot-basic {
       width: 74%;
       display: flex;
       margin-top:40px;
       justify-content: center;
       align-items: center;
       padding-bottom: 30px;
       padding-right: 80px;

       .name {
         flex: 1;
         color: #f2f2f2;
         font-size: 13px;
         text-align: left;
       }

       .value {
         margin-left: 10px;
         color: #f2f2f2;
         font-size: 13px;
         cursor: pointer;
       }

       .ico {
         width: 20px;
         height: 20px;
         margin-left: 10px;
       }
     }
   }
 }

 .foot-float {

   display: flex;
 }
</style>
