import { render, staticRenderFns } from "./Postgradute.vue?vue&type=template&id=023724fc&scoped=true"
import script from "./Postgradute.vue?vue&type=script&lang=js"
export * from "./Postgradute.vue?vue&type=script&lang=js"
import style0 from "./Postgradute.vue?vue&type=style&index=0&id=023724fc&prod&lang=scss&scoped=true"
import style1 from "../assets/style/Dipoma.scss?vue&type=style&index=1&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "023724fc",
  null
  
)

export default component.exports