import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CurrentCampus from '../views/CurrentCampus.vue'
import OurUniversity from '../views/OurUniversity.vue'
import News from '../views/News.vue'
import PreUniversity from '../views/PreUniversity'
import ApplyNow from '../views/ApplyNow'
import ScholarShip from '../views/ScholarShip'
import FinancialAids from '../views/FinancialAids'
import InternationalStudents from '../views/InternationalStudents'
import PaymentInstructions from '../views/PaymentInstructions'
import Publications from '../views/Publications'
import Dipoma from '../views/Dipoma'
import SchoolLife from '../views/SchoolLife'
import CampusLife from '../views/CampusLife'
import Stander from '../views/Stander'

import contactus from '../views/contactus'
import sustainability from '../views/sustainability'


import Detail from '../views/Detail'
import Detaile from '../views/Detail1'
import Detaile_peo from '../views/Detail1_peo'
import Detaile_plo from '../views/Detail1_plo'
import Undergraduate from '../views/Undergraduate'
import Postgradute from '../views/Postgradute'
import list2 from '../views/component/list2'
import course from '../views/component/course'
import introduction from '../views/component/introduction'
import list1 from '../views/component/list1'
import about from '../views/component/about'
import GovernanceAndLeadership from '../views/GovernanceAndLeadership'
import GovernanceAndLeadershipDetail from '../views/GovernanceAndLeadershipDetail'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/currentcampus',
    name: 'currentcampus',
    component: CurrentCampus
  },
  {
    path: '/ouruniversity',
    name: 'ouruniversity',
    component: OurUniversity
  },
  // {
  //   path: '/news',
  //   name: 'news',
  //   component: News
  // },
  {
    path: '/preuniversity',
    name: 'preuniversity',
    component: PreUniversity
  },
  {
    path: '/applynow',
    name: 'applynow',
    component: ApplyNow
  },
  {
    path: '/scholarship',
    name: 'scholarship',
    component: ScholarShip
  },
  {
    path: '/financialaids',
    name: 'financialaids',
    component: FinancialAids
  },
  {
    path: '/internationalstudents',
    name: 'internationalstudents',
    component: InternationalStudents
  },
  {
    path: '/paymentinstructions',
    name: 'paymentinstructions',
    component: PaymentInstructions
  },
  {
    path: '/stander',
    name: 'stander',
    component: Stander
  },
  {
    path: '/publications',
    name: 'publications',
    component: Publications
  },
  {
    path: '/dipoma',
    name: 'dipoma',
    component: Dipoma
  },
  {
    path: '/schoolLife',
    name: 'schoolLife',
    component: SchoolLife
  },

  {
    path: '/campuslife',
    name: 'campuslife',
    component: CampusLife
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: About
  // },
  {
    path: '/contactus',
    name: 'contactus',
    component: contactus
  },
  {
    path: '/sustainability',
    name: 'sustainability',
    component: sustainability
  },
  {
    path: '/Detail',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/Undergraduate',
    name: 'Undergraduate',
    component: Undergraduate
  },
  {
    path: '/Postgradute',
    name: 'Postgradute',
    component: Postgradute
  },
  // news菜单
  {
    path: '/news',
    name: 'news',
    component: list2
  },
  {
    path: '/course',
    name: 'course',
    component: course
  },
  {
    path: '/study',
    name: 'study',
    component: introduction
  },
  {
    path: '/school',
    name: 'school',
    component: introduction
  },
  {
    path: '/student',
    name: 'student',
    component: introduction
  },
  {
    path: '/campus',
    name: 'campus',
    component: list1
  },
  {
    path: '/about',
    name: 'about',
    component:introduction// about
  },
  {
    path: '/Detaile',
    name: 'Detaile',
    component: Detaile
  },
  {
    path: '/Detaile_peo',
    name: 'Detaile_peo',
    component: Detaile_peo
  },
  {
    path: '/Detaile_plo',
    name: 'Detaile_plo',
    component: Detaile_plo
  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  {
    path: '/GovernanceAndLeadership',
    name: 'GovernanceAndLeadership',
    component: GovernanceAndLeadership,
  },
  {
    path:'/GovernanceAndLeadershipDetail',
    name: 'GovernanceAndLeadershipDetail',
    component: GovernanceAndLeadershipDetail
}
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
