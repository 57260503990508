<template>
  <div class="campus-universally">

    <bannerImage :tip="false"  v-if="$route.query.name=='News'" :image="'http://47.250.39.153:8600/upload/2024/02/22/Student Canteen No.2_20240222114945A067.jpg'"></bannerImage>
    <bannerImage :tip="false" v-if="$route.query.name=='Events'" :image="'http://47.250.39.153:8600/upload/2024/02/22/West-Gate_20240222114954A068.jpg'"></bannerImage>
    <bannerImage :tip="false" v-else-if="$route.query.name!='Events'&$route.query.name!='News'" :image="image"></bannerImage>

    <div class="universally-content universally-f">
      <div class="flex-ac" style="margin: 20px auto; align-items: flex-start;  justify-self: center;">
<!--        <h1 style="text-align: left;margin: 20px auto 0; font-weight: 600;">{{ $route.query.name }}</h1>-->

        <div class="list-call">
          <div class="content">
            <div class="nei" @click="gopush(item.articleId)" v-for="(item,index) in newList" :key="index">
              <img class="p-imgs" v-if="item.images" :src="item.images" />
              <span>{{ item.createTime }}</span>
              <p>{{ item.title }}</p>
              <div>{{ item.summary }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-pagination class="pageone" style="margin: 10px 0" background layout="prev, pager, next" :total="total"
      :page-size="10" :current-page="page" @current-change="getchangePage">
    </el-pagination>
    <!--底部导航栏-->
    <footCom></footCom>
  </div>
</template>
<script>
// import collegeHeader from "@/components/head.vue";
import { getNewList, getArticleDetail } from "@/api/home";
import bannerImage from "@/views/component/bannerImage.vue";

export default {
  components: {
    bannerImage
  },
  created() {
    this.initData();
  },
  data() {
    return {
      total: 0,
      page: 1,
      newList: [],
      image:null,
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.getNewList();
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    initData() {
      this.getNewList();
    },
    getNewList() {
      getNewList({ pageNum: this.page, pageSize: 20, module: 'news', categoryName: this.$route.query.name == 'Events' ? 'Events' : 'News', orderByColumn: 'updateTime', isAsc: 'desc' }).then((response) => {
        response.rows.forEach(item => {
          item.images = this.imgUrl + item.images
          this.image = item.images
        })
        this.newList = response.rows;
        this.total = response.total
        // this.total = 200
        console.log("News详情", response.rows, this.newList);
      });
    },
    getchangePage(page) {
      console.log(page);
      this.page = page;
      this.getNewList()
    },
    gopush(id) {
      this.$router.push({ name: 'Detail', params: { id } })
    }
  },
};
</script>
<style lang="scss" type="text/scss" src="../../assets/style/OurUniversity.scss" />
<style lang="scss" scoped>
.current-campus {
  margin-top: 100px;
}

.university-wrap {
  min-height: calc(100vh - 100px - 72px - 52px);
}

.list-call {
  width: 100%;
  height: auto;
  margin-top: 20px;

  .content {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    width: 100%;

    img:after,
    img:before {
      max-width: 100%;
    }
  }

  .nei {
/*    min-width: 302px;
    max-width: 400px;*/
    background-color: #fff;
    cursor: pointer;
    height: 500px;
    width: 50%;
    padding: 0px 10px;

    .p-imgs {
      width: 100%;
      height: 400px;
      box-shadow: 0 0 10px #2c3e50, 0px 1px 0px 0px #2c3e50, 0 0 30px #2c3e50;
      object-fit: cover;
    }

    .p-imgs1 {
      width: 100%;
      height: auto;
    }

    p {
      margin: 8px 0;
      font-size: 14px;
      font-family: 'SourceSansPro', Helvetica, Arial, sans-serif;
      color: #003572;
    }

    div {
      margin-bottom: 16px;
      text-align: left;
    }

    span {
      display: block;
      text-align: left;
      margin: 10px 0;
      font-size: 15px;
    }
  }
}

.pageone {
  .el-pager {
    li {
      width: auto;
    }
  }
}
</style>
