<template>
  <div class="current-campus">
    <div class="university-wrap">
      <h1 style="width: 1400px; min-width: 1400px;text-align: left;margin: 20px auto 0; font-weight: 600;">sustainability</h1>
      <div
        class="flex-ac"
        style="width: 90%; margin: 20px auto; align-items: flex-start; min-width: 1400px; justify-self: center;"
      >
      
      <div class="list-call">
        <div class="content">
          <div class="nei" :class="index % 2 == 0? 'rever':''" v-for="(item, index) in 9" :key="item">
            <img class="p-imgs" src="../assets/1.jpg" />
            <div class="p-right">
              <p style="font-size: 40px; color: #003572;">Commitment to Environmental Sustainability</p>
              <div style="text-align: left;">The University is fully committed to advancing planetary health and sustainability. Climate action and environmental responsibility are at the forefront of our operations. By 2030, we aim to reduce CO2 emissions by 20%, decrease water dependency by 10%, and divert 40% of waste from landfills.</div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <webBottom />
    <!-- <bottomleft /> -->
  </div>
</template>
<script>
import webBottom from "@/components/webBottom.vue";
import bottomleft from "@/components/bottomLeft.vue";
import { getNewList, getArticleDetail } from "@/api/home";
export default {
  components: {
    webBottom,
    // bottomleft,
  },
  created() {
    this.initData();
  },
  data() {
    return {
      article: {},
      linkList: [],
      content: "",
    };
  },
  methods: {
    initData() {
      this.getArticleList();
    },
    getArticleList() {
      getNewList().then((res) => {
        // console.log('数据库',res)
        this.linkList = res.rows;
        this.getArticleDetail(res.rows[0].articleId);
      });
    },
    getArticleDetail(value) {
      console.log(value,'value')
      getArticleDetail({ value: value }).then((res) => {
        // console.log('查看文章详情',res)
        this.article = res.data;
        this.article.images?this.article.images = this.imgUrl + this.article.images:''
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.list-call {
  width: 100%;
  height: auto;
  margin-top: 20px;
  .content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 1400px;
    background-color: #fff;
  }
  .nei {
    display: flex;
    width: 100%;
    margin: 20px 24px;
    margin-bottom: 15px;
    background-color: #fff;
    .p-imgs {
      width: 50%;
      height: auto;
    }
    .p-right {
      width: 50%;
      height: auto;
      padding: 20px;
      box-sizing: border-box;
    }
  }
  .rever {
    flex-direction: row-reverse;
  }
}
</style>
<style lang="scss" type="text/scss" src="../assets/style/OurUniversity.scss" />