<template>
  <div>
    <collegeHeader />
    <div class="header-part flex-ac">
      <div>
        <div class="title" style="text-align: left; margin-left: 35px;">
          welcome to our world of earch
        </div>
      </div>
    </div>
 <!--   <img class="font-s" style="margin-top: 30px;" src="../assets/shutterstock_14293893891.jpg">-->
    <h1 class="font-s">Overview</h1>
    <div class="font-s" style="text-align: left;width: 1400px;margin: 0 auto;">In addition to its academic divisions, MILA University houses several research centres and groups that pursue various research efforts across diverse fields of study. </div>
    <div class="list-call">
      <div class="content">
        <div class="nei" v-for="item in 9" :key="item">
          <img class="p-imgs" src="../assets/1.jpg" />
          <p>The Asia Pacific Centre for Hospitality Research (APCHR)</p>
          <div>Research activities at APCHR revolve around the intersecting fields of hospitality, tourism, leisure, culinary arts and events. </div>
        </div>
      </div>
    </div>
    <webBottom />
    <!-- <bottomleft /> -->
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import webBottom from "@/components/webBottom.vue";
import bottomleft from "@/components/bottomLeft.vue";
export default {
  components: {
    collegeHeader,
    webBottom,
    // bottomleft
  },
};
</script>
<style lang="scss" scoped>
.header-part {
  width: 100%;
  height: 400px;
  background-image: url("../assets/2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 0;
}
.font-s {
  width: 1400px;
  text-align: left;
  margin: 0 auto;
  padding: 0 24px;
  box-sizing: border-box;
}
.list-call {
  width: 100%;
  height: auto;
  margin-top: 20px;
  .content {
    display: flex;
    flex-wrap: wrap;
    width: 1400px;
    background-color: #fff;
  }
  .nei {
    min-width: 302px;
    max-width: 465px;
    padding: 0 24px;
    margin-bottom: 15px;
    .p-imgs {
      width: 100%;
      height: auto;
    }
    p {
      margin: 8px 0;
      font-size: 18px;
      font-family: 'SourceSansPro', Helvetica, Arial, sans-serif;
      color: #003572;
    }
    div {
      margin-bottom: 16px;
      text-align: left;
    }
  }
}
</style>
<style lang="scss" type="text/scss" src="../assets/style/Pulications.scss">
