<template>
  <div>
    <collegeHeader />
    <carousel />
    <webBottom />
    <!-- <bottomleft /> -->
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import webBottom from "@/components/webBottom.vue";
import carousel from "@/components/carousel.vue";
import bottomleft from "@/components/bottomLeft.vue";
export default {
  components: {
    collegeHeader,
    webBottom,
    carousel,
    // bottomleft
  },
};
</script>
<style lang="scss" scoped>
.header-part {
  width: 100%;
  height: 400px;
  background-image: url("../assets/2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 0;
}
</style>
