<template>
  <div>
    <collegeHeader />
    <div class="header-part flex-ac">
      <div style="width: 800px; margin: 0 auto">
        <div class="title" style="text-align: center">FINANCIAL AIDS</div>
        <div class="second-title">HOME LIMU CENTRE FORBGTANYERSIT</div>
      </div>
    </div>
    <div class="school-data">
      <div class="school-left">
        <div
          class="p-div"
          :class="current == index ? 'p-div-active' : ''"
          v-for="(item, index) in list"
          :key="index"
          @click="handlerSelected(index)"
        >
          {{ item }}
        </div>
        <!-- <div class="p-div">UNDERGRADUATE</div>
        <div class="p-div">POSTGRADUATE</div>
        <div class=""></div> -->
      </div>
      <div class="school-right">
        <div style="margin-top: 10px; display: flex; padding-left: 93px">
          <img class="school-img" src="../assets/1.jpg" alt="" />
        </div>
        <div style="padding-left: 93px">
          <div class="second-titles p-t-20 p-b-20" style="font-size: 30px">
            National Higher Education Fund or Perbadanan Tabung Pendidikan
            Tinggi Nasional(PTPTN)
          </div>
          <div class="p-div p-b-20">
            National Higher Education Fund or Perbadanan Tabung Pendidikan
            Tinggi Nasional (PTPTN) is a government initlative aimed to provide
            education loan to students pursuing their studies at local HONG
            LEONG TOUNDATION higher education institution.
          </div>
          <div class="p-div p-t-20 p-b-20" style="color: #2858b2">
            TERMS AND CONDITIONS
          </div>
          <div class="flex-cc p-b-20" style="justify-content: left">
            <i class="el-icon-caret-right" style="font-size: 25px"></i>
            <div class="p-div" style="font-size: 16px">
              The PTPTN loan SONLY available for the local option proyrammes
              conducted fully in Malaysia
            </div>
          </div>
          <div
            class="flex-cc p-b-20"
            style="justify-content: left; align-items: flex-start"
          >
            <i class="el-icon-caret-right" style="font-size: 25px"></i>
            <div class="p-div" style="font-size: 16px">
              For the credit transtct programmes (Medicine & Dentstry).PTPTN
              wi!l ONLY cover the duration<br />
              YAYASAN SARAWAK the student is in Malaysla.
            </div>
          </div>
          <div class="flex-cc p-b-20" style="justify-content: left">
            <i class="el-icon-caret-right" style="font-size: 25px"></i>
            <div class="p-div" style="font-size: 16px">
              The PTPTN loan SONLY available for the local option proyrammes
              conducted fully in Malaysia<br />
              YAYASAN SARAWAK the student is in Malaysla.
            </div>
          </div>
          <div class="flex-cc p-b-20" style="justify-content: left;align-items: flex-start;">
            <i class="el-icon-caret-right" style="font-size: 25px"></i>
            <div class="p-div" style="font-size: 16px">
              Student loan SONLY available for the local option proyrammes
              conducted fully in Malaysia<br />
              YAYASAN SARAWAK the student is in Malaysla.
            </div>
          </div>
          <div class="p-div p-t-20 p-b-20" style="color: #2858b2">
            ENIGIBILITY TO APPLY
          </div>
          <div class="flex-cc p-b-20" style="justify-content: left">
            <i class="el-icon-caret-right" style="font-size: 25px"></i>
            <div class="p-div" style="font-size: 16px">
              Malaysian (45 and below).
            </div>
          </div>
          <div
            class="flex-cc p-b-20"
            style="justify-content: left; align-items: flex-start"
          >
            <i class="el-icon-caret-right" style="font-size: 25px"></i>
            <div class="p-div" style="font-size: 16px">
             Must not be sponsored presently by any sponsoring body.
            </div>
          </div>
          <div class="flex-cc p-b-20" style="justify-content: left;align-items: flex-start;">
            <i class="el-icon-caret-right" style="font-size: 25px"></i>
            <div class="p-div" style="font-size: 16px">
              The PTPTN loan SONLY available for the local option proyrammes
              conducted fully in Malaysia<br />
              *YAYASAN SARAWAK the student is in Malaysla.
              <br />
              *YAYASAN SARAWAK the student is in Malaysla.
              <br />
              *YAYASAN SARAWAK the student is in Malaysla.
            </div>
          </div>
          <div class="flex-cc p-b-20" style="justify-content: left;align-items: flex-start;">
            <i class="el-icon-caret-right" style="font-size: 25px"></i>
            <div class="p-div" style="font-size: 16px">
              Student loan SONLY available for the local option proyrammes
              conducted fully in Malaysia<br />
              YAYASAN SARAWAK the student is in Malaysla.
            </div>
          </div>
          <div class="p-div p-t-20 p-b-20" >
            To apply online, student needs to:
          </div>
          <div class="flex-cc p-b-20" style="justify-content: left;align-items: flex-start;">
            <i class="el-icon-caret-right" style="font-size: 25px"></i>
            <div class="p-div" style="font-size: 16px">
              CIMB Account<br />
              *YAYASAN SARAWAK the student is in Malaysla.
              <br />
              *YAYASAN SARAWAK the student is in Malaysla.
              <br />
              *YAYASAN SARAWAK the student is in Malaysla.
            </div>
          </div>
          <div class="flex-cc p-b-20" style="justify-content: left;align-items: flex-start;">
            <i class="el-icon-caret-right" style="font-size: 25px"></i>
            <div class="p-div" style="font-size: 16px">
              SSPN Account<br />
              *YAYASAN SARAWAK the student is in Malaysla at <span style="color:rgba(204, 151, 37, 0.955);">https://baidu.com</span>.
              <br />
              *YAYASAN SARAWAK the student is in Malaysla.
              <br />
              *YAYASAN SARAWAK the student is in Malaysla.
            </div>
          </div>
          <div class="p-div p-t-20 p-b-20" style="color: #2858b2;">
            To apply online, student needs to:
          </div>

          <div class="flex-cc">
            <div class="table-head flex-cc" style="width: 30%;font-size: 12px;font-size: 12px;">
              Scholarship Amount
            </div>
            <div class="table-head flex-cc" style="width:30%;font-size: 12px;">
              YAYASAN SARAWAK the student
            </div>
            <div class="table-head flex-cc" style="width: 40%;font-size: 12px;">
              Scholarship Amount
            </div>
          </div>

          <div class="flex-cc">
            <div class="table-tb flex-cc" style="width: 30%">RM13,500</div>
            <div class="table-tb flex-cc" style="width: 30%">RM13,500</div>
            <div class="table-tb flex-cc" style="width: 40%">8As</div>
          </div>
          <div class="flex-cc">
            <div class="table-tb flex-cc" style="width: 30%">RM13,500</div>
            <div class="table-tb flex-cc" style="width: 30%">RM13,500</div>
            <div class="table-tb flex-cc" style="width: 40%">8As</div>
          </div>
          <div class="flex-cc">
            <div class="table-tb flex-cc" style="width: 30%">RM13,500</div>
            <div class="table-tb flex-cc" style="width: 30%">RM13,500</div>
            <div class="table-tb flex-cc" style="width: 40%">8As</div>
          </div>
          <div class="p-div p-t-20 p-b-20" style="color: #2858b2">
            TERMS AND CONDITIONS
          </div>
          <div class="flex-cc p-b-20" style="justify-content: left">
            <i class="el-icon-caret-right" style="font-size: 25px"></i>
            <div class="p-div" style="font-size: 16px">
              The PTPTN loan SONLY available for the local option proyrammes
              conducted fully in Malaysia
            </div>
          </div>
          <div
            class="flex-cc p-b-20"
            style="justify-content: left; align-items: flex-start"
          >
            <i class="el-icon-caret-right" style="font-size: 25px"></i>
            <div class="p-div" style="font-size: 16px">
              For the credit transtct programmes (Medicine & Dentstry).PTPTN
              wi!l ONLY cover the duration<br />
              YAYASAN SARAWAK the student is in Malaysla.
            </div>
          </div>
          <div class="p-div p-t-20 p-b-20" style="color: #2858b2;">
            Tstudent needs to:
          </div>
          <div class="flex-cc">
            <div class="table-head flex-cc" style="width: 40%">
              Scholarship Amount
            </div>
            <div class="table-head flex-cc" style="width: 60%">
              SPM / O-Levels Required
            </div>
          </div>
          <div class="flex-cc">
            <div class="table-tb flex-cc" style="width: 40%">RM13,500</div>
            <div class="table-tb flex-cc" style="width: 60%">8As</div>
          </div>
          <div class="flex-cc">
            <div class="table-tb flex-cc" style="width: 40%">RM8,000*</div>
            <div class="table-tb flex-cc" style="width: 60%">7As</div>
          </div>
          <div class="flex-cc">
            <div class="table-tb flex-cc" style="width: 40%">RM6,500</div>
            <div class="table-tb flex-cc" style="width: 60%">8As</div>
          </div>
          <div class="p-div p-t-20 p-b-20" style="color: #2858b2">
            TERMS AND CONDITIONS
          </div>
          <div class="flex-cc p-b-20" style="justify-content: left">
            <i class="el-icon-caret-right" style="font-size: 25px"></i>
            <div class="p-div" style="font-size: 16px">
              The PTPTN loan SONLY available for the local option proyrammes
              conducted fully in Malaysia
            </div>
          </div>
          <div
            class="flex-cc p-b-20"
            style="justify-content: left; align-items: flex-start"
          >
            <i class="el-icon-caret-right" style="font-size: 25px"></i>
            <div class="p-div" style="font-size: 16px">
              For the credit transtct programmes (Medicine & Dentstry).PTPTN
              wi!l ONLY cover the duration<br />
              YAYASAN SARAWAK the student is in Malaysla.
            </div>
          </div>
          <!-- <div class="flex-cc">
            <div class="table-tb flex-cc" style="width: 40%">RM4,500</div>
            <div class="table-tb flex-cc" style="width: 60%">8As</div>
          </div> -->

        </div>
      </div>
    </div>
    <webBottom />
    <!-- <bottomleft /> -->
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import webBottom from "@/components/webBottom.vue";
import bottomleft from "@/components/bottomLeft.vue";
export default {
  components: {
    collegeHeader,
    webBottom,
    // bottomleft
  },
  data() {
    return {
      list: [
        "NHEF/PTPTN",
        "PSD /JPA",
        "KUOK FOUNDATION",
        "EPF/KWSP",
        "MAYBANK EDUCATION LOAN",
        "OCBC EARN & LEARN EDUCATION LOAN",
        "MAJLIS AMANAH RAKYAT (MARAI)",
        "HONG LEONG TOUNDATION",
        "KOPERAS! DAYADIRI MALAYSIA DERHAD",
        "YAYASAN TUNKU ABDUL RAHMAN",
        "YAYASAN SARAWAK",
      ],
      current: "0",
    };
  },
  methods: {
    handlerSelected(index) {
      this.current = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.header-part {
  width: 100%;
  height: 400px;
  background-image: url("../assets/2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 0;
}
</style>
<style lang="scss" type="text/scss" src="../assets/style/FinancialAids.scss">
