<template>
  <div class="campus-universally">
    <div class="universally-content universally-f">

      <div class="flex-ac" style="margin: 20px auto; align-items: flex-start;  justify-self: center;">

        <div class="list-call">
          <div class="content">
            <div class="nei">
              <h1 style="text-align: left;margin: auto 0; font-size: 28px;margin-bottom: 20px">{{ form.title}}
              </h1>
<!--
              <span style="font-size: 13px;font-weight: 500;color: #646464;float: left;margin: 0;margin-right: 10px;">{{ form.createTime }}</span>
              <span style="font-size: 13px;font-weight: 500;color: #646464;">{{ form.summary }}</span>
              <span style="font-size: 13px;font-weight: 500;color: #646464;margin-bottom: 20px">{{ form.remark }}</span>
-->

              <!--<img class="p-imgs" v-if="form.images" :src="form.images" />-->
              <div v-html="form.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--底部导航栏-->
    <footCom></footCom>
  </div>
</template>
<script>
import { getArticleDetail } from "@/api/home";
export default {
  components: {
  },
  created() {
    this.initData();
  },
  data() {
    return {
      form: {}
    };
  },
  methods: {
    initData() {
      this.getNewList();
    },
    getNewList() {
      getArticleDetail({ value: this.$route.params.id }).then((response) => {
        if (response.data && response.data.images) {
          response.data.images = this.imgUrl + response.data.images
        }
        this.form = response.data
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.current-campus {
  margin-top: 100px;
}

.university-wrap {
  min-height: calc(100vh - 100px - 72px);
}

.list-call {
  width: 100%;
  height: auto;
  margin-top: 20px;

  .content {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    width: 100%;

    img:after,
    img:before {
      max-width: 100%;
    }
  }

  .nei {
    width: 100%;
    margin: 0 24px;
    margin-bottom: 15px;
    background-color: #fff;

    .p-imgs {
      width: 100%;
      height: auto;
    }

    p {
      margin: 8px 0;
      font-size: 18px;
      font-family: 'SourceSansPro', Helvetica, Arial, sans-serif;
      color: #003572;
    }

    div {
      margin-bottom: 16px;
      text-align: left;
    }

    span {
      display: block;
      text-align: left;
      margin: 10px 0;
      font-size: 15px;
    }
  }
}
</style>
<style lang="scss" type="text/scss" src="../assets/style/OurUniversity.scss" />
