<template>
  <div class="container">

    <bannerImage :image="image"></bannerImage>

    <div style="margin: 90px auto; max-width: 860px;">

      <img :src="profile.image" style="border-radius: 10px;margin-bottom: 30px;max-height: 600px;" />
  
      <h3> {{ profile.name }} </h3>
      <h4 v-html="profile.title"></h4>
  
  
      <p style="padding: 20px"> {{ profile.introduction }} </p>

    </div>



    <!--底部导航栏-->
    <footCom></footCom>
  </div>
</template>
<script>
import { getArticleDetail1 } from "@/api/home";
import bannerImage from "@/views/component/bannerImage.vue";

export default {
  components: {
    bannerImage
  },
  created() {
    this.params = this.$route.query.params;

    console.log("this", this);
    this.initData();

    var profile = null;
    for (var i = 0; i < this.profiles.length; i++) {
      if (this.profiles[i].index == parseInt(this.params)) {
        profile = this.profiles[i];
        break;
      }
    }
    this.profile = profile;
  },

  data() {

    var profiles = [
        {
          index: 1,
          name: 'Prof. Dr. Jason Fitzsimmons',
          title: 'Vice Chancellor',
          introduction: 'Prof. Dr. Jason Fitzsimmons comes with over 20 years of experience in higher education and during that period he has held senior academic roles with extensive experience in international recruitment, programme development, academic collaborations, executive education programmes and the integration of technology in learning environments. He has lived and taught in various countries including Australia, India, Malaysia, Singapore and the United Arab Emirates. ',
          image: require('@/assets/leader01.jpg'),
        },
        {
          index: 2,
          name: 'Prof. Dr. Graham Kendall',
          title: 'Deputy Vice Chancellor - Quality Assurance & Research',
          introduction: 'Prof. Dr. Graham Kendall is an academic but has spent a significant amount of his career in industry, holding senior positions in the IT industry (in the UK) and C-suite positions in Malaysia, in both industry and academia. He was the Provost/CEO of the University of Nottingham Malaysia (5,000 students, with 650 staff on a 125-acre estate). He also served as a Pro-Vice Chancellor for the global University. He is still affiliated with Nottingham, as an Emeritus Professor. He holds distinguished and honorary positions in Malaysia, India and Hong Kong.',
          image: require('@/assets/leader02.jpg'),
        },
        {
          index: 3,
          name: 'Mr. Zhao Baoli',
          title: 'Vice President - Facilities, Foundation & Development',
          introduction: 'Zhao Baoli is the Vice President of Mila University. He was formerly the Vice President of Hainan SaiboLe Education Group， and the Assistant to the President and Director of Infrastructure at Hainan University of Economics, as well as having rich experience in education management and campus construction. Earlier in his career, he served as the CEO of Changying Group\'s Hainan Cultural Industry Company, bringing with him extensive experience in business management & project planning and construction.',
          image: require('@/assets/leader03.jpg'),
        },
        {
          index: 4,
          name: 'Assoc. Prof. Sargunam Thirugnanam',
          title: 'Registrar',
          introduction: 'Assoc. Prof. Sargunam Thirugnanam has more than three decades of experience with dynamic expertise in Academics, Registry, Governance, Quality, Compliance, Student Life Cycle, and Project Management in Higher Education Ecosystem. He has held various senior academic and management roles responsible for academics, programme development, quality, examination and international collaborations in private higher educational institutions. He played a key role in establishing twinning and franchise programmes with some of the universities in the United Kingdom, Australia, and the United States. He holds Chartered Engineer (CEng) status from the Engineering Council (UK) and is recognized as a Professional Fellow of the British Computer Society, The Chartered Institute for IT (UK).',
          image: require('@/assets/leader04.jpg'),
        },
        {
          index: 5,
          name: 'Prof. Dr. Lee Chew Ging',
          title: 'Assistant Deputy Vice Chancellor <br />Dean of the School of Management & Business',
          introduction: 'Prof. Dr. Lee Chew Ging is a Professor in Economics. He graduated from the University of London with a Bachelor of Science (Economics). He received his Master of Social Sciences (Economics) from the National University of Singapore. He completed his PhD by Publication at the University of Portsmouth. He was promoted to Full Professor in 2011 by the University of Nottingham Malaysia. Prior to joining MILA University, he had held different senior positions, such as Deputy Chief Academic Officer of INTI International University & Colleges, Head of Southampton Malaysia Business School, Dean of Nottingham Business School Malaysia and Associate Vice Chancellor of Wawasan Open University. He also served as an expert to assist the Malaysian Qualifications Agency (MQA) to develop the first edition and second edition of Programme Standards: Business Studies. Currently, he is a member of the Board of Examiners of Asian Institute of Chartered Banker. ',
          image: require('@/assets/leader05.jpg'),
        },
        {
          index: 6,
          name: 'Prof. Dr. Edwin Chung Chin Yau',
          title: 'Dean of the School of Engineering & Computing <br/>Dean of the School of Biotechnology',
          introduction: 'Prof. Dr. Edwin Chung is an Engineering Professor and the Dean for the School of Engineering & Computing as well as the School of Biotechnology at MILA University. Edwin graduated from Monash University (Clayton) with a Bachelor of Science majoring in Computer Science, a Bachelor of Engineering with first class honours majoring in Electrical & Computer Systems Engineering and a PhD in the area of asynchronous circuit design.He was the Director for Knowledge Transfer & Commercialisation at Taylor’s University and the General Manager at Taylor’s Consultancy Sdn Bhd (a subsidiary of Taylor’s University).He also holds 1 Malaysian and 2 international patents.',
          image: require('@/assets/leader06.jpg'),
        },

      ];

    return {
      profile: null,
      profiles: profiles,
      image: null,
      params: "",
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.getNewList();
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    initData() {
      this.getNewList();
    },
    getNewList() {
      getArticleDetail1({ value: 43 }).then((response) => {
        if (response.data && response.data.images) {
          response.data.images = this.imgUrl + response.data.images;
          this.image = response.data.images;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 105px 0px 20px 0px;
  min-height: 75%;
  max-width: 2220px;
}
</style>
