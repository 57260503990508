<template>
  <div id="app">
    <collegeHeader :routerList="routerList" />
    <router-view></router-view>
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import { getPList } from '@/api/home.js'
export default {
  components: {
    collegeHeader
  },

  async created() {
    await this.getRouter()
    console.log(123, this.arr);
  },
  data() {
    return {
      arr: [],
      routerList: [
        {
          name: 'about',
          title: 'About Us',
          list: [{ name: 'Governance and Leadership', module: 'GovernanceAndLeadership',sort : 25, status: '1' }]
        },
        {
          name: 'school',
          title: 'Schools',
          list: []
        },
        {
          name: 'course',
          title: 'Courses',
          list: [
            { name: 'Pre-University', module: 'course' }, 
            { name: 'Diploma', module: 'course' }, 
            { name: 'Undergraduate', module: 'course' }, 
            { name: 'Postgraduate', module: 'course' }]
        },
        {
          name: 'student',
          title: 'Admissions',
          list: []
        },
        {
          name: 'campus',
          title: 'Campus Life',
          list: []
        },
        {
          name: 'study',
          title: 'Research',
          list: []
        },
        {
          name: 'news',
          title: 'News and Events',
          list: []
        }
      ]
    }
  },
  methods: {
    async getRouter() {
      let res = await getPList({ pageSize: 1000 })
      console.log(res);
      res.rows.forEach(item => {
        if (item.parentId != 0) return
        switch (item.module) {
          case 'about':
            this.routerList[0].list.push(item)
            break;
          case 'school':
            this.routerList[1].list.push(item)
            break;
          case 'student':
            this.routerList[3].list.push(item)
            break;
          case 'campus':
            this.routerList[4].list.push(item)
            break;
          case 'news':
            this.routerList[6].list.push(item)
            break;
        }

        //循环所有路由, 按照子路由的sort字段进行排序
        //删除所有 status 为 0 的路由
        this.routerList.forEach(item => {
          item.list.sort((a, b) => a.sort - b.sort)
          item.list = item.list.filter(e => e.status == null || e.status == '1')
        })
        
      })

      //删除 routerList[5]
      this.routerList.splice(5, 1)

      localStorage.setItem('menu', JSON.stringify(this.routerList))
    }
  }
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  overflow-x: haudden;
  overflow-y: auto;
}

.content p {
  max-width: 200vh;
}

.middle-max {
  max-width: 2220px;
}

.campus-universally {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 85px 0 0 0;
  display: block;
  overflow-y: auto;
  background: #F1F8FC;

  .banner-img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      width: 100%;
      height: auto;
      display: block;
      border: none;
      vertical-align: middle;
      max-width: 100%;
      max-height: 100%;
      height: 400px;
    }
  }

  .universally-content {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: block;
    overflow: hidden;
    min-height: 75%;
    max-width: 2220px;



    .content {
      padding-top: 20px !important;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .3);
    }

    .dipoma-main{
      width: 100%;
      padding-left: 150px;
      padding-right: 150px;
    }
  }

  .universally-f {
    padding: 20px 70px;
  }

  .html-con {

    img {
      max-width: 100%!important;
/*      width: 33%;
      height: 180px;*/
      margin: 0 auto;
      display: block;
/*      padding: 5px 10px;
      float: right;
      margin-bottom: 15px;*/
    }
  }
  .html-con p{
    text-indent: 2em;
  }
}

@import "@/assets/style/public.scss"
</style>
