<template>
  <div>
    <collegeHeader />
    <div class="content-body">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item">Contact Us</li>
      </ol>
      <h1>Contact Us</h1>
      <div class="conten">
        <div class="conten-left">
          <p class="header">Send us a message</p>
          <el-form :label-position="'top'"  :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
            <el-form-item label="I am a" prop="type">
              <el-input v-model="ruleForm.type"></el-input>
            </el-form-item>
            <el-form-item label="Name" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="Email Address" prop="address">
              <el-input v-model="ruleForm.address"></el-input>
            </el-form-item>
            <el-form-item label="Question or Enquiry" prop="question">
              <el-input type="textarea" rows="4" v-model="ruleForm.question"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="conten-right">
          <p>Contact Information</p>
          <p>Feel free to drop us your thoughts or suggestions for our improvement. We appreciate your comments.</p>
          <p>For other ways of connecting with us, do follow us on Facebook or Twitter.</p>
          <p>Operating Hours</p>
          <p>The Admission Office opening hours are as follows:</p>
          <p>Mondays to Fridays - 8.30am to 5.30pm</p>
          <p>Saturdays - 8.30am to 1.30pm</p>
          <p>We are closed on Sundays and Public Holidays</p>
          <div class="infor">
            <div>
              (200401015434 (653937-U))(DU025(B))
              <br/>
              No. 5, Jalan Universiti,
              <br/>
              Bandar Sunway,
              <br/>
              47500 Selangor Darul Ehsan
              <br/>
              Malaysia
            </div>
            <div>+6 (03) 7491 8622</div>
            <div>
              Waze
              <br/>
              Google Map
            </div>
            <div>
              info@sunway.edu.my
            </div>
          </div>
        </div>
      </div>
    </div>
    <webBottom />
    <!-- <bottomleft /> -->
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import webBottom from "@/components/webBottom.vue";
import bottomleft from "@/components/bottomLeft.vue";
export default {
  components: {
    collegeHeader,
    webBottom,
    // bottomleft
  },
  data() {
    return {
      ruleForm: {
        type: '',
        name: '',
        address: '',
        question: '',
      },
      rules: {
        type: [{ required: true, message: 'please change', trigger: 'change' }],
        name: [{ required: true, message: 'please input', trigger: 'blur' }],
        address: [{ required: true, message: 'please input', trigger: 'blur' }],
        question: [{ required: true, message: 'please input', trigger: 'blur' }],
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.content-body {
  width: 1400px;
  margin: 0 auto;
  background-color: #f7f7f7;
  padding: 10px 10px;
}
h1 {
  text-align: left;
  font-weight: 600;
}
.conten {
  display: flex;
  align-items: flex-start;
}
.conten-left {
  flex: 1;
  padding: 16px 32px;
  box-sizing: border-box;
  background-color: #fff;
  .header {
    margin: 8px 0;
    font-size: 18px;
  }
}
.conten-right {
  flex: 1;
  padding: 16px 32px;
  box-sizing: border-box;
  p {
    margin-bottom: 16px;
  }
  .infor {
    display: flex;
    flex-wrap: wrap;
    div {
      width: 300px;
      text-align: left;
      margin-bottom: 15px;
    }
    div:before {
      content: "\f5a0";
    }
  }
}
::v-deep {
  .el-form {
    text-align: left;
  }
}
</style>