var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"university-wrap"},[_c('h1',{staticStyle:{"text-align":"left","margin":"20px auto 0","padding-left":"20px","font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.form.title))]),_c('div',{staticClass:"flex-ac",staticStyle:{"width":"90%","margin":"20px auto","align-items":"flex-start","justify-self":"center"}},[_c('div',{staticClass:"list-call",style:({
        display: 'flex',

      })},[_c('div',{style:({
          width: '23%',
          background: '#fff',
          textAlign: 'left',
          height: 'auto',
          alignSelf: 'flex-start',
        })},_vm._l((_vm.data),function(i,k){return _c('div',{key:k,style:({
            padding: '10px 20px',
            borderBottom: '1px solid #e5e5e5',
          })},[_c('span',{style:({
              fontSize: '20px',
              padding: '10px 0',
              cursor: 'pointer',
              color: '#727272',
            }),on:{"click":function($event){return _vm.goTo(i.path)}}},[_vm._v(_vm._s(i.name))]),_vm._l((i.children),function(j,kk){return _c('div',{key:kk,class:{ active: _vm.isCurrentCategory(j.categoryId)},style:({
              margin: '10px 0 5px 20px',
              fontSize: '16px'
            })},[_c('span',{style:({
                cursor: 'pointer',
              }),on:{"click":function($event){return _vm.gopush(j.categoryId)}}},[_vm._v(_vm._s(j.name))])])})],2)}),0),_vm._m(0)])])]),_c('footCom')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"nei"},[_c('hr'),_c('div',[_c('p',[_c('strong',[_vm._v("Programme Educational Objectives")])]),_c('p',[_vm._v("The Programme Educational Objectives stated below are the specific goals of the programme, consistent with the mission of MILA University and are responsive to the expressed interest of the Mechanical Engineering Programme. The statement describes what the mechanical engineering students are expected to know and be able to perform or attain certain key attributes by the time of graduation.")]),_c('p',[_c('img',{attrs:{"src":require("../assets/detail_extra/s1.png")}})])])])])
}]

export { render, staticRenderFns }