<template>
  <div class="current-campus">
    <collegeHeader />
    <img
      src="//31814893.s21i.faiusr.com/2/ABUIABACGAAgvp6yqgYonsPNowIwgA849AM.jpg.webp&quot"
    />
    <div class="universitys-wrap">
      <div class="inner">
        <div class="u-title">Our Campus, Our Story</div>
        <p>
          Asia Pacific University of Technology &Innovation (APU) unveiled the
          new iconic campus in 2017 that is strategically located in Technology
          Park Malaysia (TPM), Bukit Jalil.APU is a top-notch University
          recognised as a Premier Digital University by MDEC and was rated
          5-Stars at the SETARA2017 Ratings by Ministry of Higher Education
        </p>
        <p>
          APU's Campus blends technology, integration, innovation and creativity
          under one roof. Designed by award-winning consultants and architects,
          APU's new iconic campus provides not just a university learning
          environment, but also a lively community spot for our students to
          formulate new ideas, gain intellectual growth and discover new
          adventures.
        </p>
        <img src="../assets/1.jpg" class="bigPic" />
        <div
          class="flex-ac"
          style="justify-content: space-between; margin-top: 20px"
        >
          <img src="../assets/2.jpg" style="width: 48%; height: 300px" />
          <img src="../assets/2.jpg" style="width: 48%; height: 300px" />
        </div>
        <div class="u-title p-t">
          Technology Park Malaysia (TPM),Bukit Jalil-A Location with Ultimate
          Connectivity and Accessibility
        </div>
        <p>
          Strategically located within TPM, Bukit Jalil, the APU's new iconic
          campus provides high accessibility through major highways from
          Puchong,Subang, Petaling Jaya and the heart of Kuala Lumpur. Apart
          from providing ample parking spaces on-campus for students and staff,
          the campus is surrounded by a wide array of public transportation,
          such as the Bukit Jalil LRT station and Terminal Bas Selatan @ Bandar
          Tasik Selatan.TPM, the technology park that also houses Astro, Mimos,
          Jaring and other various technological companies, is the ideal
          location for this contemporary campus, due to its strong positioning
          as Malaysia's primary hub for leading-edge and high-tech developments
          in a wide variety of areas
        </p>
        <div
          class="flex-ac"
          style="justify-content: space-between; margin-top: 20px"
        >
          <img src="../assets/1.jpg" style="width: 48%; height: 300px" />
          <img src="../assets/3.jpg" style="width: 48%; height: 300px" />
        </div>
        <img src="../assets/4.jpg" class="bigPic p-t" />
        
        <p>
          Strategically located within TPM, Bukit Jalil, the APU's new iconic
          campus provides high accessibility through major highways from
          Puchong,Subang, Petaling Jaya and the heart of Kuala Lumpur. Apart
          from providing ample parking spaces on-campus for students and staff,
          the campus is surrounded by a wide array of public transportation,
          such as the Bukit Jalil LRT station and Terminal Bas Selatan @ Bandar
          Tasik Selatan.TPM, the technology park that also houses Astro, Mimos,
          Jaring and other various technological companies, is the ideal
          location for this contemporary campus, due to its strong positioning
          as Malaysia's primary hub for leading-edge and high-tech developments
          in a wide variety of areas
        </p>
        <div class="u-title p-t">
          Technology Park Malaysia (TPM),Bukit Jalil-A Location with Ultimate
          Connectivity and Accessibility
        </div>
        <p>
          APU's Campus blends technology, integration, innovation and creativity
          under one roof. Designed by award-winning consultants and architects,
          APU's new iconic campus provides not just a university learning
          environment, but also a lively community spot for our students to
          formulate new ideas, gain intellectual growth and discover new
          adventures.
        </p>
        <div
          class="flex-ac"
          style="justify-content: space-between; margin-top: 20px"
        >
          <img src="../assets/1.jpg" style="width: 48%; height: 300px" />
          <img src="../assets/3.jpg" style="width: 48%; height: 300px" />
        </div>
        <div class="u-title p-t">
          Technology Park Malaysia (TPM),Bukit Jalil-A Location with Ultimate
          Connectivity and Accessibility
        </div>
        <p>
          APU's Campus blends technology, integration, innovation and creativity
          under one roof. Designed by award-winning consultants and architects,
          APU's new iconic campus provides not just a university learning
          environment, but also a lively community spot for our students to
          formulate new ideas, gain intellectual growth and discover new
          adventures.
        </p>
        <div
          class="flex-ac"
          style="justify-content: space-between; margin-top: 20px"
        >
          <img src="../assets/1.jpg" style="width: 48%; height: 300px" />
          <img src="../assets/3.jpg" style="width: 48%; height: 300px" />
        </div>
        <p>
          APU's Campus blends technology, integration, innovation and creativity
          under one roof. Designed by award-winning consultants and architects,
          APU's new iconic campus provides not just a university learning
          environment, but also a lively community spot for our students to
          formulate new ideas, gain intellectual growth and discover new
          adventures.
        </p>
        <div class="u-title p-t">
          Technology Park Malaysia (TPM),Bukit Jalil-A Location with Ultimate
          Connectivity and Accessibility
        </div>
        <p>
          APU's Campus blends technology, integration, innovation and creativity
          under one roof. Designed by award-winning consultants and architects,
          APU's new iconic campus provides not just a university learning
          environment, but also a lively community spot for our students to
          formulate new ideas, gain intellectual growth and discover new
          adventures.
        </p>
        <div
          class="flex-ac"
          style="justify-content: space-between; margin-top: 20px"
        >
          <img src="../assets/1.jpg" style="width: 48%; height: 300px" />
          <img src="../assets/3.jpg" style="width: 48%; height: 300px" />
        </div>
        <div
          class="flex-ac"
          style="justify-content: space-between; margin-top: 20px"
        >
          <img src="../assets/1.jpg" style="width: 30%; height: 300px" />
          <img src="../assets/3.jpg" style="width: 30%; height: 300px" />
          <img src="../assets/1.jpg" style="width: 30%; height: 300px" />
        </div>
      </div>
    </div>
    <webBottom />
    <!-- <bottomleft /> -->
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import webBottom from "@/components/webBottom.vue";
import bottomleft from "@/components/bottomLeft.vue";
export default {
  components: {
    collegeHeader,
    webBottom,
    // bottomleft
  },
};
</script>
<style lang="scss" type="text/scss" src="../assets/style/CurrentCampus.scss">