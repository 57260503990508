<template>
  <div>
    <div class="header-mian">
      <div class="total-wrap" :class="isshow && isHome ? '' : 'wrap-shadow'">

        <div class="row header-wrap" :class="isshow && isHome ? '' : 'whiteBack'">
          <div class="middle-content middle-max">
            <div class="col-sm-2" style="">
              <!-- width:16.66666667%;width: 100vw -->
              <a href="/" @click="iscontent = -1">
                <img class="bg-left" style="width: 165px; height: 55px;margin-left:20px;margin-top: 0px"
                  src="../assets/log_head.png" alt="" />
              </a>
            </div>
            <div class="col-sm-10" style="padding-left: 20px;box-sizing: border-box;">
              <!-- width:83.33333333% -->
              <div style="max-width: 10000px;height:55px;color:#000;display: flex;align-items: center;">
                <el-dropdown v-for="(item, index) in routerList" :key="index" style="margin-right: 20px;">
                  <span
                    :class="['el-dropdown-link', actName == item.name ? 'hover' : '', iscontent == index ? 'dhs' : '']"
                    @mouseenter="handleMouseEnter(item)" @mouseleave="handleMouseLeave(item)">
                    {{ item.title }}
                  </span>
                  <el-dropdown-menu slot="dropdown" class="custom-dropdown-mian">
                    <div class="custom-dropdown-content" @mouseenter="handleMouseEnter(item)"
                      @mouseleave="handleMouseLeave(item)">
                      <img class="imgs" src="../assets/common/1.jpg" v-if="item.name=='about'"/>
                      <img class="imgs" src="http://47.250.39.153:8600/common/image/2024/01/06/2.jpg" v-if="item.name=='school'"/>
                      <img class="imgs" src="../assets/common/3.jpg" v-if="item.name=='course'"/>
                      <img class="imgs" src="http://47.250.39.153:8600/common/image/2024/01/06/4.jpg" v-if="item.name=='student'"/>
                      <img class="imgs" src="http://47.250.39.153:8600/common/image/2024/01/06/5.jpg" v-if="item.name=='campus'"/>
                      <img class="imgs" src="http://47.250.39.153:8600/common/image/2024/01/06/6.jpg" v-if="item.name=='study'"/>
                      <img class="imgs" src="http://47.250.39.153:8600/common/image/2024/01/06/9.jpg" v-if="item.name=='news'"/>
                      <div class="left-d">
                        <p>THINKING POWER</p>
                        <p>ACTION POWER</p>
                        <p>INNOVATION</p>
                      </div>
                      <div class="right-d">
                        <div class="right-item " v-for="(e, i) in item.list" :key="i"
                          @click="handlerToTarget(`/${e.module}`, e.name, e.categoryId); iscontent = index">
                          <el-dropdown-item>{{ e.name }}</el-dropdown-item>
                        </div>
                      </div>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { getPList, getArticleDetail1 } from '@/api/home.js'
export default {
  name: "header",
  mounted() {
    $(function () {
      $("#header-nav>li").hover(
        function () {
          $(this).children(".line").fadeIn(300);
          $(this).children("ul").fadeIn(300);
        },
        function () {
          // $(this).children("a").css("color", "#333");
          $(this).children("ul").fadeOut(200);
          $(this).children(".line").fadeOut(300);
        }
      );
    });
    window.addEventListener('scroll', this.scrollTop)
  },
  computed: {
    isHome() {
      if (this.$route.path == '/' || this.$route.path == '/home') {
        return true
      } else {
        return false
      }
    }
  },
  props: {
    routerList: Array
  },
  watch: {
    routerList: {
      deep: true,
      handler() {
        this.$forceUpdate()
      }
    },
    $route: {
      handler: function (val, oldVal) {
        this.scrollTop()
      },
      // 深度观察监听
      deep: true
    }
  },
  data() {
    return {
      iscontent: -1,
      isshow: true,
      actName: '',
    }
  },
  methods: {
    handleMouseEnter(item) {
      this.actName = item.name
    },
    handleMouseLeave(item) {
      this.actName = ''
    },
    scrollTop() {
      if (this.$route.name != 'home') {
        this.isshow = false
        return
      }
      if (document.documentElement.scrollTop > 690) {
        this.isshow = false
      } else {
        this.isshow = true
      }
    },
    handlerToTarget(path, name, categoryId) {
      if (path == '/home') {
        this.$router.push({ path: '/', query: { id: 1 } })
        return
      }
      if (path == '/study' || path == '/school' || path == '/student') {
        getArticleDetail1({ value: categoryId }).then((response) => {
          if (response && response.data) {
            this.$router.push({ path: path, query: { name, categoryId } })
          } else {
            this.$message.error('There is currently no introduction to this menu')
          }
        });
        return
      }
      console.log('route', path)
      if (this.$route.name == path) {
        return
      }
      this.$router.push({ path: path, query: { name, categoryId } })
    },
  },
  created() {
  }
};
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" type="text/scss" src="../assets/style/head.scss" scoped></style>
<style scoped lang="scss"> .total-wrap {
   background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
 }

 .header-wrap {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 0 !important;
   margin: 0 !important;
 }

 .middle-content {
   width: 100%;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-wrap: wrap;
   padding: 0;
   margin: 0;
 }

 .wrap-shadow {
   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .2);
 }

 .el-dropdown-link {
   font-size: 16px;
   font-weight: 500;
   padding: 6px 0;
   color: #fff;
   font-family: 微软雅黑, 黑体;
   cursor: pointer;
 }

 .el-dropdown-link:hover {
   //border-bottom: #fff solid 2px;
 }

 //新进来的
 .el-dropdown-link:after {
   content: "";
   border-bottom: 2px solid #003572;
   margin-top: 5px;
   display: block;
   width: 100%;
   transition: all 0.2s ease-out 0s;
   transform: scaleX(0);
 }

 .el-dropdown-link:before {
   content: "";
   border-left: 2px solid #003572;
   height: 20px;
   transition: all 0.2s ease-out 0s;
   transform: scaleY(0);
   position: absolute;
   left: 50%;
   top: 100%;
   transform-origin: center top;
 }

 .el-dropdown-link.hover:before {
   transform: none;
 }

 .el-dropdown-link.now:after {
   transform: none;
 }

 .el-dropdown-link.hover:after {
   transform: none;
 }

 //新进来的

 .wrap-shadow .el-dropdown-link:hover {
   //border-bottom: #003572 solid 2px;
 }

 .wrap-shadow .el-dropdown-link:after {
   content: "";
   border-bottom: 2px solid #1e50ae;
 }

 .wrap-shadow .el-dropdown-link:before {
   content: "";
   border-left: 2px solid #1e50ae;
 }

 .box li {
   cursor: pointer;

 }

 .box li:hover {
   background-color: #fff;
   color: #000;
 }

 a {
   text-decoration: none;
   font-size: 16px;
   color: #fff;
 }

 .whiteBack {
   background-color: #fff !important;

   .el-dropdown-link {
     color: #333 !important;
   }

 }

 .dhs {
   color: #eda712 !important;
 }

 .el-dropdown-menu__item:not(.is-disabled):hover {
   background-color: #66b1ff;
   color: #fff;
 }
</style>


<style lang="scss">
.custom-dropdown-mian {
  top: 66px !important;
  z-index: 99999 !important;
  padding: 0;
  border-radius: 0;
  border-top: #1e50ae solid 2px;

  .custom-dropdown-content {
    position: relative;
    display: block;
    margin: 0 auto;
    max-width: 10000px;
    padding: 40px;
    width: 900px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: #fff url(../assets/png96.png) center bottom no-repeat;
    background-size: contain;

    .imgs {
      width: 140px;
      height: 140px;
      display: block;
      margin-right: 30px;
    }

    .left-d {
      width: 200px;

      p {
        font-size: 16px;
        color: #94070a;
        line-height: 1.2;
        display: block;
      }
    }

    .right-d {
      //width: calc(100% - 360px);
      flex: 1;
      //min-height: 140px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      .right-item {
       // min-width: calc(33.33% - 10px);
        min-width: calc(100% - 10px);
        max-width: calc(100% - 10px);
        margin: 7.5px 0;
        margin-right: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .el-dropdown-menu__item {
          height: auto !important;
          font-size: 18px;
          font-family: 微软雅黑, 黑体;
          color: #333;
          line-height: 18px;
          text-align: left;
          padding-left: 0 !important;
          padding-bottom: 0 !important;
          list-style: none
        }

        .el-dropdown-menu__item:hover {
          background: none;
          color: #1e50ae;
        }
      }
    }
  }

  .popper__arrow {
    display: none !important;
  }
}
</style>
