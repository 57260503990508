<template>
  <div class="campus-universally">

    <bannerImage :image="image" :tip="(this.$route.path !== '/school')"></bannerImage>

    <div class="universally-content universally-f">

      <div class="flex-ac" style="margin: 20px auto; align-items: flex-start; justify-self: center;">

        <div class="list-call">
          <div class="content">
            <div class="nei">
              <h1 style="text-align: left;margin: auto 0; font-size: 28px;margin-bottom: 20px">{{ form.title }}
              </h1>
              <!--
                <span style="font-size: 13px;font-weight: 500;color: #646464;float: left;margin: 0;margin-right: 10px;">{{ form.createTime }}</span>
                <span style="font-size: 13px;font-weight: 500;color: #646464;">{{ form.summary }}</span>
                <span style="font-size: 13px;font-weight: 500;color: #646464;margin-bottom: 20px">{{ form.remark }}</span>
-->


              <div v-if="enableCarouselMap">
                <div data-v-3a73f4cf="" class="html-con">
                  <div style="margin:40px 120px;">
                    <ElCarousel height="500px" trigger="click">
                      <el-carousel-item v-for="item in this.carouselImages" :key="item">
                        <el-image style="width: 100%;height: 100%;" fit="contain" :src="item"></el-image>
                      </el-carousel-item>
                    </ElCarousel>
                  </div>
                  <p data-source="1">In August 2023, MILA University emerged as a beacon of educational excellence
                    following the acquisition by Shenzheng International Trade (Hainan) Co. Ltd. with full approval
                    from the Ministry of Higher Education, Malaysia. This marked a transformative moment for the former
                    Manipal International University, Malaysia (MIU), which underwent a complete rebranding under its
                    new name.</p>
                  <p data-source="3">Originally established by the renowned Manipal Education Group, MILA University
                    stands as a dynamic, international institution in Malaysia, offering a diverse range of
                    undergraduates, postgraduates, and doctoral programmes. With a global perspective, the university
                    seamlessly blends disciplines such as business management, information science, engineering, and
                    biotechnology. Building on a rich legacy of 60 years devoted to advancing higher education in Asia,
                    MILA University achieved a global ranking of 551-600 position in Asia according to the QS Asia
                    University Rankings in 2024.</p>
                  <p data-source="5">The accolades continued as the university earned recognition from the Malaysian
                    Higher Education SETARA, rated as competitive in 2023. Moreover, it earned the 11th spot in the
                    ASEAN application-oriented Private University Rankings in 2022.</p>
                  <p data-source="7"><strong>University Positioning</strong></p>
                  <p data-source="9">We want to revolutionise education and teaching in its entirety, quickly assemble a
                    top-notch faculty, and develop creative and multidisciplinary talent possessing a global
                    perspective, exceptional overall quality, and formidable professional aptitude.</p>
                  <p data-source="11"><strong>5 Years’ Development Goal</strong></p>
                  <p data-source="13">Elevate campus environment and educational facilities to the forefront among
                    Malaysian universities.</p>
                  <p data-source="15">Strive to achieve an in-campus student population of ten thousand (10,000).</p>
                  <p data-source="17">Aim to enter the top 500 in the QS World University Rankings (targeting the top
                    300 within 10 years).</p>
                  <p data-source="11"><strong>University Mission and Vision</strong></p>
                  <p data-source="11"><strong>VISION</strong></p>
                  <p data-source="15">To produce socially conscious, competent, innovative, and confident human capital for the 21st century – in abundance!</p>

                  <p data-source="11"><strong>MISSION</strong></p>
                  <p data-source="15">Ensure holistic development of individuals.</p>
                  <p data-source="15">eXplore opportunities to collaborate and synergize with the best universities across the world to develop a world-class institution.</p>
                  <p data-source="15">Create programs that are aligned with the economic development of Malaysia and the region.</p>
                  <p data-source="15">Establish lasting industry relationships for a win-win solution.</p>
                  <p data-source="15">Learn the best practices of top universities around the World.</p>

                    
                </div>
              </div>
              <div v-else-if="enableCurrentCampus">
                <div data-v-3a73f4cf="" class="html-con">

                  <p><strong>Campus development</strong></p>
                  <p class="ql-align-justify">In the coming five years, MILA University plans to complete 700,000 square
                    meters of buildings, including the East and West gates, a new comprehensive building, three academic
                    buildings, culture and arts centre, the academic centre, international exchange centre, library,
                    sports centre, student activity centre, stadium, filming studio, and parking premise.</p>
                  <p><strong>Lakeside campus</strong></p>

                  <div style="margin:40px 120px;">
                    <ElCarousel height="500px" trigger="click">
                      <el-carousel-item v-for="item in this.currentCampusImages" :key="item">
                        <el-image style="width: 100%;height: 100%;" fit="contain" :src="item.image"></el-image>
                        <div
                          style="position: relative; top:-118px; color: white; font-weight: bold; text-align: center; text-shadow: rgb(0, 0, 0) 2px 2px 4px;">
                          <span style="text-align: center;font-size:30px">{{ item.label }}</span>
                        </div>
                      </el-carousel-item>
                    </ElCarousel>
                  </div>

                  <div
                    style="position: relative; top:-90px; z-index:99999 ; color: white; font-weight: bold; text-align: center; text-shadow: rgb(0, 0, 0) 2px 2px 4px;">
                    <span style="text-align: center;font-size:16px">Conceptual Design for the future</span>
                  </div>

                  <p class="ql-align-justify">The commitment to sustainability extends beyond the campus, aligning with
                    MILA University's broader mission of promoting technology, innovation, and nurturing socially
                    responsible corporate citizens of tomorrow. The green practices implemented underscore the
                    institution's dedication to shaping environmentally conscious leaders.</p>
                  <p class="ql-align-justify">Nestled within a sprawling 142-acre expanse, the campus boasts an idyllic
                    setting surrounded by lush green hills and adorned by a quaint 13-acre lake. Strategically located
                    just 45 km from downtown Kuala Lumpur and 20 km from Kuala Lumpur International Airport, the campus
                    is seamlessly connected by road and rail.</p>
                  <p class="ql-align-justify">The campus is equipped with cutting-edge infrastructure, specifically
                    tailored for engineering and science education. Laboratories and advanced research facilities
                    underscore MILA University's commitment to providing students with the tools they need to thrive in
                    their academic pursuits.</p>
                  <p class="ql-align-justify">The tranquil ambiance of the campus creates an ideal environment for
                    learning and personal growth. This serene setting not only facilitates academic excellence but also
                    encourages students to excel in every aspect of their educational journey.</p>
                  <p class="ql-align-justify">In the next five years, MILA University envisions an impressive expansion
                    with over 700,000 square meters of new buildings. This ambitious development plan aims to set a
                    benchmark for first-class standards in both academic and daily life within Southeast Asia, further
                    solidifying the university's position as a leader in the region. The future of MILA University's
                    Lakeside Campus is not just about growth but a commitment to sustainability, innovation, and
                    excellence.</p>
                  <p><strong>Location Advantage</strong></p>
                  <p class="ql-align-justify">Situated in Nilai (University Town), Negeri Sembilan, Malaysia, MILA
                    University enjoys a strategic location with several notable advantages.</p>
                  <p class="ql-align-justify">1. Convenient location: The campus is ideally positioned, just 45
                    kilometres from the bustling Kuala Lumpur city centre and 15 kilometres from the Kuala Lumpur
                    International Airport. The well-connected road network surrounding the campus ensures ease of travel
                    for students.</p>
                  <p class="ql-align-justify">2. Employment opportunities: The campus is surrounded by high-tech
                    industrial district, including the Multimedia Super Corridor and the Malaysian Hi-Tech Industry
                    Development Zone. This provides students with a myriad of internship and career prospects, fostering
                    real-world experience and professional growth.</p>
                  <p class="ql-align-justify">3. Multicultural life: Malaysia's multicultural environment attracts
                    students globally, enhancing their intercultural communication skills and fostering a global
                    perspective. The diverse student body contributes to a rich cultural tapestry, creating a vibrant
                    and inclusive campus atmosphere.</p>
                  <p class="ql-align-justify">4. Low cost of living: Malaysia boasts a comparatively low cost of living
                    compared to North America and Europe. The campus provides extensive living facilities, including
                    student apartments, canteens, shopping centres, and hospitals, ensuring a convenient and affordable
                    lifestyle for both staff and students.</p>
                  <p><strong>Studying in Malaysia</strong></p>
                  <p class="ql-align-justify">As one of Asia's economic powerhouses, Malaysia experienced substantial
                    growth in the 1990s and has emerged as one of the world's most diversified and industrialised
                    nations. Kuala Lumpur, the capital, is a vibrant city with global influence in culture, education,
                    economy, business, and finance. Renowned landmarks and premier shopping centres make Malaysia a
                    sought-after destination for travel and education, attracting international students from around the
                    world.</p>

                </div>
              </div>
              <div class="html-con" v-else v-html="form.content"></div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!--底部导航栏-->
    <footCom></footCom>
  </div>
</template>
<script>
import carousel from '@/components/carousel.vue'
import collegeHeader from "@/components/head.vue";
import bannerImage from "@/views/component/bannerImage.vue";
import { getArticleDetail1 } from "@/api/home";

export default {
  components: {
    bannerImage
  },
  computed: {
    /**是否轮播图片列表 */
    enableCarouselMap() {
      return (this.$route.path === '/about' && this.$route.query.name === 'Our University');
    },
    enableCurrentCampus() {
      return (this.$route.path === '/about' && this.$route.query.name === 'Current Campus');
    },
    /**是否给tr td 设置边框 */
    enableTableBorder() {
      return (this.$route.path === '/study' && this.$route.query.name === 'Ongoing Research Activities');
    }
  },
  created() {
    this.initData();
    console.log("this.$route.path", this, this.$route.path);
  },
  data() {
    return {
      form: {},
      image: null,

      carouselImages: [
        require('@/assets/carousel/1.jpg'),
        require('@/assets/carousel/2.jpg'),
        require('@/assets/carousel/3.jpg'),
        require('@/assets/carousel/4.jpg'),
        require('@/assets/carousel/5.jpg'),
        require('@/assets/carousel/6.jpg'),
      ],
      
      currentCampusImages: [
        {
          label: "Sunview Aerial View",
          image: require('@/assets/currentCampus/pic (11).jpg')
        },
        {
          label: "Academic center",
          image: require('@/assets/currentCampus/pic (2).jpg')
        },
        {
          label: "Center for the Arts and Culture",
          image: require('@/assets/currentCampus/pic (3).jpg')
        },
        {
          label: "EDUCATION BUILDING NO.1",
          image: require('@/assets/currentCampus/pic (5).jpg')
        },
        {
          label: "Teaching Building No.2",
          image: require('@/assets/currentCampus/pic (12).jpg')
        },
        {
          label: "Teaching Building No.3",
          image: require('@/assets/currentCampus/pic (1).jpg')
        },
        {
          label: "FILM STUDIO",
          image: require('@/assets/currentCampus/pic (6).jpg')
        },
        {
          label: "INTERNATIONAL HOUSE",
          image: require('@/assets/currentCampus/pic (7).jpg')
        },
        {
          label: "LIBRARY",
          image: require('@/assets/currentCampus/pic (8).jpg')
        },
        {
          label: "MAIN BUILDING",
          image: require('@/assets/currentCampus/pic (9).jpg')
        },
        {
          label: "EAST GATE",
          image: require('@/assets/currentCampus/pic (4).jpg')
        },
        {
          label: "Night view Aerial View",
          image: require('@/assets/currentCampus/pic (10).jpg')
        },
      ]

    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.getNewList();
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    initData() {
      this.getNewList();
    },
    getNewList() {
      getArticleDetail1({ value: this.$route.query.categoryId }).then((response) => {
        if (response.data && response.data.images) {
          response.data.images = this.imgUrl + response.data.images
          console.log("response.data.images", response.data.images)
          this.image = response.data.images;
        }
        this.form = response.data
        if (this.enableTableBorder) {
          this.setTrTdBorder()
        }
      });
    },
    // 设置tr td th 边框 
    setTrTdBorder() {
      this.$nextTick(() => {
        const richTr = document.querySelector('.html-con').querySelectorAll('tr');
        for (let r = 0; r < richTr.length; r++) {
          const tr = richTr[r];
          /**设置tr下边框 */
          tr.style.borderBottom = '1px solid #808080';
          /**设置td右边框 */
          const richTd = tr.querySelectorAll('td');
          for (let t = 0; t < richTd.length; t++) {
            richTd[t].style.borderRight = '1px solid #808080';
          }
          /**设置td右边框 */
          const richTH = tr.querySelectorAll('th');
          for (let t = 0; t < richTH.length; t++) {
            richTH[t].style.borderRight = '1px solid #808080';
          }

        }

        const table = document.querySelector('.html-con').querySelector('table');
        table.style.borderLeft = '1px solid #808080';
        table.style.borderTop = '1px solid #808080';

      })
    },
  },
};
</script>
<style lang="scss" scoped>
.current-campus {
  margin-top: 100px;
  // height: 100vh;
}

.university-wrap {
  min-height: calc(100vh - 100px - 72px);
}

.list-call {
  width: 100%;
  height: auto;
  margin-top: 20px;

  .content {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    width: 100%;

    img:after,
    img:before {
      max-width: 100%;
    }
  }

  .nei {
    width: 100%;
    margin: 0 24px;
    margin-bottom: 15px;
    background-color: #fff;

    .p-imgs {
      width: 100%;
      height: auto;
    }

    p {
      margin: 8px 0;
      font-size: 18px;
      font-family: 'SourceSansPro', Helvetica, Arial, sans-serif;
      color: #003572;
    }

    div {
      margin-bottom: 16px;
      text-align: left;
    }

    span {
      display: block;
      text-align: left;
      margin: 10px 0;
      font-size: 15px;
    }
  }
}
</style>
<style lang="scss" type="text/scss" src="../../assets/style/OurUniversity.scss" />
