var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"campus-universally",staticStyle:{"background-color":"white"}},[_c('bannerImage',{attrs:{"image":this.image,"tip":true}}),_c('div',{staticClass:"container"},[_c('div',{style:({
          textAlign: 'left',
          width: '900px',
          margin: '0 auto 50px auto',
        })},[_c('div',{style:({
          margin: '20px 0 20px 0',
        })},[_c('span',{style:({
          fontSize: '20px',
          marginRight: '10px',
        })},[_vm._v("Courses")]),_c('span',{style:({
          marginRight: '10px'
        })},[_vm._v(">")]),_c('span',{style:({
          color: '#465584'
        })},[_vm._v(_vm._s(_vm.$route.query.name))])]),_c('div',[_c('h1',{style:({
          fontSize: '40px',
          color: '#000',
          margin: '0 0 40px 0',
          padding: '30px 0 30px 0',
          borderBottom: '4px solid #465584',
        })},[_vm._v(_vm._s(_vm.$route.query.name))])]),_vm._l((_vm.newList),function(item){return _c('div',{key:item},[_c('div',{style:({
          borderRadius: '20px',
          overflow: 'hidden',
          border: '1px solid #EBF0FF',
          cursor: 'pointer',
        })},_vm._l((item.children),function(e){return _c('div',{key:e,style:({
          display: 'flex',
          justifyContent: 'flex-start',
          borderBottom: '1px solid #EBF0FF',
          padding: '20px 0 20px 0',
          margin: '0',
        }),on:{"click":function($event){return _vm.gopush(e.categoryId)}}},[_vm._m(0,true),_c('div',[_c('p',{staticStyle:{"margin-bottom":"0px !important"}},[_vm._v(_vm._s(e.name))])])])}),0)])})],2)]),_c('footCom')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticStyle:{"width":"1.2em","margin":"0em 1.2em"},attrs:{"src":require("@/assets/icons/_book.png")}})])
}]

export { render, staticRenderFns }