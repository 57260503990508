<template>
  <div>
    <collegeHeader />
    <div class="header-part flex-ac">
      <div style="width: 800px; margin: 0 auto">
        <div class="title" style="text-align: center">
          Undergraduate
        </div>
        <div class="second-title">HOME LIMU CENTRE FORBGTANYERSIT</div>
      </div>
    </div>
    <div class="dipoma-main" style="padding-top: 20px">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="">Home</a></li>
        <li class="breadcrumb-item">Courses</li>
        <li class="breadcrumb-item active">Undergraduate</li>
      </ol>
      <div class="second-titles">Undergraduate</div>
      <div v-for="item in newList" :key="item">
        <div class="pay-box-title flex-ac-js">
          <div>{{item.name}}</div>
          <div></div>
        </div>
        <div class="payment-infos">
          <span>{{item.content}}</span>
        </div>
        <div class="flex-ac p-b-3" v-if="item.children.length > 0">
          <div class="dipoma-item flex-cc p-r-20" v-for="e in item.children" :key="e">
            <div class="text-r"></div>
            {{ e.name }}
          </div>
        </div>
      </div>

    </div>

    <webBottom />
    <!-- <bottomleft /> -->
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import webBottom from "@/components/webBottom.vue";
import bottomleft from "@/components/bottomLeft.vue";
import { categoryList } from "@/api/home";
export default {
  components: {
    collegeHeader,
    webBottom,
    // bottomleft
  },
  created() {
    this.initData();
  },
  data() {
    return {
      newList: [],
    };
  },
  methods: {
    initData() {
      this.getNewList();
    },
    getNewList() {
      categoryList({pageSize:1000,module:'course',parentName:'Undergraduate',orderByColumn:'updateTime',isAsc:'desc'}).then((response) => {
        this.newList = response.rows;
        console.log("学院详情", response.rows, this.newList);
        this.getEdit()
      });
    },
    getEdit() {
      this.newList.forEach(async item => {
        let res = await categoryList({pageSize:1000,module:'course',parentId: item.categoryId,orderByColumn:'updateTime',isAsc:'desc'})
        item.children = res.rows
      })
      console.log(this.newList);
    }
  },
};
</script>
<style lang="scss" scoped>
.header-part {
  width: 100%;
  height: 400px;
  background-image: url("../assets/2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 0;
}
</style>
<style lang="scss" type="text/scss" src="../assets/style/Dipoma.scss">
