import request from '@/utils/request'
// 查询首页轮播图
export function getAllBannerList() {
  return request({
    url: '/index/getAllBannerList',
    method: 'get'
  })
}
// 查询友情链接
export function getLinkList() {
  return request({
    url: '/index/getLinkList',
    method: 'get'
  })
}
// 查询学校里面的所有学院 
export function getPList(data) {
  return request({
    url: '/blog/category/plist',
    method: 'get',
    params: data
  })
}
export function getPList2(data) {
  return request({
    url: '/blog/category/plist?module=school',
    method: 'get',
    params: data
  })
}
// 获取文章列表
export function getArticleList(data) {
  return request({
    url: '/blog/article/list',
    method: 'get',
    params: data
  })
}
// 获取文章详情
export function getArticleDetail(query) {
  return request({
    url: '/blog/article/getInfo?articleId='+query.value,
    method: 'get'
  })
}
export function getArticleDetail1(query) {
  return request({
    url: '/blog/article/getInfo?categoryId='+query.value,
    method: 'get'
  })
}
// 获取新闻列表
export function getNewList(data) {
  return request({
    url: '/blog/article/list',
    method: 'get',
    params: data
  })
}
// 获取课程
export function getCourseList(id) {
  return request({
    url: '/blog/category/list?parentId='+id,
    method: 'get'
  })
}
// 获取验证码
export function getcaptchaImage(id) {
  return request({
    url: '/captchaImage',
    method: 'get'
  })
}
// 获取文章详情
export function addApply(data) {
  return request({
    url: '/index/apply',
    method: 'post',
    data: data
  })
}

// 获取课程
export function categoryList(data) {
  return request({
    url: 'blog/category/list',
    method: 'get',
    params: data
  })
}

