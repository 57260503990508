<template>
  <div>
    <collegeHeader />
    <div class="header-part flex-ac">
      <div style="width: 800px; margin: 0 auto">
        <div class="title" style="text-align: center">
          SCHOLARSHIP & BURSARY
        </div>
        <div class="second-title">HOME LIMU CENTRE FORBGTANYERSIT</div>
      </div>
    </div>
    <div class="school-card">
      <div class="school-title flex-cc">
        <div class="school-titles">ABOUt</div>
        <div class="school-line"></div>
      </div>
      <p class="school-text">
        Studying at university is a life-changing <br />experience that often
        requires some support, <br />which could help you achieve your dreams.
        At <br />IMU, we offer a number of scholarships and<br />
        bursaries to help you fully achieve your potential.
      </p>
    </div>
    <img class="school-mid" src="../assets/1.jpg" />
    <div class="school-data">
      <div class="school-left">
        <div
          class="p-div"
          :class="current == index ? 'p-div-active' : ''"
          v-for="(item, index) in list"
          :key="index"
          @click="handlerSelected(index)"
        >
          {{ item }}
        </div>
        <!-- <div class="p-div">UNDERGRADUATE</div>
        <div class="p-div">POSTGRADUATE</div>
        <div class=""></div> -->
      </div>
      <div class="school-right">
        <div style="margin-top: 10px">
          <el-select v-model="value" placeholder="请选择" style="width: 80%">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="padding-left: 93px">
          <div class="second-titles p-t-20 p-b-20">
            Foundation in Science Scholarship
          </div>
          <div class="p-div p-b-20">
            Available for students with 2022 SPM or 2022/2023 IGCSE/O-Level
            qualification
          </div>
          <div class="flex-cc">
            <div class="table-head flex-cc" style="width: 40%">
              Scholarship Amount
            </div>
            <div class="table-head flex-cc" style="width: 60%">
              SPM / O-Levels Required
            </div>
          </div>
          <div class="flex-cc">
            <div class="table-tb flex-cc" style="width: 40%">RM13,500</div>
            <div class="table-tb flex-cc" style="width: 60%">8As</div>
          </div>
          <div class="flex-cc">
            <div class="table-tb flex-cc" style="width: 40%">RM8,000*</div>
            <div class="table-tb flex-cc" style="width: 60%">7As</div>
          </div>
          <div class="flex-cc">
            <div class="table-tb flex-cc" style="width: 40%">RM6,500</div>
            <div class="table-tb flex-cc" style="width: 60%">8As</div>
          </div>
          <div class="flex-cc">
            <div class="table-tb flex-cc" style="width: 40%">RM4,500</div>
            <div class="table-tb flex-cc" style="width: 60%">8As</div>
          </div>
          <div class="p-div p-t-20" style="color: red; font-size: 15px">
            Candidates are required to achieve As in 2 Science subjects
            (Biology,Chemistry,Physics)
          </div>
          <div class="p-div p-t-10">
            *The scholarship value will be deducted across 3 semesters
          </div>
          <div class="p-div p-t-10">
            **The scholarship value will be deducted from Semester 2 only
          </div>
          <div class="p-div p-t-20">
            <b style="color: black">NOTE:</b>Only applicable for2023 intakes.The
            scholarship will be awarded based on actual SPM or IGCSE/O-Level
            result and not on forecast result.
          </div>
          <div class="school-bottom-box">
            <div class="school-bottom-title">IMPORTANT</div>
            <div class="school-bottom-line"></div>
            <p style="text-align:center">
              IMU Scholarship Committee may vary the selection requirements as
              and when it deems<br /> necessary.Bursaries granted at the University's
              sole discretion and decisions are final.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <bottomleft /> -->
    <webBottom />
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import webBottom from "@/components/webBottom.vue";
import bottomleft from "@/components/bottomLeft.vue";
export default {
  components: {
    collegeHeader,
    webBottom,
    // bottomleft
  },
  data() {
    return {
      list: ["FOUNDATION IN SCIENCE", "UNDERGRADUATE", "POSTGRADUATE"],
      current: "0",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
  methods: {
    handlerSelected(index) {
      this.current = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.header-part {
  width: 100%;
  height: 400px;
  background-image: url("../assets/2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 0;
}
</style>
<style lang="scss" type="text/scss" src="../assets/style/ScholarShip.scss">
