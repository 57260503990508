<template>
  <div class="campus-universally" style="background-color: white;">

    <bannerImage :image="this.image" :tip="true"></bannerImage>

    <div class="container">

      <div :style="{
            textAlign: 'left',
            width: '900px',
            margin: '0 auto 50px auto',
          }">
        <div :style="{
            margin: '20px 0 20px 0',
          }">
          <span :style="{
            fontSize: '20px',
            marginRight: '10px',
          }">Courses</span><span :style="{
            marginRight: '10px'
          }">></span>
          <span :style="{
            color: '#465584'
          }">{{ $route.query.name }}</span>
        </div>
        <div>
          <h1 :style="{
            fontSize: '40px',
            color: '#000',
            margin: '0 0 40px 0',
            padding: '30px 0 30px 0',
            borderBottom: '4px solid #465584',
          }">{{ $route.query.name }}</h1>
        </div>

        <div v-for="item in newList" :key="item">

          <!-- 列表div -->
          <div :style="{
            borderRadius: '20px',
            overflow: 'hidden',
            border: '1px solid #EBF0FF',
            cursor: 'pointer',
          }">
            <div :style="{
            display: 'flex',
            justifyContent: 'flex-start',
            borderBottom: '1px solid #EBF0FF',
            padding: '20px 0 20px 0',
            margin: '0',
          }" v-for="e in item.children" :key="e" @click="gopush(e.categoryId)">
              <div>
                <img style="width: 1.2em;margin: 0em 1.2em;" src="@/assets/icons/_book.png" />

            </div>
            <div>
                <p style="margin-bottom: 0px !important;">{{ e.name }}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!--底部导航栏-->
    <footCom></footCom>
  </div>
</template>
<script>
import { categoryList, getArticleDetail1 } from "@/api/home";
import bannerImage from "@/views/component/bannerImage.vue";

export default {
  components: {
    bannerImage
  },
  created() {
    this.initData();
    console.log("course",this);
  },
  data() {
    return {
      image: require('@/assets/2.jpg'),
      newList: [],
      title: '',
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.getNewList();
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    initData() {
      this.getNewList();
    },
    getNewList() {
      this.newList = []
      var switchName = this.$route.query.name == 'Pre-University' ? 'Foundation' : this.$route.query.name
      categoryList({ pageSize: 1000, module: 'course', parentName: switchName, orderByColumn: 'updateTime', isAsc: 'desc' }).then((response) => {
        this.newList = response.rows;
        console.log("学院详情", response.rows, this.newList);
        this.getEdit()
      });
    },
    getEdit() {
      this.newList.forEach(async item => {
        let res = await categoryList({ pageSize: 1000, module: 'course', parentId: item.categoryId, orderByColumn: 'updateTime', isAsc: 'desc' })
        item.children = res.rows
      })
      console.log(this.newList);
    },
    gopush(id) {
      getArticleDetail1({ value: id }).then((response) => {
        if (response && response.data) {
          this.$router.push({ path: 'Detaile', query: { id } })
        } else {
          this.$message.error('There is currently no introduction to this Detail')
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 10px 0px 10px 0px;
  min-height: 75%;
  max-width: 2220px;



  .list {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    padding: 20px;

    .item {
      cursor: pointer;
      height: 400px;

      img {
        height: 300px;
        width: 50%;
      }
    }
  }
}
</style>