<template>
  <div>
    <div class="home home-custom" @scroll="handleScroll">
      <!-- <collegeHeader /> -->
      <carousel :imgList="imgList" />
      <!--publicationsList-->
      <div class="stagesRef publications-mian piece-shadow" ref="publicatRef" id="publicatRef"
        v-if="publicationsShow && publicationsShow.length > 0">
        <div class="middle-content middle-max">
          <div class="publications-carousel">
            <div class="swiper-container swiper-container-horizontal swiper-container3">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="cooperation-item">
                    <img class="imgs" src="@/assets/20240107132601.jpg" />
                  </div>
                </div>
                <!--<div class="swiper-slide">
                  <div class="cooperation-item">
                    <img class="imgs" src="https://www.pku.edu.cn/Uploads/Picture/2020/06/29/s5ef96183443a6.gif" />
                  </div>
                </div>-->
              </div>
            </div>
          </div>
          <div class="publications-more">
            <div class="current-title current-title2">ALWAYS PURSUE EXCELLENCE</div>
            <div class="be-current-more">

            </div>
          </div>

          <div
            :class="['publications-content animate__animated ', animFun('publicatRef_t', 1, -200) ? 'animate__rotateInDownLeft' : 'animate__rotateOutDownLeft']">
            <div class="article-content">
              <img class="imgs"  src="../assets/2218902fd8caebe50706cf46d0eb3e9.jpg"  />
              <div class="r-content">
                <div class="name">Cao Chengjie, Chairman of Mila University</div>
                <div class="value">
                  Thinking power is the ability to transform sparks into wisdom, viewpoints into ideologies, and ideas into goals.
                  A compelling vision and a top-notch execution team are the two key elements of action power.
                  Innovation is an internal driving force that propels individuals and organizations to continuously break through barriers, create and achieve sustained and leapfrog development.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- MILA University -->
      <div style="box-shadow: 0 0 0 0 rgb(0 0 0 / 20%);" class="stagesRef  part-four piece-shadow" ref="partFoutRef" id="partFoutRef">
        <div class="middle-content middle-max">

          <!--<div
            :class="['second-title animate__animated ', animFun('partFoutRef_t', 1, -300) ? 'animate__fadeInRight' : 'animate__fadeOutRight']">
            Five Star University</div>-->
          <div class="second-middle div-p  div-p-tou">
            <div
              :class="['animate__animated second-left', animFun('partFoutRef_t', 1, -200) ? 'animate__bounceIn' : 'animate__bounceOut']">
              <div
                :class="['title animate__animated ', animFun('partFoutRef_t', 1, -300) ? 'animate__fadeInLeft' : 'animate__fadeOutLeft']">
                MILA University</div>

              <div class="div-p div-p2">
                <!-- <div style="float: right; clear: both;" align="center">

                </div>-->
                <p>
                  MILA University, formerly known as Manipal International University (MIU) is a multi-disciplinary
                  international
                  university founded by Manipal Education Group, which has a rich experience of more than 60
                  years
                  in
                  the higher education industry in Asia. The University currently offers a variety of programmes in
                  business
                  management, computer science, engineering and biotechnology.
                  <br>
                  In August 2023, with the approval of the Ministry of Higher Education of Malaysia, Shen Zheng
                  International
                  Trade
                  (Hainan) Co. Ltd. wholly acquired Manipal International University (MIU) and rebranded its name to MILA
                  University. Mr. Cao Chengjie is the current Chairman of MILA Education Malaysia and is also the Founder and
                  Chairman
                  of
                  Haikou Institute of Economics, a renowned private university in China. He also serves as the Vice
                  President
                  of
                  the
                  China Private Education Association. He has nearly 30 years of experience in education management and is
                  an
                  influential educator and investor in the private higher education industry in China. Haikou University
                  of
                  Economics has grown from a junior vocational college to more than 2,000 faculty members and staff, more
                  than
                  30,000 students, 20 secondary colleges and 2 public teaching departments, and 53 undergraduate specialty
                  majors,
                  in the past 20 years. Haikou University of Economics is the only application-oriented undergraduate
                  university
                  and
                  is used as the benchmark for other private universities in China to model.
                </p>

                <div class="second-right">
                  <!--autoplay-->
                  <div class="vioew-mian">
                    <video v-if="videoUrl && videoUrl != ''" :src="videoUrl" controls="controls"></video>
                    <div class="error-mian" v-else>视频加载出错...</div>
                  </div>
                  <div class="vioew-btn">
                    <div class="btn">New Campus</div>
                    <div class="btn">New Future</div>
                  </div>
                </div>
              </div>


            </div>

            <div class="second-right2"></div>

          </div>

          <div class="flex-ac-js" style="margin-bottom: 50px">
            <!-- <div class="div-tag wow fadeInDown" data-wow-duration="2s"> -->
            <div
              :class="['div-tag reveal-top animate__animated  ', animFun('partFoutRef_t', 1, -200) ? 'animate__fadeInLeft' : 'animate__fadeOutLeft']">
              <img src="../assets/trophy-icon.svg" />
              <div class="div-text">
                <div class="title">Top 88 in South-eastern Asia</div>
                <div class="content">In QS Asia University Rankings 2024</div>
              </div>
            </div>
            <div class="div-line"></div>
            <div
              :class="['div-tag reveal-top animate__animated  ', animFun('partFoutRef_t', 1, -200) ? 'animate__fadeInLeft' : 'animate__fadeOutLeft']">
              <img src="../assets/handshake-icon.svg" />
              <div class="div-text">
                <div class="title">Strong Partnerships</div>
                <div class="content">& Affiliations with leading institutions</div>
              </div>
            </div>
            <div class="div-line"></div>
            <div
              :class="['div-tag reveal-top animate__animated ', animFun('partFoutRef_t', 1, -200) ? 'animate__fadeInRight' : 'animate__fadeOutRight']">
              <img src="../assets/medal-icon.svg" />
              <div class="div-text">
                <div class="title">Setara 2022: Berdaya Saing (Competitive)</div>
                <div class="content">&nbsp;</div>
              </div>
            </div>
            <div class="div-line"></div>
            <div
              :class="['div-tag reveal-top animate__animated ', animFun('partFoutRef_t', 1, -200) ? 'animate__fadeInRight' : 'animate__fadeOutRight']">
              <img src="../assets/book-icon.svg" />
              <div class="div-text">
                <div class="title">60 Years</div>
                <div class="content">Foundation of Asian Higher Education</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--student-activities-mian -->
      <div ref="stuActRef" id="stuActRef" class="stagesRef student-activities-mian piece-shadow"
        v-if="studentArtList && studentArtList.length > 0">
        <div class="middle-content middle-max">
          <div
            :class="['student-activities-left animate__animated ', animFun('stuActRef_t', 1, -300) ? 'animate__fadeInDown' : 'animate__fadeOutUp']">
            <div
              :class="['activities-item', index == 1 || index == 3 ? 'item-l' : '', index == 2 || index == 3 ? 'item-t' : '']"
              v-for="(item, index) in studentArtList" :key="index" v-show="index >= 0 && index < 4"
              @click="gopush(item.articleId)">
              <img class="imgs" :src="item.images" />
              <!--<div class="name"><span>{{ item.title }}</span> </div>-->
            </div>
          </div>

          <div
            :class="['student-activities-right animate__animated ', animFun('stuActRef_t', 1, -300) ? 'animate__fadeInUp' : 'animate__fadeOutDown']">
            <div class="title">
              ALUMNI · MESSAGES
            </div>
            <div class="more-mian">
              <div class="be-current-more ">
                <div class="more-item"
                  @click="$router.push({ path: '/campus', query: { name: 'Student Activities', categoryId: 9 } })">
                  <div class="item-imgs">
                    <div class="ico"><i class="el-icon-s-promotion"></i></div>
                  </div>
                  <div class="item-name">View All</div>
                </div>
              </div>
            </div>

            <div class="student-activities-left left-title" v-if="studentArtList.length > 4">
              <div
                :class="['activities-item', index == 5 || index == 7 ? 'item-l' : '', index == 6 || index == 7 ? 'item-t' : '']"
                v-for="(item, index) in studentArtList" :key="index" v-show="index >= 4 && index < 8"
                @click="gopush(item.articleId)">
                <img class="imgs" :src="item.images" />
                <!--<div class="name"><span>{{ item.title }}</span> </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Schools-->
      <div ref="cooperRef" id="cooperRef" class="stagesRef cooperation-mian piece-shadow"
        v-if="cooperationList && cooperationList.length > 0">
        <div class="middle-content middle-max">
          <div class="current-title">COOPERATION · COMMUNICATION</div>
          <div class="cooperation-ul">
            <div class="cooperation-li">

              <div class="swiper-container swiper-container-horizontal swiper-container2">
                <div :class="['swiper-wrapper']">
                  <div class="swiper-slide" v-for="(item, index) in showCooperation" :key="index">
                    <div class="cooperation-item" @click="handlerToTarget(`/${item.module}`, item.name, item.categoryId)">
                      <img class="imgs" :src="item.images" />
                      <div class="name">
                        <span v-if="item.images.includes('gw5')">Key Projects Signing Ceremony OfRCEP</span>
                        <span v-if="item.images.includes('gw4')">Dean Chi Fulin of ClRD came to MILA for communication.</span>
                        <span v-if="item.images.includes('gw3')">Dr JASON was interviewed by CCTV</span>
                        <span v-if="item.images.includes('gw2')">CEO Cao Fei had a cordial conversation with the leaders of Zambia</span>
                        <span v-if="item.images.includes('gw1')">Communicate with brother colleges</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="cooperation-right" v-if="cooperationResidue.length > 1">
              <div
                :class="['right-item animate__animated ', index == 0 ? animFun('cooperRef_t', 1, -200) ? 'animate__rotateInDownLeft' : 'animate__rotateOutDownLeft' : '', index == 1 ? animFun('cooperRef_t', 1, -200) ? 'animate__rotateInDownRight' : 'animate__rotateOutDownRight' : '', index == 2 ? animFun('cooperRef_t', 1, -200) ? 'animate__rotateInUpLeft' : 'animate__rotateOutUpLeft' : '', index == 3 ? animFun('cooperRef_t', 1, -200) ? 'animate__rotateInUpRight' : 'animate__rotateOutUpRight' : '']"
                v-for="(item, index) in cooperationResidue" :key="index" v-show="index >= 0 && index < 4"
                @click="handlerToTarget(`/${item.module}`, item.name, item.categoryId)">
                <img class="imgs" :src="item.images" />
                <div class="name"><span>{{ item.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="be-current-more current-more-15">
        <div class="more-item">
          <div class="item-imgs">
            <div class="ico"><i class="el-icon-s-promotion"></i></div>
          </div>
          <div class="item-name">View All</div>
        </div>
      </div>-->

      </div>


      <!--News-->
      <div ref="newFDERef" id="newFDERef" class="stagesRef new-custom-mian piece-shadow2">
        <div class="middle-content middle-max">
          <div class="title-nav wow fadeInLeft">News</div>
          <div class="be-current-more current-more-15  current-more-p be-curre-whot">
            <div
              :class="['more-item animate__animated ', animFun('newFDERef_t', 1, -300) ? 'animate__bounceInLeft' : 'animate__bounceOutLeft']"
              @click="$router.push({ path: '/news', query: { name: 'News' } })">
              <div class="item-imgs">
                <div class="ico"><i class="el-icon-s-promotion"></i></div>
              </div>
              <div class="item-name">News</div>
            </div>

            <div
              :class="['more-item animate__animated ', animFun('newFDERef_t', 1, -300) ? 'animate__bounceInLeft' : 'animate__bounceOutLeft']"
              @click="gopush1('Diploma')">
              <div class="item-imgs">
                <div class="ico"><i class="el-icon-s-promotion"></i></div>
              </div>
              <div class="item-name">Diploma</div>
            </div>

            <div
              :class="['more-item animate__animated ', animFun('newFDERef_t', 1, -300) ? 'animate__bounceInDown' : 'animate__bounceOutUp']"
              @click="gopush1('Undergraduate')">
              <div class="item-imgs">
                <div class="ico"><i class="el-icon-s-promotion"></i></div>
              </div>
              <div class="item-name">Undergraduate</div>
            </div>

            <div
              :class="['more-item animate__animated ', animFun('newFDERef_t', 1, -300) ? 'animate__bounceInRight' : 'animate__bounceOutRight']"
              @click="gopush1('Postgraduate')">
              <div class="item-imgs">
                <div class="ico"><i class="el-icon-s-promotion"></i></div>
              </div>
              <div class="item-name">Postgraduate</div>
            </div>

            <div
              :class="['more-item animate__animated ', animFun('newFDERef_t', 1, -300) ? 'animate__bounceInRight' : 'animate__bounceOutRight']"
              @click="gopush1('Pre-University')">
              <div class="item-imgs">
                <div class="ico"><i class="el-icon-s-promotion"></i></div>
              </div>
              <div class="item-name">Pre-University</div>
            </div>
          </div>
          <div
            :class="['new-li-first animate__animated ', animFun('newFDERef_t', 1, -100) ? 'animate__bounceInLeft' : 'animate__bounceOutLeft']"
            @click="gopush(newList[0].articleId)">
            <img class="first-ims" :src="newList[0].images"  style="object-fit: cover;"/>
            <div class="first-name"><span v-if="newList[0].title && newList[0].title != ''">{{ newList[0].title }}</span>
            </div>
          </div>

          <div v-if="newList.length > 1"
            :class="['new-li-second animate__animated ', animFun('newFDERef_t', 1, -100) ? 'animate__bounceInRight' : 'animate__bounceOutRight']">
            <div v-for="(item, index) in newList" :key="index" v-show="index > 0 && index <= 4" class="second-item"
              @click="gopush(item.articleId)">
              <span v-if="item.title && item.title != ''">{{ item.title }}</span>
            </div>
          </div>

          <div v-if="newList.length > 5"
            :class="['new-li-second li-second-45 animate__animated ', animFun('newFDERef_t', 1, -100) ? 'animate__bounceInLeft' : 'animate__bounceOutLeft']"
            @click="gopush(newList[0].articleId)">
            <div v-for="(item, index) in newList" :key="index" v-show="index > 4 && index <= 8" class="second-item"
              @click="gopush(item.articleId)">
              <span v-if="item.title && item.title != ''">{{ item.title }}</span>
            </div>
          </div>

          <div
            :class="['new-li-first li-first-55 animate__animated ', animFun('newFDERef_t', 1, -100) ? 'animate__bounceInRight' : 'animate__bounceOutRight']"
            v-if="newList.length >= 10" @click="gopush(newList[9].articleId)">
            <img class="first-ims" :src="newList[9].images" style="object-fit: cover;" />
            <div class="first-name"><span v-if="newList[9].title && newList[9].title != ''">{{ newList[0].title }}</span>
            </div>
          </div>
        </div>
      </div>



      <!-- Events
    <div class="events-custom-mian" v-if="eventList && eventList.length > 0">
      <div class="events-left">
        <span class="title">Events</span>
        <div class="time" @click="$router.push({ path: '/news', query: { name: 'Events' } })">
          <div class="be-current-more current-more-15">
            <div class="more-item" @click="$router.push({ path: '/news', query: { name: 'News' } })">
              <div class="item-imgs">
                <div class="ico"><i class="el-icon-s-promotion"></i></div>
              </div>
            </div>
          </div>
        </div>

        <div class="more" @click="$router.push({ path: '/news', query: { name: 'Events' } })">View All</div>
      </div>

      <div class="events-right">
        <div class="events-item" v-for="(item, index) in eventList" :key="index" v-show="index >= 0 && index < 4"
          @click="gopush(item.articleId)">
          <img class="imgs" :src="item.images" />
          <div class="name">{{ item.title }}</div>
          <div class="more" @click="$router.push({ path: '/news', query: { name: 'Events' } })">View All</div>
        </div>
      </div>
    </div>-->

      <!---->
      <div ref="campusLifeRef" id="campusLifeRef" class="stagesRef campus-life-mian piece-shadow2">
        <div class="middle-content middle-max">

          <div class="campus-life-con">
            <div class="life-con-menu">
              <div
                :class="['con-menu-item animate__animated  ', animFun('campusLifeRef_t', 1, -300) ? 'animate__rotateInDownLeft' : 'animate__rotateOutUpLeft']">
                <div style="background:#002f66;"><img style="opacity: 0.1;"  class="imgs" src="http://47.250.39.153:8600/common/image/2024/01/06/1.jpg" /></div>
                <div class="item-piece">
                  <div class="ico"><i class="el-icon-printer"></i></div>
                  <div class="name">Academic Research</div>
                </div>
              </div>

              <div
                :class="['con-menu-item animate__animated  ', animFun('campusLifeRef_t', 1, -300) ? 'animate__rotateInDownLeft' : 'animate__rotateOutUpLeft']">
                <div style="background:#004699;"><img style="opacity: 0.1;" class="imgs" src="http://47.250.39.153:8600/common/image/2024/01/06/2.jpg" /></div>
                <div class="item-piece">
                  <div class="ico"><i class="el-icon-present"></i></div>
                  <div class="name">Conference Lecture</div>
                </div>
              </div>

              <div
                :class="['con-menu-item animate__animated  ', animFun('campusLifeRef_t', 1, -300) ? 'animate__rotateInDownLeft' : 'animate__rotateOutUpLeft']">
                <div style="background:#005dcc;"><img style="opacity: 0.1;"  class="imgs" src="http://47.250.39.153:8600/common/image/2024/01/06/3.jpg" /></div>
                <div class="item-piece">
                  <div class="ico"><i class="el-icon-date"></i></div>
                  <div class="name">School Calendar</div>
                </div>
              </div>
            </div>



            <div
              :class="['life-con-imgs animate__animated  ', animFun('campusLifeRef_t', 1, -300) ? 'animate__bounceIn' : 'animate__bounceOut']">
              <img class="imgs" src="http://47.250.39.153:8600/common/image/2024/01/06/7.jpg" />

              <div class="r-content">
                <div class="current-title">Campus Life</div>
                <div class="be-current-more">
                  <div class="more-item">
                    <div class="item-imgs">
                      <div class="ico"><i class="el-icon-s-promotion"></i></div>
                    </div>
                    <div class="item-name">Feel the colorful youth</div>
                  </div>
                </div>

              </div>
            </div>





          </div>
          <div class="campus-life-con life-left">
            <div class="life-con-imgs">
              <div class="swiper-container swiper-container-horizontal swiper-campus">
                <div class="swiper-wrapper">
                  <!--<div class="swiper-slide">
                    <div class="cooperation-item">
                      <img class="imgs" src="https://www.pku.edu.cn/Uploads/Picture/2023/10/16/s652cee2d804d9.jpg" />
                    </div>
                  </div>-->
                  <div class="swiper-slide">
                    <div class="cooperation-item">
                      <img class="imgs"
                        src="http://47.250.39.153:8600/common/image/2024/01/06/8.jpg" />
                    </div>
                  </div>

                  <!--<div class="swiper-slide">
                    <div class="cooperation-item">
                      <img class="imgs" src="https://www.pku.edu.cn/Uploads/Picture/2023/10/16/s652cee1b78d85.jpg" />
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
            <div class="life-con-menu">
              <div
                :class="['con-menu-item animate__animated  ', animFun('campusLifeRef_t', 1, 50) ? 'animate__rotateInUpLeft' : 'animate__rotateOutDownLeft']">
                <div style="background:#0074ff;"><img style="opacity: 0.1;"  class="imgs" src="http://47.250.39.153:8600/common/image/2024/01/06/4.jpg" /></div>
                <div class="item-piece">
                  <div class="ico"><i class="el-icon-coordinate"></i></div>
                  <div class="name">Student Society</div>
                </div>
              </div>

              <div
                :class="['con-menu-item animate__animated  ', animFun('campusLifeRef_t', 1, 50) ? 'animate__rotateInUpLeft' : 'animate__rotateOutDownLeft']">
                <div style="background:#3390ff;"><img style="opacity: 0.1;"  class="imgs" src="http://47.250.39.153:8600/common/image/2024/01/06/5.jpg" /></div>
                <div class="item-piece">
                  <div class="ico"><i class="el-icon-camera"></i></div>
                  <div class="name">Art & Culture</div>
                </div>
              </div>

              <div
                :class="['con-menu-item animate__animated  ', animFun('campusLifeRef_t', 1, 50) ? 'animate__rotateInUpLeft' : 'animate__rotateOutDownLeft']">
                <div style="background:#66acff;"><img style="opacity: 0.1;" class="imgs" src="http://47.250.39.153:8600/common/image/2024/01/06/6.jpg" /></div>
                <div class="item-piece">
                  <div class="ico"><i class="el-icon-present"></i></div>
                  <div class="name">Sports & Health</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>



      <!--Admissions  v-if="admissionsList && admissionsList.length > 0"-->
      <div ref="admissionsRef" id="admissionsRef" class="stagesRef admissions-mian piece-shadow2">
        <div class="middle-content middle-max">
          <div class="current-title">Admissions</div>
          <div class="admissions-ul">
            <div
              :class="['admissions-li animate__animated  ', animFun('admissionsRef_t', 1, -300) ? 'animate__bounceIn' : 'animate__bounceOut']"
              v-for="(item, index) in admissionsList" :key="index"
              @click="item.onclick == null ?  handlerToTarget(`/${item.module}`, item.name, item.categoryId) : item.onclick() ">
              <img class="imgs" :src="item.image" />
              <div class="name">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>

      <!--底部导航栏-->
      <footCom :type="1"></footCom>
    </div>

    <!--测试-->
    <!--<div :class="['body_b', isShowDiv ? 'body_b_show' : '']">
      666
    </div>-->
  </div>
</template>

<script>
// @ is an alias to /src
// import collegeHeader from "@/components/head.vue";
import carousel from "@/components/carousel.vue";
//import bottomleft from "@/components/bottomLeft.vue"

//import { WOW } from 'wowjs'
//import 'wowjs/css/libs/animate.css';
import 'animate.css';
// import carousel2 from "@/components/carousel2.vue";
import { getAllBannerList, getLinkList, getNewList, getArticleList } from "@/api/home";
import { nextTick } from "vue";
import scrollReveal from 'scrollreveal';
import { getPList, getArticleDetail1 } from '@/api/home.js'
export default {
  name: "HomeView",
  components: {
    carousel,
  },
  created() {
    this.initData();
  },
  mounted() {
    /*var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    });
    wow.init();*/
    window.scrollTo(0, 0);
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
    this.scrollReveal.reveal('.reveal-top', {
      // 动画的时长
      duration: 2000,
      // 延迟时间
      delay: 500,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'top',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '73px',
      // 其他可用的动画效果
      opacity: 0.01,
      easing: 'linear',
      scale: 0.9,
      beforeReset: () => {
        console.log(111111111111);
      }
    })


    setTimeout(() => {
      this.isShowDiv = true
      setTimeout(() => {
        this.isShowDiv = false
      }, 5000)
    }, 3000)
  },
  data() {
    return {
      imgList: [],
      linkList: [],
      newList: [],
      eventList: [],
      iso: false,
      scrollReveal: scrollReveal(),
      // news动画
      isdownNews: false,
      getNewsOption: 0,
      studentArtList: [],//学生活动
      cooperationList: [],//Schools
      admissionsList: [],// admissions
      publicationsList: [],//科学
      mySwiper2: undefined,
      swiperCampus: undefined,
      mySwiper3: undefined,


      scrollTop: 0,//滚动条
      scrollMap: {

      },
      isShowDiv: false,
      admissionsImages:[
        require('@/assets/icons/a.png'),
        require('@/assets/icons/b.png'),
        require('@/assets/icons/c.png'),
        require('@/assets/icons/d.png'),
        require('@/assets/icons/e.png'),
        require('@/assets/icons/f.png')
      ],
      videoUrl: 'http://47.250.39.153:8600/common/image/2024/01/04/7ca1a3be1bafa5bf41805e48a2795eea.mp4'
    };
  },
  computed: {
    showCooperation() {
      let arr = this.cooperationList && Array.isArray(this.cooperationList) ? JSON.parse(JSON.stringify(this.cooperationList)) : []
      this.$nextTick(() => {
        this.initSwiper()
      })

      if (arr.length <= 4 && arr.length > 0) {
        arr.splice(1, arr.length)
      } else if (arr.length > 0) {
        arr.splice(arr.length - 4, arr.length)
      }

      return arr
    },

    cooperationResidue() {
      let arr = this.cooperationList && Array.isArray(this.cooperationList) ? JSON.parse(JSON.stringify(this.cooperationList)) : []
      this.$nextTick(() => {
        this.initSwiper()
      })

      let list = []
      if (arr.length <= 4 && arr.length > 0) {
        list = arr.splice(1, arr.length)
      } else if (arr.length > 0) {
        list = arr.splice(arr.length - 4, arr.length)
      }

      return list
    },
    publicationsShow() {
      this.$nextTick(() => {
        this.initSwiper3()
      })
      console.log('this.publicationsList', this.publicationsList)
      return this.publicationsList
    }
  },

  methods: {
    animFun(name, type, value) {
      if (name && name != '' && this.scrollTop) {
        if (type == 1) {
          if (this.scrollTop >= this.scrollMap[name] + value) {
            return true
          }
        } else {
          if (this.scrollTop <= this.scrollMap[name] + value) {
            return true
          }
        }

      }
      return false
    },
    handlerToTarget(path, name, categoryId) {
      if (path == '/home') {
        this.$router.push({ path: '/', query: { id: 1 } })
        return
      }
      if (path == '/study' || path == '/school' || path == '/student') {
        getArticleDetail1({ value: categoryId }).then((response) => {
          if (response && response.data) {
            this.$router.push({ path: path, query: { name, categoryId } })
          } else {
            this.$message.error('There is currently no introduction to this menu')
          }
        });
        return
      }
      if (this.$route.name == path) {
        return
      }
      this.$router.push({ path: path, query: { name, categoryId } })
    },

    // publicationsList:[],//科学

    getrouteData() {
      getPList({ pageSize: 1000 }).then((res) => {
        let admissionsList = []
        /*[{
          name: 'Apply Now',
          module: 'home',
          ico: 'el-icon-office-building',
          onclick: () =>{
            this.iso = true
          }
        }]*/
        let cooperationList = []
        this.publicationsList = []



        console.log('res.rows1', res.rows)
        if (Array.isArray(res.rows)) {
          res.rows.forEach(item => {

        console.log('res.rows1', item.parentId)
            if (item.parentId != 0) return


            switch (item.module) {
              case 'study':
                if (item.name == 'About Us') {
                  getArticleDetail1({ value: item.categoryId }).then((response) => {
                    if (response && response.data) {
                      response.data.images = this.imgUrl + response.data.images
                      response.data.oldData = item
                      this.publicationsList.push(response.data)

                      console.log('this.publicationsList1', this.publicationsList)
                    }
                  });
                }
                break;

              case 'school':

                item.images = 'https://www.pku.edu.cn/Uploads/Picture/2023/09/08/s64fa9a8e90378.png';
                item.title = item.name;
                cooperationList.push(item)
                break;
              case 'student':
                item.ico = 'el-icon-office-building';
                if (item.name == 'Financial Aids') {
                  item.ico = 'el-icon-school';
                }
                if (item.name == 'ScholarShip') {
                  item.ico = 'el-icon-collection';
                }
                if (item.name == 'Payment Instructions') {
                  item.ico = 'el-icon-receiving';
                }
                if (item.name == 'International Students') {
                  item.ico = 'el-icon-present';
                }
                admissionsList.push(item)
                break;
            }
          })
        }

        admissionsList.forEach((item, index) => {
          item.image = this.admissionsImages[index]
        })

        //测试数据
        /*if (cooperationList.length > 0) {
          cooperationList.push(cooperationList[0])
          if (cooperationList.length > 1) {
            cooperationList.push(cooperationList[1])
          }
          if (cooperationList.length > 2) {
            cooperationList.push(cooperationList[2])
          }
          if (cooperationList.length > 3) {
            cooperationList.push(cooperationList[3])
          }
        }*/


        let arr = [
          'http://47.250.39.153:8600/common/image/2024/01/06/gw5.jpg',
          'http://47.250.39.153:8600/common/image/2024/01/06/gw4.jpg',
          'http://47.250.39.153:8600/common/image/2024/01/06/gw3.jpg',
          'http://47.250.39.153:8600/common/image/2024/01/06/gw2.jpg',
          'http://47.250.39.153:8600/common/image/2024/01/06/gw1.jpg',
          'https://www.pku.edu.cn/Uploads/Picture/2020/01/09/s5e16e44bdc2a2.jpg',
          'https://www.pku.edu.cn/Uploads/Picture/2020/01/10/s5e1800bb27666.jpg'
        ]
        for (let i = 0; i < cooperationList.length; i++) {
          if (i < arr.length) {
            cooperationList[i].images = arr[i]
          }
        }

        //测试数据

        this.cooperationList = cooperationList
        this.admissionsList = admissionsList
        this.handleScroll()
      })
    },
    initSwiper() {
      setTimeout(() => {
        if (this.mySwiper2 != undefined && this.mySwiper2 != 'undefined') {
          // this.mySwiper.update();
        } else {
          this.mySwiper2 = new Swiper('.swiper-container2', {
            loop: true,
            preventClicks: false,//默认true
            autoplayDisableOnInteraction: false,
            paginationClickable: true,
            autoplay: 5000,//可选选项，自动滑动
            observer: true,
            observeParents: true,
            onSlideChangeEnd: (swiper) => {
              //this.swiperIndex = swiper.realIndex
            }
          });
        }
      }, 3000)
    },

    initSwiper3() {
      setTimeout(() => {
        if (this.mySwiper3 != undefined && this.mySwiper3 != 'undefined') {
          // this.mySwiper3.update();
        } else {
          this.mySwiper3 = new Swiper('.swiper-container3', {
            loop: true,
            preventClicks: false,//默认true
            autoplayDisableOnInteraction: false,
            paginationClickable: true,
            autoplay: 5000,//可选选项，自动滑动
            observer: true,
            observeParents: true,
            onSlideChangeEnd: (swiper) => {
              //this.swiperIndex = swiper.realIndex
            }
          });
        }
      }, 3000)
    },

    initSwiperCampus() {
      setTimeout(() => {
        if (this.swiperCampus != undefined && this.swiperCampus != 'undefined') {
          // this.swiperCampus.update();
        } else {
          this.swiperCampus = new Swiper('.swiper-campus', {
            loop: true,
            preventClicks: false,//默认true
            autoplayDisableOnInteraction: false,
            paginationClickable: true,
            autoplay: 5000,//可选选项，自动滑动
            observer: true,
            observeParents: true,
            onSlideChangeEnd: (swiper) => {
              //this.swiperIndex = swiper.realIndex
            }
          });
        }
      }, 3000)
    },

    //滚动条在Y轴上的滚动距离

    getScrollTop() {
      var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
      if (document.body) {
        bodyScrollTop = document.body.scrollTop;
      }
      if (document.documentElement) {
        documentScrollTop = document.documentElement.scrollTop;
      }
      scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
      return scrollTop;
    },

    //文档的总高度

    getScrollHeight() {
      var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
      if (document.body) {
        bodyScrollHeight = document.body.scrollHeight;
      }
      if (document.documentElement) {
        documentScrollHeight = document.documentElement.scrollHeight;
      }
      scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
      return scrollHeight;
    },

    //浏览器视口的高度

    getWindowHeight() {
      var windowHeight = 0;
      if (document.compatMode == "CSS1Compat") {
        windowHeight = document.documentElement.clientHeight;
      } else {
        windowHeight = document.body.clientHeight;
      }
      return windowHeight;
    }

    ,
    handleScroll() {

      let isBotto = (this.getScrollTop() + this.getWindowHeight()) == this.getScrollHeight();
      this.scrollTop = isBotto ? 99999999 : document.documentElement.scrollTop
      this.stagesHandle();
      /*if (1518 <= document.documentElement.scrollTop) {
        this.isdownNews = true
      }
      if (1518 > document.documentElement.scrollTop) {
        this.isdownNews = false
      }*/
    },

    //分区处理
    stagesItem() {
      let list = document.getElementsByClassName('stagesRef')
      for (let i = 0; i < list.length; i++) {
        this.scrollMap[list[i].id + '_t'] = list[i].offsetTop - 85;
        this.scrollMap[list[i].id + '_b'] = list[i].offsetTop + list[i].offsetHeight - 85;
      }

      //console.log("this.scrollMap = ", this.scrollMap)
    },
    stagesHandle() {
      setTimeout(() => {
        if (document.getElementsByClassName('stagesRef') && document.getElementsByClassName('stagesRef').length > 0) {
          this.stagesItem()
        } else {
          setTimeout(() => {
            if (document.getElementsByClassName('stagesRef') && document.getElementsByClassName('stagesRef').length > 0) {
              this.stagesItem()
            } else {
              setTimeout(() => {
                if (document.getElementsByClassName('stagesRef') && document.getElementsByClassName('stagesRef').length > 0) {
                  this.stagesItem()
                } else {
                  setTimeout(() => {
                    if (document.getElementsByClassName('stagesRef') && document.getElementsByClassName('stagesRef').length > 0) {
                      this.stagesItem()
                    } else {
                      setTimeout(() => {
                        if (document.getElementsByClassName('stagesRef') && document.getElementsByClassName('stagesRef').length > 0) {
                          this.stagesItem()
                        } else {
                          setTimeout(() => {
                            if (document.getElementsByClassName('stagesRef') && document.getElementsByClassName('stagesRef').length > 0) {
                              this.stagesItem()
                            } else {
                              setTimeout(() => {
                                if (document.getElementsByClassName('stagesRef') && document.getElementsByClassName('stagesRef').length > 0) {
                                  this.stagesItem()
                                }
                              }, 1000)
                            }
                          }, 500)
                        }
                      }, 300)
                    }
                  }, 200)
                }
              }, 100)
            }
          }, 50)
        }
      }, 10)

    },
    handlerToRoute(url) {
      this.$router.push({ path: url })
    },
    async initData() {
      this.initSwiperCampus()
      await this.getBannerList();
      await this.getLinkList();
      await this.getNewList();
      await this.getEventList()
      await this.getStudentArticleList()
      await this.getrouteData()
    },
    getBannerList() {
      getAllBannerList().then((response) => {
        // console.log('查看数据',response)
        // console.log(this.imgUrl)
        var data = response.data;
        var url = this.imgUrl;
        console.log(url, 'ssss');
        var temp = [];
        data.forEach((item) => {
          temp.push(url + item.imageUrl);
        });
        this.imgList = temp;
        console.log(
          temp,
          "查看数",
          "../assets/FA_ACCA Flyer - MILA-03_Desktop.jpg".indexOf("..")
        );

        this.handleScroll()
      });
    },
    getLinkList() {
      getLinkList().then((response) => {
        console.log("友情链接", response);
        this.linkList = response.data;
        this.handleScroll()
      });
    },
    getNewList() {
      //先屏蔽
      getNewList({ categoryName: 'News', module: 'news', pageNum: 1, pageSize: 10, orderByColumn: 'updateTime', isAsc: 'desc' }).then((response) => {
        response.rows.forEach(item => {
          item.images = this.imgUrl + item.images

          //item.images = 'https://www.pku.edu.cn/Uploads/Picture/2023/12/29/s658e1ff9a62fe.jpg'
        })
        this.newList = response.rows;
        console.log("News详情", response.rows, this.newList);
        this.handleScroll()
      });
    },
    gopush1(name) {
      this.$router.push({ path: '/course', query: { name } })
    },
    getEventList() {
      //先屏蔽
      getNewList({ categoryName: 'Events', module: 'news', pageNum: 1, pageSize: 4, orderByColumn: 'updateTime', isAsc: 'desc' }).then((response) => {
        this.eventList = response.rows;
        response.rows.forEach(item => {
          item.images = this.imgUrl + item.images
          //item.images = 'https://www.pku.edu.cn/dat/img/344b546ab3de42e885101859f6b8ed3b.jpg'
        })
        console.log("EVENTS详情", response.rows, this.newList);
        this.handleScroll()
      });
    },
    handlerToLink(url) {
      window.open(url);
      // this.$router.push({path:"ouruniversity"})
    },
    gopush(id) {
      this.$router.push({ name: 'Detail', params: { id } })
    },

    gopush2(e) {
      let arr = JSON.parse(localStorage.getItem('menu'))
      console.log(arr);
      if (e == 1) {
        let arr1 = arr[1] && arr[1].list
        if (arr1.length == 0) {
          this.$message.error('no menu')
          return
        }
        this.$router.push({ path: '/about', query: { name: arr1[0].name, categoryId: arr1[0].categoryId } })
      } else if (e == 2) {
        let arr1 = arr[5] && arr[5].list
        if (arr1.length == 0) {
          this.$message.error('no menu')
          return
        }
        this.$router.push({ path: '/study', query: { name: arr1[0].name, categoryId: arr1[0].categoryId } })
      }
    },
    //新增的
    getStudentArticleList() {
      //先屏蔽
      getArticleList({ categoryId: 9, pageNum: 1, pageSize: 10, orderByColumn: 'updateTime', isAsc: 'desc' }).then((response) => {
        response.rows.forEach(item => {
          item.images = this.imgUrl + item.images
          //item.images = 'https://www.pku.edu.cn/Uploads/Picture/2023/12/29/s658e1ff9a62fe.jpg'
        })
        this.studentArtList = response.rows;
        this.handleScroll()
      });
    },


  },
};
</script>
<style lang="scss" type="text/scss" src="../assets/style/home.scss"/>
<style lang="scss" scoped>
.div-text .title {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    //flex: 1;
    margin-right: 5px;
  }

  .ico-img {
    margin-left: 2px;
    margin-right: 0;
    width: 20px;
    height: 20px;
  }
}

.home {
  min-height: 100vh;
  background: #f2f2f2;
  max-width: 10000px;
  margin: 0 auto;
}

#app {
  background: #f2f2f2;
}



.downNews {
  animation-name: newAnimate;
  animation-duration: 3s;
}

@keyframes newAnimate {
  0% {
    top: -33px;
    transform: translateY(-73px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes newAnimate1 {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }

  100% {
    transform: translateY(-73px);
    opacity: 0;
  }
}

.upNews {
  animation-name: newAnimate1;
  animation-duration: 3s;
}

.div-p {
  padding: 5px;
}

.div-p2 {
  margin-top: 20px;
}

.div-p-tou {
  background: linear-gradient(to right, #f9f9f9, #1e50ae) no-repeat left bottom;
  background-size: 0 2px;
  transition: background-size 500ms ease-in-out;
}

.div-p:hover {
  background-size: 100% 2px;
}
</style>

<style lang="scss" scoped>
.part-four {
  width: 100%;
  padding: 0 0;
  min-width: 768px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;

  .middle-content {
    width: 100%;
    padding-top: 10px;
    //margin-top: 20px;
    padding: 40px 170px;
    padding-top: 1px;
  }



  .title {
    margin-top: 30px;
  }

  .flex-ac-js {
    //flex-wrap: wrap;
  }

  .btn-more {
    border: #1e50ae solid 1px;
    color: #1e50ae;
  }

  .btn-more:hover {
    background: #1e50ae;
    color: #fff;
  }
}

.title-nav {
  background: #fff;
  //margin-top: 20px;
}

.new-custom-mian {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;


  .middle-content {
    width: 100%;
    padding: 0 160px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-bottom: 20px;

    .li-first-55 {
      width: calc(50% - 20px);
    }

    .new-li-first {
      position: relative;
      width: calc(50% - 20px);
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 10px 10px;
      box-sizing: border-box;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .5);
      height: 290px;

      .first-ims {
        width: 100%;
        height: 100%;
        display: block;
        cursor: pointer;
      }

      .first-name {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        z-index: 3;
        padding: 20px;
        box-sizing: border-box;
        cursor: pointer;

        span {
          color: #fff;
          font-size: 18px;
          width: 100%;
          text-align: left;
          display: block;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }
    }

    .new-li-first:hover {
      transform: scale(1.04);
      transition: all 1s
    }

    .new-li-second {
      //height: 290px;
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0;
      box-sizing: border-box;

      .second-item {
        width: calc(50% - 20px);
        height: 135px;
        margin: 10px 10px;
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .5);
        border-bottom: #1e50ae solid 3px;
        background: #fff;
        cursor: pointer;

        span {
          width: 100%;
          text-align: left;
          display: block;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          color: #333;
          font-size: 16px;
          line-height: 20px;
        }
      }

      .second-item:hover {
        transform: scale(1.07);
        transition: all 1s
      }
    }



    .li-first-55 {
      width: calc(50% - 20px);
    }

    .li-second-45 {
      width: 50%;
    }
  }


}


.events-custom-mian {
  width: 100%;
  background: #fff;
  padding: 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;

  .events-left {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    text-align: left;
    max-width: 50px;

    .title {
      writing-mode: tb-lr;
      writing-mode: vertical-lr;
      font-size: 28px;
      line-height: 1.3;
      color: #333;
    }

    .time {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      margin-top: 120px;
      cursor: pointer;
    }

    .more {
      writing-mode: vertical-lr;
      font-size: 14px;
      color: #1e50ae;
      margin-top: 10px;
      cursor: pointer;
    }

  }

  .events-right {
    flex: 1;
    margin-left: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .events-item {
      position: relative;
      width: calc(25% - 20px);
      margin-left: 20px;
      height: 600px;
      cursor: pointer;
      box-sizing: border-box;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .5);
      transform: none;
      overflow: hidden;

      .imgs {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        cursor: pointer;
      }

      .imgs:hover {
        transform: scale(1.1);
        transition: all 1s
      }

      .name {
        position: absolute;
        left: 30px;
        top: 40px;
        writing-mode: tb-lr;
        writing-mode: vertical-lr;
        max-height: 70%;
        font-size: 22px;
        line-height: 1.6;
        color: #fff;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        text-align: left;
      }

      .more {
        position: absolute;
        right: 30px;
        bottom: 0px;
        writing-mode: tb-lr;
        writing-mode: vertical-lr;
        text-align: center;
        font-size: 15px;
        color: #fff;
        line-height: 1.6;
      }

      .more:after {
        content: "";
        border-left: 1px solid #fff;
        display: inline-block;
        height: 40px;
        margin-top: 8px;
      }
    }
  }
}

.student-activities-mian {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  .middle-content {
    width: 100%;
    display: flex;
    padding: 30px 170px;
    //margin-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;

    .student-activities-left {
      position: relative;
      width: calc(50% - 10px);
      margin-right: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .5);

      .activities-item {
        position: relative;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 280px;
        cursor: pointer;
        transform: none;
        overflow: hidden;

        .imgs {
          width: 98%;
          height: 98%;
          display: block;
          object-fit: cover;
        }

        .name {
          position: absolute;
          left: 0;
          width: 100%;
          bottom: 10px;
          padding: 0 10px;
          text-align: left;

          span {
            width: 100%;
            display: block;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            color: #fff;
            font-size: 18px;
          }
        }
      }

      .activities-item:hover {
        .imgs {
          transform: scale(1.2);
          transition: all 1s;
        }
      }

      .item-l {
        //border-left: #1e50ae solid 1px;
      }

      .item-t {
        //border-top: #1e50ae solid 1px;
      }

    }




    .left-title {
      width: 100%;
      margin-right: 0;
      margin-top: 10px;
    }


    .student-activities-right {
      width: calc(50% - 10px);
      margin-left: 10px;
      display: block;

      .title {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 28px;
        color: #333;
        padding: 10px 0;
      }

      .more-mian {
        width: 100%;
        display: flex;
        justify-content: center;
        align-self: center;
        padding-bottom: 70px;

      }
    }
  }


}

.current-title {
  width: 100%;
  padding-bottom: 20px;
  display: block;
  text-align: center;
  font-size: 28px;
  color: #333;

  span {
    display: block;
    //padding-left: 22px;
  }
}

.current-title2 {
  font-size: 20px;
}

.cooperation-mian {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-top: 20px;

  .middle-content {
    width: 100%;
    padding: 30px 180px 10px 180px;

    .cooperation-ul {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 20px 0;


      .cooperation-li {
        background-color: #f2f2f2;
        width: calc(50% - 20px);
        margin: 0 10px;
        height: 420px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .5);

        .swiper-container {
          width: 100%;
          height: 100%;

          .swiper-wrapper {
            width: 100%;
            height: 100%;

            .swiper-slide {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              .cooperation-item {
                position: relative;
                width: 100%;
                height: 100%;
                display: block;
                transform: none;
                overflow: hidden;
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .5);
                cursor: pointer;

                .imgs {
                  width: 100%;
                  height: 100%;
                  display: block;
                  object-fit: cover;
                }

                .imgs:hover {
                  transform: scale(1.2);
                  transition: all 1s;
                }

                .name {
                  position: absolute;
                  left: 0;
                  width: 100%;
                  bottom: 20px;
                  padding: 0 20px;
                  text-align: center;
                  opacity: 0.8;
                  background: #fff;
                  span {
                    width: 100%;
                    display: block;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    color: #2b66a7;
                    ffont-size: 28px;
                  }
                }
              }
            }
          }
        }

      }

      .cooperation-right {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        .right-item {
          position: relative;
          width: calc(50% - 20px);
          margin: 0 10px 20px 10px;
          height: 200px;
          display: block;
          transform: none;
          overflow: hidden;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .5);
          cursor: pointer;

          .imgs {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }

          .imgs:hover {
            transform: scale(1.1);
            transition: all 1s;
          }

          .name {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 20px;
            padding: 0 0px;
            text-align: center;
            opacity: 0.8;
            background: #fff;
            span {
              width: 100%;
              display: block;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
              text-align: center;
              color: #2b66a7;
              ffont-size: 28px;
            }
          }
        }


      }
    }
  }


}

.admissions-mian {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  //margin-top: 20px;
  .middle-content {
    width: 100%;
    display: block;
    padding: 100px 150px 100px 150px;

    .admissions-ul {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 40px;
      margin-top: 20px;

      .admissions-li {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        margin: 10px 0;
        cursor: pointer;

        .imgs {
          i {
            font-size: 80px;
            color: #333;
          }
        }

        .imgs:hover {
          transform: scale(1.2);
          transition: all 1s;
        }

        .name {
          width: 100%;
          display: block;
          text-align: center;
          margin-top: 20px;
          color: #333;
          font-size: 18px;
        }
      }
    }
  }

}

.current-more-p {
  padding: 10px 0;
}

.piece-shadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .2);
}

.piece-shadow2 {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .2);
}

.piece-shadow-d {
  width: 100%;
  height: 5px;
  display: block;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}



.publications-mian {
  position: relative;
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin: 0 auto;

  .middle-content {
    position: relative;
    width: 100%;
    padding: 10px 10px;
    display: block;

    .publications-carousel {
      width: 100%;
      height: 360px;
      display: block;

      .swiper-container {
        width: 100%;
        height: 100%;

        .swiper-wrapper {
          width: 100%;
          height: 100%;

          .swiper-slide {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .cooperation-item {
              position: relative;
              width: 100%;
              height: 100%;
              display: block;
              transform: none;
              overflow: hidden;

              .imgs {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
              }
            }
          }
        }
      }
    }

    .publications-more {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 30px 0 0 10px;
      flex-flow: column;

      .current-title {
        text-align: left;
        padding-bottom: 5px;
      }

      .be-current-more {
        justify-content: flex-start;
      }

    }


    .publications-content {
      position: relative;
      margin-top: -220px;
      margin-left: 330px;
      width: calc(100% - 330px);
      z-index: 33;
      padding-bottom: 5px;

      .article-content {
        //margin: 0 auto;
        width: 94%;
        padding: 40px;
        background: #1e50ae;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        cursor: pointer;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .5);

        .imgs {
          width: 150px;
          height: 150px;
          margin-right: 50px;
        }

        .imgs:hover {
          transform: scale(1.1);
          transition: all 1s
        }


        .r-content {
          flex: 1;

          .name {
            width: 100%;
            display: block;
            font-size: 20px;
            line-height: 1.6;
            color: #fff;
            text-align: left;
            display: block;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .value {
            width: 100%;
            margin-top: 10px;
            display: block;
            font-size: 14px;
            line-height: 1.6;
            color: #fff;
            text-align: left;
            display: block;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 10;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }

}

.campus-life-mian {
  //margin-top: 20px;
  width: 100%;
  background: #003572;
  display: flex;
  justify-content: center;
  align-items: center;

  .middle-content {
    width: 100%;
    padding: 20px 150px 80px 150px;
    display: block;

    .campus-life-con {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      .life-con-menu {
        position: relative;
        width: 440px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-wrap: wrap;
        z-index: 3;
        //margin-right: -30px;
        margin-bottom: 80px;
        .con-menu-item {
          position: relative;
          width: 220px;
          height: 200px;
          background: #9F9998;

          .imgs {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }

          .item-piece {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            width: 100%;
            height: 100%;
            z-index: 4;
            cursor: pointer;

            .ico {
              display: flex;
              justify-content: center;
              align-items: center;

              i {
                font-size: 46px;
                color: #fff;
              }
            }


            .name {
              width: 100%;
              display: block;
              color: #fff;
              font-size: 20px;
              text-align: center;
              margin-top: 15px;
            }
          }

          .item-piece:hover {
            .ico {
              transform: scale(1.4);
              transition: all 1s
            }
          }
        }
      }

      .life-con-imgs {
        position: relative;
        width: 55%;
        height: 550px;
        transform: none;
        overflow: hidden;
        cursor: pointer;

        .swiper-container {
          width: 100%;
          height: 100%;

          .swiper-wrapper {
            width: 100%;
            height: 100%;

            .swiper-slide {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              .cooperation-item {
                position: relative;
                width: 100%;
                height: 100%;
                display: block;
                transform: none;
                overflow: hidden;

              }
            }
          }
        }

        .imgs {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }

        .imgs:hover {
          transform: scale(1.1);
          transition: all 1s
        }

        .r-content {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-flow: column;

          .current-title {
            width: 100%;
            color: #fff;
            padding-bottom: 0;
          }

          .be-current-more {
            width: 100%;
            margin-top: 5px;

            .more-item {
              .item-imgs {
                border: #fff dashed 1px;

                .ico {
                  border: #fff solid 1px;

                  i {
                    color: #fff;
                    font-size: 16px;
                  }
                }
              }

              .item-name {
                color: #fff;
              }
            }
          }
        }

        .r-content:hover {
          .imgs {
            transform: scale(1.1);
            transition: all 1s
          }
        }
      }
    }


    .life-left {
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: -80px;

      .life-con-menu {
        margin-right: 0;
        margin-bottom: 0;

        .con-menu-item {
          background: #261819;
        }
      }


      .life-con-imgs {
        width: calc(45% + 30px);
        height: 360px;
      }

    }
  }


}

.be-current-more {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .more-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 5px 0;

    .item-imgs {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 3px;
      border: #1e50ae dashed 1px;

      .ico {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: #1e50ae solid 1px;

        i {
          color: #1e50ae;
          font-size: 16px;
        }
      }
    }

    .item-name {
      font-size: 14px;
      color: #1e50ae;
      margin-left: 15px;
    }
  }

  .more-item:hover {
    .item-imgs {
      .ico {
        background: #1e50ae;

        i {
          color: #fff;
        }
      }
    }

  }
}

.current-more-15 {
  margin-top: 0px;

  .more-item {
    margin: 0 20px;
  }
}


.be-curre-whot {
  background: #fff;
  margin-top: 0;
  padding-top: 0px;
  padding-bottom: 30px;
}

.second-middle {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center; //

  .second-left {
    position: relative;
    flex: 1;
    display: block;

    .div-p {
      position: relative;
      width: 100%;

      p {
        font-size: 20px;
      }
    }

    .second-right {
      position: absolute;
      margin-left: 20px;
      top: 12px;
      right: -420px;
      width: 400px;
      height: calc(100% - 22px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;


      .vioew-mian {
        position: relative;
        width: 100%;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000000;

        video {
          width: 100% !important;
          height: auto !important;
        }

        .error-mian {
          width: 100%;
          text-align: center;
          display: block;
          color: #fff;
          font-size: 18px;
        }
      }

      .vioew-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;

        .btn {
          color: #1e50ae;
          font-size: 24px;
          margin: 0 10px;
          font-weight: bold;
        }
      }


    }
  }



  .second-right2 {
    width: 400px;
    height: 1px;
    display: block;
    margin-left: 20px;
  }


}

.div-line {
  background: #1e50ae;
}
</style>


<style lang="scss">
.body_b {
  position: fixed;
  left: 0%;
  top: 100%;
  background: #fff;
  overflow: hidden;
  transition: top 0.7s cubic-bezier(0.5, 0, 0.2, 1) 0s;
  z-index: 1;
  overflow: auto;
  outline: none;
  z-index: 55555555;
  width: 100%;
  height: 150%;
  display: block;
}

.body_b_show {
  top: 0;
}


@media screen and (max-width: 1600px) {
  .second-left {
    .div-p {
      p {
        font-size: 15px !important;
      }
    }

  }
}

.el-message-box {
  width: 500px;
  .el-message-box__header {
    padding-top: 0;
  }
  .el-message-box__content {
    p {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }
  }
  .el-message-box__btns {
    .el-button--primary {
      border-color: #7f7f7f;
      background-color: #7f7f7f;
    }
    .el-button--small {
      padding: 15px 35px;
    }
  }
}
.el-message-box--center {
  padding-bottom: 10px;
}
</style>
