<template>
    <div class="web-bottoms"></div>
</template>
<script>
export default {
    name:'webBottom'
}
</script>
<style lang="scss" scoped>
.web-bottoms {
    height:72px;
    width:100%;
    background-color: #000;
}
</style>