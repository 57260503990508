<template>
  <div class="current-campus">
    <collegeHeader />
    <div class="university-wrap">
      <h1 style="width: 1400px; min-width: 1400px;text-align: left;margin: 20px auto 0; font-weight: 600;">News And Events</h1>
      <div
        class="flex-ac"
        style="width: 90%; margin: 20px auto; align-items: flex-start; min-width: 1400px; justify-self: center;"
      >

        <div class="list-call">
          <div class="content">
            <div class="nei"  @click="gopush(item.articleId)" v-for="item in newList" :key="item">
              <img class="p-imgs" :src="item.images" />
              <span>{{item.createTime}}</span>
              <p>{{ item.title }}</p>
              <div>{{ item.summary }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      class="pageone"
      style="margin: 10px 0"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="10"
      :current-page="page"
      @current-change="getchangePage">
    </el-pagination>
    <webBottom />
    <!-- <bottomleft /> -->
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import webBottom from "@/components/webBottom.vue";
import bottomleft from "@/components/bottomLeft.vue";
import { getNewList, getArticleDetail } from "@/api/home";
export default {
  components: {
    collegeHeader,
    webBottom,
    // bottomleft,
  },
  created() {
    this.initData();
  },
  data() {
    return {
      total: 0,
      page: 1,
      newList: [],
    };
  },
  methods: {
    initData() {
      this.getNewList();
    },
    getNewList() {
        console.log("News详情", response.rows, this.newList);
      getNewList({pageNum:this.page,pageSize:20,module:'news',orderByColumn:'updateTime',isAsc:'desc'}).then((response) => {
        response.rows.forEach(item => {
          item.images = this.imgUrl +  item.images
        })
        this.newList = response.rows;
        this.total = response.total
        // this.total = 200
        console.log("News详情", response.rows, this.newList);
      });
    },
    getchangePage(page) {
      console.log(page);
      this.page = page;
      this.getNewList()
    },
    gopush(id) {
      this.$router.push({name:'Detail', params: {id}})
    }
  },
};
</script>
<style lang="scss" type="text/scss" src="../assets/style/OurUniversity.scss" />
<style lang="scss" scoped>
.university-wrap {
  min-height: calc(100vh - 100px - 72px - 32px);
}
.list-call {
  width: 100%;
  height: auto;
  margin-top: 20px;
  .content {
    display: flex;
    flex-wrap: wrap;
    width: 1400px;
    background-color: #fff;
  }
  .nei {
    min-width: 302px;
    max-width: 400px;
    margin: 0 33px;
    margin-bottom: 15px;
    background-color: #fff;
    .p-imgs {
      width: 100%;
      height: auto;
    }
    p {
      margin: 8px 0;
      font-size: 18px;
      font-family: 'SourceSansPro', Helvetica, Arial, sans-serif;
      color: #003572;
    }
    div {
      margin-bottom: 16px;
      text-align: left;
    }
    span {
      display: block;
      text-align: left;
      margin: 10px 0;
      font-size: 15px;
    }
  }
}
.pageone {
  .el-pager {
    li {
      width: auto;
    }
  }
}

</style>
