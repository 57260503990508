<template>
  <div>
    <collegeHeader />
    <div class="header-part flex-ac">
      <div style="margin-left: 150px">
        <div class="second-title" style="font-size: 30px; text-align: left">
          Apply Now
        </div>
        <div class="title">Inspiring Minds, Transforming</div>
        <div class="title">Futures at MILA University</div>
      </div>
    </div>
    <div class="Apply-now-wrap">
      <div class="apply-now-content">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active">Apply</li>
        </ol>
        <div class="apply-now-title">Apply Now</div>
        <div class="card-wrap">
          <div class="card-left">
            <div class="step-text">Step 1</div>
            <div class="step-content">Before registering</div>
            <div class="step-bottom">
              kindly look over your entry requirements and check the
              prerequisites with our counselors
            </div>
          </div>
          <div class="card-right">
            <i
              class="el-icon-phone-outline"
              style="
                color: rgba(204, 151, 37, 0.955);
                font-size: 90px;
                margin-bottom: 20px;
              "
            ></i>
            <div class="apply-content">Call one of our course specialists</div>
            <p>Monday to Friday:9am-5.30pm AEDT</p>
            <div class="btn-call">Call +6 (03) 7491 8622</div>
          </div>
        </div>
        <div class="card-wrap">
          <div class="card-left">
            <div class="step-text">Step 2</div>
            <div class="step-content">Refer to document checklist</div>
            <div class="step-bottom">
              To avoid delays in the process, please ensure all information
              required in the application is complete and correct
            </div>
            <div class="other-thing">
              <el-collapse v-model="activeNames">
                <el-collapse-item title="Malaysian Students" name="1">
                  <div style="padding-left: 20px">
                    与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class="other-thing">
              <el-collapse v-model="activeName">
                <el-collapse-item title="International Students" name="1">
                  <div style="padding-left: 20px">
                    与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
          <div class="card-right flex-jc-fc">
            <div class="icon-wrap">
              <i
                class="el-icon-tickets"
                style="color: rgba(204, 151, 37, 0.955); font-size: 90px"
              ></i>
            </div>
            <div class="apply-content">Document Checklist</div>
            <p>Ensure all are in order</p>
            <!-- <div class="btn-call">Call +6 (03) 7491 8622</div> -->
          </div>
        </div>
        <div class="card-wrap">
          <div class="card-left">
            <div class="step-text">Step 3</div>
            <div class="step-content">How to enrol:</div>
            <div
              class="btn-call"
              style="
                margin: 0;
                color: rgba(204, 151, 37, 0.955);
                margin-top: 10px;
              "
            >
              Ontine Application
            </div>
            <a href="" style="margin-top: 10px"
              >Download Form-Undergraduate Programme</a
            >
            <a href="">Download Form-Postgraduate Programme </a>
            <a href="">Download Form-Micro-credential and Short Courses</a>
          </div>
          <div class="card-right flex-jc-fc">
            <div class="icon-wrap">
              <i
                class="el-icon-mobile-phone"
                style="color: rgba(204, 151, 37, 0.955); font-size: 90px"
              ></i>
            </div>
            <div class="apply-content">Apply Online</div>
            <p>Click to apply online</p>
          </div>
        </div>
      </div>
    </div>
    <webBottom />
    <!-- <bottomleft /> -->
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import bottomleft from "@/components/bottomLeft.vue";
import webBottom from "@/components/webBottom.vue";
export default {
  components: {
    collegeHeader,
    webBottom,
    // bottomleft
  },
  data() {
    return {
      activeNames: [],
      activeName: [],
    };
  },
};
</script>
<style lang="scss" scoped>
.header-part {
  width: 100%;
  height: 400px;
  background-image: url("../assets/2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 0;
}
</style>
<style lang="scss" type="text/scss" src="../assets/style/ApplyNow.scss">
