<template>
  <div>
    <div class="university-wrap">
      <h1
        style="text-align: left;margin: 20px auto 0; padding-left: 20px; font-weight: 600;">
        {{ form.title }}</h1>
      <div class="flex-ac"
        style="width: 90%; margin: 20px auto; align-items: flex-start; justify-self: center;">
        <div class="list-call" :style="{
          display: 'flex',

        }">

          <!-- 导航栏 -->
          <div :style="{
            width: '23%',
            background: '#fff',
            textAlign: 'left',
            height: 'auto',
            alignSelf: 'flex-start',
          }">
            <!-- 一级导航 -->
            <div :style="{
              padding: '10px 20px',
              borderBottom: '1px solid #e5e5e5',
            }" v-for="(i, k) in data" :key="k">
              <span :style="{
                fontSize: '20px',
                padding: '10px 0',
                cursor: 'pointer',
                color: '#727272',
              }" @click="goTo(i.path)">{{ i.name }}</span>
              <!-- 二级导航 -->
              <div :class="{ active: isCurrentCategory(j.categoryId)}" :style="{
                margin: '10px 0 5px 20px',
                fontSize: '16px'
              }" v-for="(j, kk) in i.children" :key="kk">
                <span :style="{
                  cursor: 'pointer',
                }" @click="gopush(j.categoryId)">{{ j.name }}</span>
              </div>
            </div>
          </div>

          <div class="content">
            <div class="nei">
              <hr />
              <div>
                <p><strong>Programme Learning Outcomes</strong></p>
                <p>The Programme Outcomes (PO) or Programme Learning Outcomes (PLO) are the statements that describe what students are expected to know and be able to perform or attain by the time of graduation. These relate to the knowledge, skills and attitudes that students acquire through the programme. The Table lists the PLO statement for the Department of Mechanical Engineering that has been adopt in Engineering Programme with slight change in the sequence of their occurrence from ETAC Standard 2020.</p>
                <p> <img src="../assets/detail_extra/s2.png" /> </p>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>

    <!--底部导航栏-->
    <footCom></footCom>

  </div>
</template>
<script>
import { categoryList, getArticleDetail1 } from "@/api/home";
export default {
  components: {
  },
  created() {
    this.initData();
  },
  data() {
    return {
      isCurrentCategory(id){
        console.log("this.$route.query.id", this.$route.query.id, id)
        return id == this.$route.query.id
      },
      form: {},
      data: [
        {
          id: 1,
          name: "Pre-University",
          children: [],
        },
        {
          id: 4,
          name: "Diploma",
          children: [],
        },
        {
          id: 7,
          name: "Undergraduate",
          path: "/OurUniversity",
          children: [],
        },
        {
          id: 10,
          name: "Postgraduate",
          children: [],
        }
      ],
    };
  },
  methods: {
    async initData() {
      
      this.getNewList();

      for(let i = 0; i < this.data.length; i++) {
        var item = this.data[i];
        var switchName = item.name == 'Pre-University' ? 'Foundation' : item.name;
        let res = await categoryList({ pageSize: 1000, module: 'course', parentName: switchName, orderByColumn: 'updateTime', isAsc: 'desc' })
        
        for(let j = 0; j< res.rows.length; j++){
          var item2 = res.rows[j];
          let res2 = await categoryList({ pageSize: 1000, module: 'course', parentId: item2.categoryId, orderByColumn: 'updateTime', isAsc: 'desc' })
          if(res2.rows.length > 0) {
            item.children = res2.rows
            break;
          }
        }

      }

    },
    gopush(id) {
      getArticleDetail1({ value: id }).then((response) => {
        if (response && response.data) {
          this.$router.push({ path: 'Detaile', query: { id } })
          this.getNewList();
        } else {
          this.$message.error('There is currently no introduction to this Detail')
        }
      });
    },
    getNewList() {
      console.log("this.$route.query.id", this.$route.query.id);
    }
  },
};
</script>
<style lang="scss" scoped>
.current-campus {
  margin-top: 100px;
}

.university-wrap {
  margin-top: 80px;
  padding-top: 20px;
  min-height: calc(100vh - 100px - 72px - 32px);
}

.list-call {
  width: 100%;
  height: auto;
  margin-top: 20px;

  .content {
    display: flex;
    flex-wrap: wrap;
    width: 1400px;
    background-color: #fff;
  }

  .nei {
    width: 100%;
    margin: 0 24px;
    margin-bottom: 15px;
    background-color: #fff;

    .p-imgs {
      width: 100%;
      height: auto;
    }

    p {
      margin: 8px 0;
      font-size: 18px;
      font-family: 'SourceSansPro', Helvetica, Arial, sans-serif;
      color: #003572;
    }

    div {
      margin-bottom: 16px;
      text-align: left;
    }

    span {
      display: block;
      text-align: left;
      margin: 10px 0;
      font-size: 15px;
    }
  }
}

.active {
  color: #fff !important;
  background-color: #465584 !important;
}</style>
<style lang="scss" type="text/scss" src="../assets/style/OurUniversity.scss" />
