<template>
  <div>
    <div class="form-active" v-if="showApply">
      <div class="video-mian">
        <!--autoplay-->
        <video v-if="videoUrl && videoUrl != ''"
          :src="videoUrl" controls="controls"></video>
        <div class="error-mian" v-else>视频加载出错...</div>
        <div class="close-ico"><i class="el-icon-close" @click="close"></i></div>
      </div>
    </div>
    <div class="bottom-left">
      <div class="content-wrap content-wrap2" @click="handlerToIndex">
        <i class="el-icon-s-cooperation" style="font-size: 30px"></i>
        <div class="name-left" style="font-size: 20px">New Campus</div>
      </div>

      <div class="content-wrap" @click="handlerToIndex2">
        <i class="el-icon-s-finance" style="font-size:  30px"></i>
        <div class="name-left" style="font-size: 20px">New Futuer</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "bottomLeft",
  props: {
    isOpen: Boolean
  },
  created() {
  },
  watch: {
    /*isOpen(newV) {
      this.showApply = !this.showApply
    }*/
  },
  data() {
    return {
      showApply: false,
      videoUrl: ''
    };
  },
  methods: {
    handlerToIndex() {
      this.videoUrl = 'http://47.250.39.153:8600/common/image/2024/01/04/7ca1a3be1bafa5bf41805e48a2795eea.mp4'
      this.showApply = true;
    },
    handlerToIndex2() {
      this.videoUrl = 'http://47.250.39.153:8600/common/image/2024/01/04/7ca1a3be1bafa5bf41805e48a2795eea.mp4'
      this.showApply = true;
    },
    close() {
      this.$emit('close')
      this.showApply = false
    }
  },
};
</script>
<style>
.el-select-dropdown__list {
  display: flex;
  flex-direction: column;
}
</style>
<style lang="scss" scoped>
.bottom-left {
  position: fixed;
  left: 0;
  z-index: 2222;
  bottom: calc(50% - 193px);

  a {
    text-decoration: none;
    color: white;
  }

  .img-wrap {
    padding: 5px 3px;
    background-color: #003572;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }


  .content-wrap {
    cursor: pointer;
    width: 75px;
    min-height: 183px;
    border-radius: 5px;
    margin-top: 10px;
    padding: 15px 5px;
    background-color: #003572;
    color: white;
  }



  .name-left {
    margin-top: 63px;
    transform: rotate(-90deg);
    white-space: nowrap;
  }


  .content-wrap2 {
    padding: 15px 5px;

    .name-left {
      margin-top: 73px;
      transform: rotate(-90deg);
      white-space: nowrap;
    }
  }

}

.form-active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba($color: #000000, $alpha: 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .video-mian {
    position: relative;
    min-width: 640px;
    min-height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;

    .error-mian {
      width: 100%;
      text-align: center;
      display: block;
      color: #fff;
      font-size: 18px;
    }

    .close-ico {
      position: absolute;
      width: 32px;
      height: 32px;
      top: -16px;
      right: -16px;
      z-index: 333333;
      border: #fff solid 2px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba($color: #000000, $alpha: 0.3);
      cursor: pointer;

      i {
        color: #fff;
        font-size: 24px;
      }
    }
  }
}
</style>
