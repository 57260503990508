<template>
  <div>
    <collegeHeader />
    <div class="header-part flex-ac">
      <div style="width: 800px; margin: 0 auto">
        <div class="title" style="text-align: center">
          CampusLife
        </div>
        <div class="second-title">HOME LIMU CENTRE FORBGTANYERSIT</div>
      </div>
    </div>
    <div
      class="dipoma-main"
      style="
        padding-top: 20px;
        padding-left: 50px;
        padding-right: 50px;
        overflow: hidden;
      "
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="">Home</a></li>
        <li class="breadcrumb-item">Schools</li>
        <li class="breadcrumb-item active">SchoolLife</li>
      </ol>
      <div class="apply-now-title" style="">
        Sunway' Unkversky Bycheesing Bu-mayy Buiness Sehoal and Sunwa, Universry
      </div>
      <p>
        you are making a staregic decision no join a reputable insthtudion
        whally cammirted to your swccess Enmall with us todyy and unlock yourful
        ponemtiai for a prospennue fut.mein the bur nees world.business. Our
        commitmen to ewellence hs oammpl fies by or autenal-a pediammace in the
        lyee ward u-lvesiry gbjeet rankd-as
      </p>
      <div class="flex-as-js">
        <div class="campusLife-item">
          <img
            src="../assets/2.jpg"
            style="width: 100%; height: 500px; margin: 15px 0"
          />
          <div class="-item-title">
            Overvicw Meet the DeanWhy Study Here Courses
            StaffProfilesResearchMore
          </div>
          <p style="margin-top: 10px">
            Embarkon atenstomatine souation iourey WiTh SUCws Bus nes Schoel and
            fur wly Un-etss Dio nehesanos of seceuianwcroeames designed to pro
            oel your career in verious discipines. Whether you have ambitions to
            becomre an acooma lished acoountant. a sstaregic manager, a dyeemie
          </p>
          <div class="-item-title hover-title">Read More</div>
        </div>
        <div class="campusLife-item">
          <img
            src="../assets/1.jpg"
            style="width: 100%; height: 500px; margin: 15px 0"
          />
          <div class="-item-title">business world</div>
          <p style="margin-top: 10px">
            Overvicw Meet the DeanWhy Study Here Courses
            StaffProfilesResearchMore Honb >anwlylished schaol Chat With Us
            Unlock your full potential for a prosperous future in the
          </p>
          <div class="-item-title hover-title">Read More</div>
        </div>
        <div class="campusLife-item">
          <img
            src="../assets/2.jpg"
            style="width: 100%; height: 500px; margin: 15px 0"
          />
          <div class="-item-title">MILA University RANKED 301-400</div>
          <p style="margin-top: 10px">
            academic and resenrch achievements in business. Our commitmen to
            ewellence hs oammpl fies by or autenal-a pediammace in the lyee ward
            u-lvesiry gbjeet rankd-as terondl-a to the eemed Tmes
          </p>
          <div class="-item-title hover-title">Read More</div>
        </div>
      </div>
    </div>
    <webBottom />
    <!-- <bottomleft /> -->
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import webBottom from "@/components/webBottom.vue";
import bottomleft from "@/components/bottomLeft.vue";
export default {
  components: {
    collegeHeader,
    webBottom,
    // bottomleft,
  },
};
</script>
<style lang="scss" scoped>
.header-part {
  width: 100%;
  height: 400px;
  background-image: url("../assets/2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 0;
}
</style>
<style lang="scss" type="text/scss" src="../assets/style/CampusLife.scss">
