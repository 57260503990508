var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-img",staticStyle:{"position":"relative"}},[(this.image != null)?_c('div',{style:(_vm.banner_style)},[_c('div',{style:({
            position: 'absolute',
            top: '90%',
            left: '20%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            fontSize: '30px',
            fontWeight: 'bold',
            textAlign: 'center',
            textShadow: '2px 2px 4px #000000',
        
        })},[(_vm.tip)?_c('span',[_vm._v("Conceptual Design for the future")]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }