<template>
  <div>
    <collegeHeader />
    <div class="header-part flex-ac">
      <div style="width: 800px; margin: 0 auto">
        <div class="title" style="text-align: center">INTERNAL STUDENTS</div>
        <div class="second-title">HOME LIMU CENTRE FORBGTANYERSIT</div>
      </div>
    </div>
    <div class="inter-content flex-ac" style="align-items: flex-start">
      <div class="inter-left pos-rel" style="padding-right: 60px">
        <div class="inter-title">
          Welcome to The International Medical University
        </div>
        <div class="cover-text-yellow inter-cover-text"></div>
        <p>
          First and foremost, a warm welcome from the International Medical
          University (IMU)! We are delighted that you are considering studying
          at our University.
        </p>
        <p>
          Malaysia's first and most established private medical and-healthcare
          University Established in 1992, the International Medical University
          (IMU) is Malaysia's first and most established private medical and
          healthcare University. With 29 years of dedicated focus in healthcare
          education, the University'enjoys-an international reputation and
          delivers a high standard of education for you to pursue your
          education, be it undergraduate, postgraduate or continuing
          professional development level.
        </p>
      </div>
      <div class="inter-right">
        <img src="../assets/1.jpg" />
      </div>
    </div>
    <webBottom />
    <!-- <bottomleft /> -->
  </div>
</template>
<script>
import collegeHeader from "@/components/head.vue";
import webBottom from "@/components/webBottom.vue";
import bottomleft from "@/components/bottomLeft.vue";
export default {
  components: {
    collegeHeader,
    webBottom,
    // bottomleft
  },
};
</script>
<style lang="scss" scoped>
.header-part {
  width: 100%;
  height: 400px;
  background-image: url("../assets/2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 0;
}
</style>
<style lang="scss" type="text/scss" src="../assets/style/InternationalStudents.scss">
