<template>
    <div class="banner-img" style="position: relative;">
        <div v-if="this.image != null" v-bind:style="banner_style">
            <div :style="{
                position: 'absolute',
                top: '90%',
                left: '20%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontSize: '30px',
                fontWeight: 'bold',
                textAlign: 'center',
                textShadow: '2px 2px 4px #000000',
            
            }">
            <span v-if="tip">Conceptual Design for the future</span>
                
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        image: {
            type: String,
            default: "",
        },
        tip:{
            type: Boolean,
            default: true,
        }
    },
    watch: {
        image() {
            this.banner_style.backgroundImage = `url('${this.image}')`;
        },
    },
    data() {
        console.log("this.image", this.image);

        return {
            banner_style: {
                backgroundImage: `url('${this.image}')`,
                height: "400px",
                width: "100%",
                //背景图片居中，并且宽度撑满
                backgroundSize: "cover",
                backgroundPosition: "center center",
            },
        };
    },
};
</script>